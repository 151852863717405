import { useState } from "react";
import Select from "react-select";
import { Col, FormGroup, Row } from "reactstrap";
import { SecretAccessLogs } from "./SecretAccessLogs";
export const CustomAccessLogsModal = ({ secretDetail }: any) => {
  const [date, setDate] = useState(null);

  const accessLogMapping = new Map();
  let dates: any = [];

  if (secretDetail.accessLog) {
    for (let i = 0, size = secretDetail.accessLog.length; i < size; i++) {
      let formattedDate = new Date(
        secretDetail.accessLog[i].timestamp
      ).toLocaleDateString();

      let logs = accessLogMapping.get(formattedDate);

      if (logs) {
        logs.push(secretDetail.accessLog[i]);

        accessLogMapping.set(formattedDate, logs);
      } else {
        let valueDate = { value: formattedDate, label: formattedDate };

        dates.push(valueDate);

        accessLogMapping.set(formattedDate, [secretDetail.accessLog[i]]);
      }
    }

    for(let i = 0, size = dates.length; i < size; i++){
      let currentLabel = dates[i].label;
      dates[i].label = <>{currentLabel} <div className="badge badge-dark badge-pill pull-right mt-1">{accessLogMapping.get(currentLabel).length}</div></>
    }
  }

  return (
    <div className="secret-detail">
      <hr />

      {secretDetail && secretDetail.creation && (
        <div className="secret-box-details">
          <Row>
            <Col md="12">
              <FormGroup>
                <Row>
                  <Col md="2"></Col>
                  <Col md="8">
                    <Select
                      className="react-select"
                      placeholder={
                        (!secretDetail.accessLog || secretDetail.accessLog &&
                        secretDetail.accessLog.length < 1)? "No access logs available" : "Choose a date"
                      }
                      name="select"
                      onChange={(value) => setDate(value)}
                      options={dates}
                      isDisabled={
                        !secretDetail.accessLog || secretDetail.accessLog &&
                        secretDetail.accessLog.length < 1
                      }
                    />
                  </Col>
                </Row>
                {secretDetail.accessLog && secretDetail.accessLog.length < 1 ? (
                  <div className="alert alert-info mt-5">
                    No logs available yet.
                  </div>
                ) : (
                  <SecretAccessLogs date={date} data={accessLogMapping} />
                )}
              </FormGroup>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};
