import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import { FormGroup, Label } from "reactstrap";
import { InfoProps } from "../../commons/models/models";
import { CustomErrorField } from "./CustomErrorField";
import { LockIconTooltip } from "./LockIconTooltip";
import { PasswordResult } from "./PasswordResult";

export const InputPasswordWizard = ({ font }: InfoProps) => {
  const [seePassword, setSeePassword] = useState(false);
  return (
    <div>
      <Label>
        Password
        <LockIconTooltip />
      </Label>
      <FormGroup>
        <div className="flex">
          <FontAwesomeIcon
            icon={seePassword ? faEye : faEyeSlash}
            color="#0d6efd"
            className="usr-profile cursor-p"
            onClick={() => setSeePassword(!seePassword)}
          />
          <Field
            className="eee-field"
            type={seePassword ? "text" : "password"}
            name="password"
          />
        </div>
      </FormGroup>
      <ErrorMessage name="password" component={CustomErrorField} />
      <PasswordResult font={font} />
    </div>
  );
};
