/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import zxcvbn from "zxcvbn";
import { isJsonString } from "../../../utils";
import { InputCredentialWizard } from "../InputCredentialWizard";
import { InputJsonWizard } from "../InputJsonWizard";
import { InputMessageWizard } from "../InputMessageWizard";
import { InputPasswordWizard } from "../InputPasswordWizard";
import { InputQrWizard } from "../InputQrWizard";
import { InputFileWizard } from "../InputFileWizard";

// core components
//import ImageUpload from "components/CustomUpload/ImageUpload.js";

const Step2 = React.forwardRef((props, ref) => {
  let secretType = props.wizardData.secretType
    ? props.wizardData.secretType
    : "password";

  props.wizardData.secretType = secretType;

  const [qrData, setQrData] = useState("No QR code scanned yet");

  const [fileData, setFileData] = useState(new File([],""));
  const [fileErrors, setFileErrors] = useState("");

  const [font, setFont] = useState({
    color: "-1",
    bgColor: "",
    text: "",
    description: "",
  });

  const formRef = useRef();

  React.useImperativeHandle(ref, () => ({
    isValidated: isValidated,
  }));

  /*eslint-disable-next-line*/
  const isValidated = () => {
    const values =
      formRef.current && formRef.current.values ? formRef.current.values : {};
    let error = true;
    
    if (secretType === "password" && values.password) {
      props.wizardData.password = values.password;
      error = false;
    } else if (secretType === "message" && values.message) {
      props.wizardData.message = values.message;
      error = false;
    } else if (
      secretType === "credentials" &&
      values.credentialEmail &&
      values.credentialPassword
    ) {
      props.wizardData.credentialEmail = values.credentialEmail;
      props.wizardData.credentialPassword = values.credentialPassword;
      props.wizardData.credentialHostname = values.credentialHostname;
      props.wizardData.credential2Fa = values.credential2Fa;
      error = false;
    } else if (
      secretType === "custom" &&
      values.json &&
      isJsonString(values.json)
    ) {
      props.wizardData.json = values.json;
      error = false;
    } else if (secretType === "QR" && qrData !== "No QR code scanned yet") {
      props.wizardData.qr = qrData;
      error = false;
    } else if (secretType === "file" && fileData.name) {
      props.wizardData.file = fileData;
      error = false;
    }

    return !error;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      validate={(values) => {
        let errors = {};
        const error = "This field is required";
        
        if (secretType === "password" && !values.password) {
          errors.password = error;
          setFont((prevFont) => ({
            ...prevFont,
            noData: true,
          }));
        } else if (secretType === "password" && values.password) {
          let checked = zxcvbn(values.password);

          if (checked.score > -1 && checked.score <= 2) {
            setFont((prevFont) => ({
              ...prevFont,
              bgColor: "bg-danger",
              color: "danger-txt",
              text: "Weak",
            }));
          } else if (checked.score === 3) {
            setFont((prevFont) => ({
              ...prevFont,
              bgColor: "bg-warning",
              color: "warning-txt",
              text: "Ok",
            }));
          } else if (checked.score >= 4) {
            setFont((prevFont) => ({
              ...prevFont,
              bgColor: "bg-success",
              color: "success-txt",
              text: "Strong",
            }));
          }

          setFont((prevFont) => ({
            ...prevFont,
            description: `${checked.crack_times_display.online_no_throttling_10_per_second} to crack`,
            noData: false,
          }));
        } else if (secretType === "message" && !values.message) {
          errors.message = error;
        } else if (secretType === "credentials") {
          if (!values.credentialEmail) errors.credentialEmail = error;

          if (!values.credentialPassword) errors.credentialPassword = error;
        } else if (secretType === "custom") {
          if (!values.json) errors.json = error;
          else if (!isJsonString(values.json)) {
            errors.json = "Please, inter a valid JSON object";
          }
        } else if (secretType === "file" && !fileData.name) {
          console.log("fileData",fileData,fileData.name);
          setFileErrors(error);
          errors.file = error;
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        props.wizardData.secretData = values;
      }}
      innerRef={formRef}
    >
      {({ isSubmitting }) => (
        <Form className="form-formik">
          <h4 className="info-text">
            {secretType === "password" && (
              <b>
                Please enter the <b>password</b> that you wish to share
              </b>
            )}
            {secretType === "message" && (
              <b>
                Please enter the <b>message</b> that you wish to share
              </b>
            )}
            {secretType === "credentials" && (
              <b>
                Please enter the <b>credential</b> details that you wish to
                share
              </b>
            )}
            {secretType === "custom" && (
              <b>
                Please enter the <b>JSON</b> that you wish to share
              </b>
            )}
            {secretType === "QR" && (
              <b>
                Please scan or upload the <b>QR</b> code that you wish to share
              </b>
            )}
            {secretType === "file" && (
              <b>
                Please upload the <b>File</b> that you wish to share
              </b>
            )}
          </h4>
          <Row className="justify-content-center mt-5">
            <Col sm="7">
              {secretType === "password" && <InputPasswordWizard font={font} />}
              {secretType === "message" && <InputMessageWizard />}
              {secretType === "credentials" && <InputCredentialWizard />}
              {secretType === "custom" && <InputJsonWizard />}
              {secretType === "QR" && (
                <InputQrWizard setQrData={setQrData} qrData={qrData} />
              )}
              {secretType === "file" && (
                <InputFileWizard  
                  setFileData={setFileData} 
                  errors={fileErrors} 
                  setErrors={setFileErrors} 
                />
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
});

export default Step2;
