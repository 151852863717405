import { useContext, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { Col, CustomInput, FormGroup, Label, Row } from "reactstrap";
import { Context } from "../../context/auth/Context";
import { types } from "../../context/types";
import { fetchMFAGenerate, fetchMFAPreference } from "../../services/services";

export const MFAForm = ({
  authVars,
  isSwitched,
  friendlyDeviceName,
  setShowStep,
  sethas2FA,
  setTOPTURL,
  setTOPTSecret,
  has2FA,
}: {
  authVars: object;
  isSwitched: boolean;
  friendlyDeviceName: string;
  setShowStep: any;
  sethas2FA: any;
  setTOPTURL: any;
  setTOPTSecret: any;
  has2FA: boolean;
}) => {
  const [enableText, setEnableText] = useState(
    isSwitched ? "MFA enabled" : "MFA disabled"
  );
  const [switchDisabled, setSwitchDisabled] = useState(false);
  const [switchStatus, setSwitchSatus] = useState(isSwitched);
  const [regenerateDisabled, setRegenerateDisabled] = useState(false);

  const [alert, setAlert] = useState(<></>);

  const hideAlert = () => {
    setAlert(<></>);
  };

  const history = useHistory();

  // Get name from context
  const { dispatch } = useContext(Context);

  const handleMFASwitch = async () => {
    const currentSwitch = !switchStatus;
    setSwitchSatus(currentSwitch);
    setEnableText(currentSwitch ? "MFA enabled" : "MFA disabled");

    setSwitchDisabled(true);

    const preferenceParams = {
      enabled: currentSwitch,
    };

    setShowStep("checking");
    const { status, redirect } = await fetchMFAPreference(preferenceParams);

    if (redirect) {
      dispatch({
        type: types.logout,
      });
      history.push("/auth/login");
    } else if (status !== "Success") {
      //Request confirmation
      if (currentSwitch) {
        const { status, details, redirect } = await fetchMFAGenerate();

        if (redirect) {
          dispatch({
            type: types.logout,
          });
          history.push("/auth/login");
        } else if (status === "Success") {
          let urlTOTP = `otpauth://totp/${encodeURIComponent(
            authVars.email
          )}?secret=${encodeURIComponent(details.SecretCode)}&issuer=SharePass`;
          setTOPTURL(urlTOTP);
          setTOPTSecret(details.SecretCode);
          setShowStep("verify");
        }
      } else {
        setShowStep("toggle");
      }
    } else {
      setShowStep("toggle");
      sethas2FA(true);
    }

    setSwitchDisabled(false);
  };

  const confirmRegenerate = async () => {
    hideAlert();
    setRegenerateDisabled(true);
    setShowStep("checking");
    const { status, details, redirect } = await fetchMFAGenerate();
    setRegenerateDisabled(false);

    if (redirect) {
      dispatch({
        type: types.logout,
      });
      history.push("/auth/login");
    } else if (status === "Success") {
      let urlTOTP = `otpauth://totp/${encodeURIComponent(
        authVars.email
      )}?secret=${encodeURIComponent(details.SecretCode)}&issuer=SharePass`;
      setTOPTURL(urlTOTP);
      setTOPTSecret(details.SecretCode);
      sethas2FA(false);
      setShowStep("verify");
    }
  };

  const showRegenerateAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Confirmation"
        onConfirm={() => confirmRegenerate()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="Ok"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        <p>
          The new TOTP setup will be available once you verify it, otherwise
          previous configuration will remain active.
        </p>
      </ReactBSAlert>
    );
  };

  let friendlyDeviceContent;
  if (friendlyDeviceName !== "") {
    friendlyDeviceContent = (
      <div>
        <Label className="top10 bottom10">
          <strong>Device:</strong> {friendlyDeviceName}
        </Label>
      </div>
    );
  }

  return (
    <>
      {alert}
      <Row className="mb-5">
        <Col lg="6" md="6" sm="6" xs="12">
          <div className="top10 bottom10">
            <FormGroup>
              <Label>{enableText}</Label>
              <CustomInput
                type="switch"
                id="enableMFA"
                onChange={handleMFASwitch}
                checked={switchStatus}
                disabled={switchDisabled}
              />
            </FormGroup>
            <br />
          </div>

          {friendlyDeviceContent}

          {has2FA && (
            <button
              disabled={regenerateDisabled}
              className="btn-fill btn-sm btn btn-secondary"
              onClick={showRegenerateAlert}
            >
              <i className="fas fa-redo" aria-hidden="true"></i> Re-generate
            </button>
          )}
          <button
            className="btn-fill btn-sm btn btn-secondary"
            onClick={() => {
              setShowStep("password");
            }}
          >
            <i className="fas fa-sign-out-alt" aria-hidden="true"></i> Exit
          </button>
        </Col>
      </Row>
    </>
  );
};
