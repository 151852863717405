export const secretOptions = [
  {
    value: "",
    label: "Select an option",
    isDisabled: true,
  },
  {
    value: "password",
    label: "Password",
    isDisabled: false,
  },
  {
    value: "message",
    label: "Message",
    isDisabled: false,
  },
  {
    value: "credentials",
    label: "Credentials",
    isDisabled: false,
  },
  {
    value: "custom",
    label: "JSON",
    isDisabled: false,
  },
  {
    value: "QR",
    label: "QR",
    isDisabled: false,
  },
  {
    value: "file",
    label: "File",
    isDisabled: false,
  },
];

export const colors = [
  { name: "primary-force", value: "#061923", label: "Blue" },
  { name: "info", value: "#1d8cf8", label: "Light blue" },
  { name: "success", value: "#00f2c3", label: "Green" },
  { name: "warning", value: "#ff8d72", label: "Orange" },
  { name: "danger", value: "#f63b3b", label: "Red" },
];

export const allColors = [
  { label: "Any", value: "any", color: "Any" },
  { name: "primary-force", value: "#061923", label: "Blue" },
  { name: "info", value: "#1d8cf8", label: "Light blue" },
  { name: "success", value: "#00f2c3", label: "Green" },
  { name: "warning", value: "#ff8d72", label: "Orange" },
  { name: "danger", value: "#f63b3b", label: "Red" },
];

export const secretTypes = [
  { value: "any", label: "Any" },
  { value: "password", label: "Password" },
  { value: "message", label: "Message" },
  { value: "credentials", label: "Credential" },
  { value: "custom", label: "Json" },
  { value: "QR", label: "Qr" },
  { value: "file", label: "File" },
];
