import { useRef, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactCodeInput from "react-code-input";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import {
  fetchCreateEnrollWebauthn,
  fetchParseEnrollWebauthn
} from "../../../../services/auth";
import { terms } from "../../../../variables/terms";
import { CustomModal } from "../../../../views/components/CustomModal";

import sharePassGif from "../../assets/img/WWYK.gif";
import useAPIError from "../../../../commons/hooks/useAPIError";
import { b64decode, b64encode } from "../../../../services/common";

const EnrollSecurityKey = () => {
  /* Handle  focus & blur of inputs*/
  const [state, setState] = useState({});

  const [check, setCheck] = useState(false);
  /* Handle rutes */
  const history = useHistory();

  /* Handle modal */
  const [modalClassic, setModalClassic] = useState(false);
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };

  const [dataForm, setDataForm] = useState({
    code: "",
  });

  const [disabledPin, setDisabledPin] = useState(false);
  const codeRef = useRef(undefined);

  // Notify
  const { addNotify } = useAPIError();

  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState();

  const handleSubmit = async () => {

    let messageErr = "";

    try {
      setDisabledPin(true);
      setIsLoading(true);

      const { code } = dataForm;

      let endData = {
        code: code,
      };

      const { status, details, message } = await fetchCreateEnrollWebauthn(endData);

      messageErr = message;
      
      if (status === "Success") {
        let credOptions = details;
        credOptions.challenge = b64decode(credOptions.challenge);
        credOptions.user.id = b64decode(credOptions.user.id);
        
        let challenge = credOptions.challenge;

        if (credOptions.excludeCredentials) {
          for (
            let i = 0, size = credOptions.excludeCredentials.length;
            i < size;
            i++
          ) {
            credOptions.excludeCredentials[i].id = b64decode(
              credOptions.excludeCredentials[i].id
            );
          }
        }
        console.log(credOptions);
        //----------create credentials using available authenticator
        const cred = await navigator.credentials
          .create({
            publicKey: credOptions,
          })
          .catch(function (error) {
            console.log(error);
            throw error;
          });

        // parse credentials response to extract id and public-key, this is the information needed to register the user in Cognito
        const credential = {};
        credential.id = cred && cred.id ? cred.id : null;
        credential.rawId = b64encode(cred && cred.rawId ? cred.rawId : null);
        credential.type = cred && cred.type ? cred.type : null;

        if (cred && cred.response) {
          const clientDataJSON = b64encode(cred.response.clientDataJSON);
          const attestationObject = b64encode(cred.response.attestationObject);
          credential.response = {
            clientDataJSON,
            attestationObject,
          };

          credential.transports = JSON.stringify(cred.response.getTransports());
        }
        endData = { ...endData, ...credential };

        const parseResult = await fetchParseEnrollWebauthn(endData);

        const { status } = parseResult;

        setDataForm({
          ...state,
          code: "",
        });

        setCheck(false);
        setIsLoading(false);
        setDisabledPin(false);

        if (codeRef.current && codeRef.current.state) {
          codeRef.current.state.input[0] = "";
          codeRef.current.state.input[1] = "";
          codeRef.current.state.input[2] = "";
          codeRef.current.state.input[3] = "";
          codeRef.current.state.input[4] = "";
          codeRef.current.state.input[5] = "";
        }

        if (status === "Success") {
          setAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Security key enrolled successfully!"
              onConfirm={() => {
                setAlert();
                history.push("/auth/login");
              }}
              confirmBtnBsStyle="primary"
              confirmBtnText="Ok"
              btnSize=""
            >
              Your security key enrollment request was created successfully.
            </ReactBSAlert>
          );
        } else {
          throw "Error";
        }
      } else {
        throw "Error";
      }
    } catch (e) {
      setIsLoading(false);
      setCheck(false);
      setDisabledPin(false);
      
      setDataForm({
        ...state,
        code: ""
      });

      if (codeRef.current && codeRef.current.state) {
        codeRef.current.state.input[0] = "";
        codeRef.current.state.input[1] = "";
        codeRef.current.state.input[2] = "";
        codeRef.current.state.input[3] = "";
        codeRef.current.state.input[4] = "";
        codeRef.current.state.input[5] = "";
      }

      setAlert(
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          onConfirm={() => {
            setAlert();
          }}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          {messageErr ? messageErr : "There was an error while trying to enroll your security key on SharePass."}
        </ReactBSAlert>
      );
    }
  };

  const returnDisabledOrNot = () => {
    return isLoading || !dataForm.code || dataForm.code && dataForm.code.length != 6 || !check;
  };

  return (
    <>
      {alert}
      <div className="content">
        <Container>
          <Row className={`${modalClassic ? "register-dark" : ""} `}>
            <Col className="ml-auto mr-auto" md="7">
              <Card className="card-register card-white no-mb">
                <CardHeader className="card-header-sp">
                  <img
                    alt="..."
                    src={require("../../assets/img/natan-logo.png").default}
                  />
                </CardHeader>
                <CardBody className="pBotttom0">
                  <div className="text-center mt-3">
                    <h3>Security key enrollment request</h3>
                  </div>

                  <Form className="form">
                    <div className="text-center input-uppercase">
                      <ReactCodeInput
                        ref={codeRef}
                        disabled={disabledPin}
                        onChange={(value) => {
                          /*dataForm.code = value;
                          setCheck(check);*/
                          setDataForm((prev) => ({ ...prev, code: value }));
                        }}
                        type="text"
                        fields={6}
                      />
                    </div>

                    <img src={sharePassGif} />

                    <FormGroup className="center-sp">
                      <Label>
                        <Input
                          type="checkbox"
                          checked={check}
                          onChange={() => {
                            setCheck(!check);
                          }}
                        />
                        <span className="form-check-sign" />I agree to the{" "}
                        <a
                          className="terms-conditions"
                          href="https://sharepass.com/terms-of-service"
                          target="_blank"
                          rel="noreferrer"
                        >
                          terms of service
                        </a>
                        .
                      </Label>
                    </FormGroup>

                    <FormGroup className="center-sp">
                      <Button
                        className={`btn-round ${
                          returnDisabledOrNot() ? "disabled" : ""
                        }`}
                        color="primary"
                        //onClick={(e) => handleSubmit(e)}
                        size="lg"
                        type="button"
                        onClick={handleSubmit}
                      >
                        {returnDisabledOrNot() && isLoading
                          ? "Please wait..."
                          : "Start"}
                      </Button>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <CustomModal
            modalClassic={modalClassic}
            toggleModalClassic={toggleModalClassic}
            textBody={terms}
          />
        </Container>
      </div>
    </>
  );
};

export default EnrollSecurityKey;
