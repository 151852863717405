export const options = [
  {
    value: "",
    label: "None",
    isDisabled: false,
  },
  { value: "Static", label: "Static pin" },
  { value: "Dynamic", label: "Dynamic pin" },
  { value: "Security key", label: "Security key" },
];
