import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHearSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import useAPIError from "../../commons/hooks/useAPIError";
import { templateColumns } from "../../constants/reactTable";
import { Context } from "../../context/auth/Context";
import { types } from "../../context/types";
import {
  fetchDeleteTemplate,
  fetchTemplates,
  fetchUpdateTemplate,
} from "../../services/services";
import ReactTemplateTable from "../components/ReactTemplateTable";

export const TemplateList = () => {
  const history = useHistory();

  // Notify
  const { addNotify } = useAPIError();

  // Get name from context
  const {
    user: { token, refreshToken, expiresIn, userId },
    dispatch,
  } = useContext(Context);

  const [templates, setTemplates] = useState([]);
  const [templatesAux, setTemplatesAux] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Notify
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  // For delete template
  const [auxId, setAuxId] = useState("");

  useEffect(() => {
    const getTemplates = async () => {
      setIsLoading(true);

      const { details, status, redirect } = await fetchTemplates();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        setIsLoading(false);

        /*
         * Show alert before delete a secret
         */

        const warningWithConfirmMessage = (id: string) => {
          setShowAlert(true);
          setAlert(
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure?"
              onConfirm={() => {
                setAuxId(id);
              }}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="success"
              confirmBtnText="Yes, delete it!"
              cancelBtnText="Cancel"
              showCancel
              btnSize=""
            >
              You will not be able to recover this template!
            </ReactBSAlert>
          );
        };

        /*
         * We redirect to edit page to edit the template
         * Store the template on the state
         */
        const handleEdit = (template: any) => {
          history.push("/admin/template/edit", { template });
        };

        /*
         * Here we parse data for the table
         * Params: array of templates from backend
         */
        const parsedDataTemplateTable = (datatable: any) => {
          return datatable.map((prop: any, key: number) => {
            return {
              id: prop.codeName,
              name: prop.codeName,
              position: "2",
              office: "3",
              age: "4",
              starred: prop.starred,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a like kind of action */}
                  <Button
                    onClick={() => {
                      setTemplateId(prop.codeName);
                    }}
                    color="info"
                    size="sm"
                    className={`btn-icon btn-link like ${
                      key < 5 ? "btn-neutral" : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      id="PinTooltip"
                      className="top5"
                      icon={prop.starred ? faHearSolid : faHeart}
                      color="#1d8cf8"
                    />
                  </Button>
                  <Button
                    onClick={() => {
                      handleEdit(prop);
                    }}
                    color="warning"
                    size="sm"
                    className="btn-icon btn-link like"
                  >
                    <i className="tim-icons icon-pencil" />
                  </Button>
                  <Button
                    onClick={() => {
                      warningWithConfirmMessage(prop.codeName);
                    }}
                    color="danger"
                    size="sm"
                    className={"btn-icon btn-link like"}
                  >
                    <i className="tim-icons icon-trash-simple danger" />
                  </Button>
                </div>
              ),
            };
          });
        };

        if (details.length > 0) {
          const parsedData = parsedDataTemplateTable(details);
          setTemplates(parsedData);
          setTemplatesAux(details);
        }
      }
    };

    if (templates.length === 0) {
      getTemplates();
    }
  }, [templates, token, refreshToken, expiresIn, userId, dispatch, history]);

  useEffect(() => {
    if (isLoading) {
      setTemplateId("");
      return;
    }
    if (templateId) {
      setIsLoading(true);

      let newData: any = [...templatesAux];
      let template: any = templatesAux.find((s: any) => {
        return s.codeName === templateId;
      });

      if (template) {
        const newTemplate = {
          ...template,
          starred: !template.starred,
        };

        const i = templatesAux.findIndex(
          (temp: any) => temp.codeName === templateId
        );
        newData[i] = newTemplate;

        /* Update secret and data table */
        const updateTemplate = async (newData: any, newTemplate: any) => {
          const body = {
            codeName: templateId,
            updateAttributes: {
              starred: newTemplate.starred,
            },
          };
          const { status, redirect } = await fetchUpdateTemplate(body);

          /* Update data table */
          if (redirect) {
            dispatch({
              type: types.logout,
            });
            history.push("/auth/login");
          } else if (status === "Success") {
            addNotify("The template was successfully updated", "success");
            setTemplatesAux(newData);

            /*
             * We redirect to edit page to edit the template
             * Store the template on the state
             */
            const handleEdit = (template: any) => {
              history.push("/admin/template/edit", { template });
            };

            /*
             * Here we parse data for the table
             * Params: array of templates from backend
             */
            const parsedDataTemplateTable = (datatable: any) => {
              const warningWithConfirmMessage = (id: string) => {
                setShowAlert(true);
                setAlert(
                  <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => {
                      setAuxId(id);
                    }}
                    onCancel={() => hideAlert()}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                  >
                    You will not be able to recover this template!
                  </ReactBSAlert>
                );
              };

              return datatable.map((prop: any, key: number) => {
                return {
                  id: prop.codeName,
                  name: prop.codeName,
                  position: "2",
                  office: "3",
                  age: "4",
                  starred: prop.starred,
                  actions: (
                    // we've added some custom button actions
                    <div className="actions-right">
                      {/* use this button to add a like kind of action */}
                      <Button
                        onClick={() => {
                          setTemplateId(prop.codeName);
                        }}
                        size="sm"
                        className={`btn-icon btn-secondary btn-link like btn-neutral`}
                      >
                        <FontAwesomeIcon
                          id="PinTooltip"
                          className="btn-like"
                          icon={prop.starred ? faHearSolid : faHeart}
                          color={"#1d8cf8"}
                        />
                      </Button>
                      <Button
                        onClick={() => {
                          handleEdit(prop);
                        }}
                        color="warning"
                        size="sm"
                        className={`btn-icon btn-link like ${
                          key < 5 ? "btn-neutral" : ""
                        }`}
                      >
                        <i className="tim-icons icon-pencil" />
                      </Button>
                      <Button
                        onClick={() => {
                          warningWithConfirmMessage(prop.codeName);
                        }}
                        color="danger"
                        size="sm"
                        className={"btn-icon btn-link like"}
                      >
                        <i className="tim-icons icon-trash-simple danger" />
                      </Button>
                    </div>
                  ),
                };
              });
            };

            const parsedData = parsedDataTemplateTable(newData);
            setTemplates(parsedData);
            setTemplateId("");
            setIsLoading(false);
          }
        };

        /* Update template on the database */
        updateTemplate(newData, newTemplate);
      }
    }
  }, [
    templateId,
    dispatch,
    expiresIn,
    history,
    isLoading,
    refreshToken,
    templatesAux,
    token,
    userId,
    addNotify,
  ]);

  useEffect(() => {
    // Delete from the database
    const deleteTemplate = async () => {
      const body = { codeName: auxId };

      const { status, redirect } = await fetchDeleteTemplate(body);

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        const newData = templates.filter((secret: any) => secret.id !== auxId);
        setTemplates(newData);
        setAuxId("");

        setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Deleted!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            Your template has been deleted.
          </ReactBSAlert>
        );
      }
    };

    const isDeleted = templates.find((secret: any) => secret.id === auxId);
    if (auxId !== "" && isDeleted) {
      deleteTemplate();
    }
  }, [auxId, templates, token, refreshToken, expiresIn, userId, dispatch]);

  /*
   * Hide alert when delete a secret
   */
  const hideAlert = () => {
    setShowAlert(false);
    setAlert({});
    //setDetails({ secret: {}, id: "", showModal: false });
  };

  const fetchMoreData = async () => {
    setIsLoading(true);

    console.log("load more data...");
  };

  return (
    <div className="content react-table">
      {showAlert && alert}
      <Row>
        <Col md="12">
          <Card md="6">
            <CardBody>
              <Col xs={12} md={12}>
                <ReactTemplateTable
                  data={templates}
                  columns={templateColumns}
                  isLoading={isLoading}
                  update={fetchMoreData}
                  lastItem={"asasa"}
                />
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
