/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
// reactstrap components
import {
  Button,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  Navbar,
  NavbarBrand,
  NavLink,
  UncontrolledDropdown,
  UncontrolledTooltip
} from "reactstrap";
import Select from "react-select";
import { Context } from "../../context/auth/Context";
import { DataContext } from "../../context/data/DataContext";
import { useWindowSize } from "../../hooks/useWindowSize";
import { dataTypes, types } from "../../context/types";
import useAPIError from "../../commons/hooks/useAPIError";
import { fetchRevokeToken,   fetchUpdateGeneralSettings} from "../../services/services";
import { faArrowAltCircleLeft, faBoxOpen, faCogs, faExternalLink, faGlobe, faHome, faInbox, faInfoCircle, faPaperPlane, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import InstanceConfig from "../../assets/js/instanceConfig";
import { SharePassLoading } from "../Loading/SharePassLoading";

const AdminNavbar = (props) => {
  const history = useHistory();
  // Notify
  const { addNotify } = useAPIError();

  // Get userdetails from context
  const {
    dispatch,
    user: { purposeMode, userDetails },
    user
  } = useContext(Context);

  // Context for profile
  const {
    profile,
    dispatch2
  } = useContext(DataContext);
  
  const [showBtn, setShowBtn] = useState(true);
  /*const [purposeModeOption, setPurposeModeOption] = useState(
    purposeMode ? { value: 1, label: "Receive" } : 
    typeof purposeMode == "undefined" ? { value: -1, label: "Purpose", isDisabled: true } : 
    { value: 0, label: "Send" }
  );*/
  const [updatingPurposeMode, setUpdatingPurposeMode] = useState(false);
  
  const size = useWindowSize();
  useEffect(() => {
    const { width } = size;
    setShowBtn(width > 991);
  }, [size]);

  const [color] = useState(purposeMode === 1 ? "navbar-receiving" : "navbar-transparent");

  const goToNewSecret = () => {
    props.history.push("/admin/secret/new-secret");
  };

  const goToSettings = () => {
      props.history.push("/admin/settings/profile");
  };

  const goToAdminPortal = () => {
    props.history.push("/adminportal/users");
  }

  const goToDashboard = () => {
    props.history.push("/admin/dashboard");
  }

  // Logout
  const handleLogout = async () => {
    await fetchRevokeToken();

    // Check and remove light mode if is applied
    if (document.body.classList.contains("white-content"))
      document.body.classList.toggle("white-content");

    dispatch({
      type: types.logout,
    });

    // Clear local storage
    localStorage.removeItem("idToken");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("userId");

    props.history.push("/auth/login");
  };

  const updatePurposeMode = async () => {
    
    if(!updatingPurposeMode){

      setUpdatingPurposeMode(true);

      let newMode = purposeMode === 1 ? 0 : 1;
      let response = await fetchUpdateGeneralSettings({purposeMode:  newMode});
      
      history.push("/admin/dashboard");

      if(response.status == "Success"){

        dispatch({
          type: types.purposeMode,
          payload: {
            purposeMode: newMode,
          },
        });

        dispatch2({
          type: dataTypes.profile,
          payload: {
            ...profile
          },
        });

        addNotify(newMode? "Now you can create/view your envelopes." : "Now you can create/view your secrets.", "success");
      }else{
        addNotify("There was an error.", "danger");
      }

      setUpdatingPurposeMode(false);
    }
  }

  return (
    <>
      { (updatingPurposeMode || typeof purposeMode == "undefined") && <SharePassLoading /> }
      <Navbar
        className={classNames("navbar-absolute", {
          [color]: props.location.pathname.indexOf("full-screen-map") === -1,
        })}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={props.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip209599"
                placement="right"
              >
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={props.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#" onClick={(e) => e.preventDefault()}>
              <div className="custom-btn">{props.brandText}</div>
            </NavbarBrand>
          </div>
          <div className="new-secret" style={{margin: "auto"}}>
              {false && !showBtn && 
                props.location.pathname !== "/admin/secret/new-secret" && (
                <Button color="primary" onClick={goToNewSecret}>
                  New secret
                </Button>
              )}

          </div>
         {/*ml-auto*/}
          <Nav className="sharepass-nav" navbar>

            {
              InstanceConfig().envelope && <>
              <Button 
                id="tooltipPurpose"
                outline={false}
                size="sm"
                className="btn-link btn btn-purpose"
                onClick={updatePurposeMode} 
                disabled={updatingPurposeMode || typeof purposeMode == "undefined"}
              >
                {purposeMode === 1 ? <FontAwesomeIcon size="2x" icon={faPaperPlane} /> : typeof purposeMode == "undefined" ? "-" :<FontAwesomeIcon size="2x" icon={faInbox} /> }
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltipPurpose"
                placement="left"
              >
                Switch to <strong>{purposeMode === 1? "Sending" : "Receiving"} Mode</strong>
              </UncontrolledTooltip>
              </>
            }


            <UncontrolledDropdown nav>
              <DropdownToggle caret color="default" nav className="">
                <div className="photo">
                  <div className="profile-info" id="email">
                    {`${userDetails.name && userDetails.name.length >= 1 ? userDetails.name[0] : ""}${userDetails.lastname && userDetails.lastname.length >= 1 ? userDetails.lastname[0] : ""}`}
                  </div>
                </div>
                <UncontrolledTooltip delay={0} target="email" placement="left">
                  {userDetails.email}
                </UncontrolledTooltip>
              </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  {user.isAdmin && !props.location.pathname.includes("adminportal") && (
                    <>
                      <NavLink tag="li">
                        <DropdownItem  onClick={goToAdminPortal} className="nav-item"><FontAwesomeIcon
                icon={faGlobe}
                color="#000"
              />{"  "}Admin portal</DropdownItem>
                      </NavLink>
                      <DropdownItem divider tag="li" />
                    </>
                  )}
                  {user.isAdmin && props.location.pathname.includes("adminportal") && (
                    <>
                      <NavLink tag="li">
                        <DropdownItem  onClick={goToDashboard} className="nav-item"><FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                color="#000"
              />{"  "}Go back</DropdownItem>
                      </NavLink>
                      <DropdownItem divider tag="li" />
                    </>
                  )}
                  <NavLink tag="li">
                    <DropdownItem  onClick={goToSettings} className="nav-item"><FontAwesomeIcon
                icon={faCogs}
                color="#000"
              />{"  "}Settings</DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem onClick={handleLogout} className="nav-item"><FontAwesomeIcon
                icon={faSignOut}
                color="#000"
              />{"  "}Log out</DropdownItem>
                  </NavLink>
                </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
