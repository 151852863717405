import { useState } from "react";
import { Button } from "reactstrap";
import ReactJson from "react-json-view";

function CustomTemplate({ secret }) {
  const [textToCopy, setTextToCopy] = useState(JSON.stringify(secret));
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnCopyContent, setBtnCopyContent] = useState(
    <>
      <i className="fas fa-copy"></i> Copy
    </>
  );
  const [btnRevealContent, setBtnRevealContent] = useState(
    <>
      <i className="fas fa-eye"></i> Reveal
    </>
  );
  const [contentRevealed, setContentRevealed] = useState(false);

  function revealData() {
    setContentRevealed(!contentRevealed);
    if (contentRevealed == false) {
      setBtnRevealContent(
        <>
          <i className="fas fa-eye-slash"></i> Hide
        </>
      );
    } else {
      setBtnRevealContent(
        <>
          <i className="fas fa-eye"></i> Reveal
        </>
      );
    }
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(textToCopy);
    setBtnDisabled(true);

    /* Alert the copied text */
    setBtnCopyContent("Copied!");

    setTimeout(function () {
      setBtnDisabled(false);
      setBtnCopyContent(
        <>
          <i className="fas fa-copy"></i> Copy
        </>
      );
    }, 3000);
  }

  return (
    <>

      <div className="mb-3">
        <i className="fas fa-check-circle"></i> You can grab the content
        delivered.
      </div>

      <div className="mb-3">
        <Button
          className={`btn-round`}
          color="primary"
          type="button"
          onClick={revealData}
        >
        {btnRevealContent}
        </Button>
      
        <Button
          className={`btn-round ${
            btnDisabled ? "disabled" : ""
          }`}
          color="secondary"
          type="button"
          disabled={btnDisabled}
          onClick={copyToClipboard}
        >
          {btnCopyContent}
        </Button>
      </div>

      {contentRevealed && 
        <div className="input-group">
          <div className="input-group">
            <ReactJson src={secret} name={false} />
          </div>
        </div>
      }
      <textarea id="jsonDataRenderer" style={{ display: "none" }}></textarea>
      
    </>
  );
}

export default CustomTemplate;
