/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  faAddressCard,
  faCode,
  faComment,
  faFile,
  faKey,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { WizardButton } from "../WizardButton";
import { secretOptions } from "../../../constants/newSecret";

const Step1 = React.forwardRef((props, ref) => {
  React.useImperativeHandle(ref, () => ({
    isValidated: undefined,
  }));

  const [pageTabs, setpageTabs] = useState("password");

  const changeActiveTab = (e: any, tabState: string, tabName: string) => {
    e.preventDefault();
    switch (tabState) {
      case "pageTabs":
        setpageTabs(tabName);
        props.wizardData.secretType = tabName;
        break;
      default:
        break;
    }
  };

  let hasFiles = false;

  for (const [index, item] of Object.entries(secretOptions)) {
    if(item.value == "file"){
      hasFiles = true;
    }
  }

  return (
    <>
      <h4 className="info-text">
        <b>What type of secret would you like to share?</b>
      </h4>
      <Row>
        <Col className="text-center">
          <WizardButton
            icon={faKey}
            pageTabs={pageTabs}
            changeActiveTab={changeActiveTab}
            tabName="password"
            title="Password"
          />
          <WizardButton
            icon={faComment}
            pageTabs={pageTabs}
            changeActiveTab={changeActiveTab}
            tabName="message"
            title="Message"
          />

          <WizardButton
            icon={faAddressCard}
            pageTabs={pageTabs}
            changeActiveTab={changeActiveTab}
            tabName="credentials"
            title="Credentials"
          />

          <WizardButton
            icon={faCode}
            pageTabs={pageTabs}
            changeActiveTab={changeActiveTab}
            tabName="custom"
            title="JSON"
          />

          <WizardButton
            icon={faQrcode}
            pageTabs={pageTabs}
            changeActiveTab={changeActiveTab}
            tabName="QR"
            title="QR code"
          />
          { hasFiles && <WizardButton
            icon={faFile}
            pageTabs={pageTabs}
            changeActiveTab={changeActiveTab}
            tabName="file"
            title="File"
          />}
          
        </Col>
      </Row>
    </>
  );
});

export default Step1;
