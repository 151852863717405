/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  Col,
  CustomInput,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { inputProps, optionsEnd } from "../../../constants/inputAvailability";
const Step3 = React.forwardRef((props, ref) => {
  const history = useHistory();

  const [selectedOption2, setSelectedOption2] = useState({
    value: "Days",
    label: "Days",
  });

  const [isSwitched, setIsSwitched] = useState(false);

  const [qty2, setQty2] = useState(7);

  const today = new Date();
  const [datetime2, setDatetime2] = useState(
    new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    ).getTime()
  );
  const [errorDate, setErrorDate] = useState(false);

  useEffect(() => {
    let datetime = new Date().getTime();
    // Validate start & end date
    if (datetime2 && datetime2 <= datetime) setErrorDate(true);
  }, [datetime2, errorDate]);

  const handleMultipleUses = () => {
    setIsSwitched(!isSwitched);
  };

  React.useImperativeHandle(ref, () => ({
    isValidated: isValidated,
  }));

  const isValidated = () => {
    let datetime = new Date().getTime();
    let error = true;

    props.wizardData.config = {};
    props.wizardData.config.lockedByOwner = false;

    props.wizardData.config.label = {};

    props.wizardData.config.availability = {};
    props.wizardData.config.availability.opt = "Right now";
    props.wizardData.config.availability.opt2 = selectedOption2.value;
    props.wizardData.config.availability.qty2 = qty2;
    props.wizardData.config.availability.dateTimeEnd = datetime2;
    props.wizardData.config.availability.isSwitched = isSwitched;
    props.wizardData.config.availability.isSwitched2 = false;
    if (selectedOption2.value === "Specific date") {
      if (datetime2 && datetime2 <= datetime) {
        setErrorDate(true);
      } else {
        error = false;
      }
    } else if (selectedOption2.value === "Never expire") {
      error = false;
    } else if (qty2) {
      error = false;
    }

    return !error;
  };

  return (
    <>
      <h4 className="info-text">
        <b>When would you like to expire it?</b>
      </h4>

      <div className="flex-center">
        <Row>
          <Col md="4" sm="12"></Col>
          <Col className="mb-3" md="4" sm="12">
            <Label className="m0 top10">
              End date
              <FontAwesomeIcon
                className="left4"
                id="info-circle-end"
                icon={faInfoCircle}
                color="#0d6efd"
              />
            </Label>
            <UncontrolledTooltip
              delay={0}
              target="info-circle-end"
              placement="right"
            >
              Expired secrets are automatically erased.
            </UncontrolledTooltip>
          </Col>
          <Col md="4" sm="12"></Col>
          <Col md="4" sm="12"></Col>
          <Col className="mb-3" md="4" sm="12">
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              value={selectedOption2}
              onChange={setSelectedOption2}
              options={optionsEnd}
              placeholder="Single Select"
            />
          </Col>
          <Col md="4" sm="12"></Col>
          <Col md="4" sm="12"></Col>
          <Col md="4" sm="12">
            {selectedOption2 &&
              selectedOption2.value !== "Right now" &&
              selectedOption2.value !== "Specific date" &&
              selectedOption2.value !== "Never expire" && (
                <Input
                  className="input-availability"
                  type="number"
                  value={qty2}
                  onChange={(e) =>
                    (e.target.value === "" ||
                      (parseInt(e.target.value) >= 0 &&
                        parseInt(e.target.value) <= 90)) &&
                    setQty2(e.target.value)
                  }
                />
              )}
            {selectedOption2 && selectedOption2.value === "Specific date" && (
              <div>
                <Datetime
                  inputProps={inputProps}
                  value={datetime2}
                  onChange={(e) => setDatetime2(new Date(e._d).getTime())}
                  dateFormat={"DD/MM/YY"}
                />
                <div className="text-center">
                  <small>
                    (
                    {Intl.DateTimeFormat()
                      .resolvedOptions()
                      .timeZone.replace(/_/g, " ")}
                    )
                  </small>
                </div>

                {errorDate && (
                  <div className="availability form-formik">
                    <p className="error">
                      <b>End date</b> must be greather than <b>Start date</b>
                    </p>
                  </div>
                )}
              </div>
            )}
          </Col>
          <Col md="4" sm="12"></Col>
          {false && (
            <>
              <Col md="4" sm="12"></Col>
              <Col md="4" sm="12">
                <div className="flex">
                  <div className="top10">
                    <Label>Allow multiple uses</Label>
                    <CustomInput
                      type="switch"
                      id="switch-1"
                      onChange={handleMultipleUses}
                      checked={isSwitched}
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" sm="12"></Col>
            </>
          )}
        </Row>
      </div>
    </>
  );
});

export default Step3;
