import React from "react";
import { Card, CardBody, CardTitle, Col, Progress } from "reactstrap";

interface InfoProps {
  title: string;
  subTitle: string;
  value: string;
  state: string;
  icon: string;
}

export const WidgetConsumes = ({
  title,
  subTitle,
  value,
  state,
  icon,
}: InfoProps) => {
  let exceeded = "";
  let extraClass = "";
  if (parseInt(value) > 100) {
    value = "100";
    exceeded = " (exceeded)";
    extraClass = "storage-exceeded";
  }

  if (parseInt(value) < 0) {
    value = "0";
  }

  return (
    <Col lg="4" md="6">
      <Card className={`card-stats card-stats-widget ${extraClass}`}>
        <CardBody>
          <CardTitle className="center-sp" tag="h4">
            <i className={icon}></i>&nbsp;{title}
          </CardTitle>
          <div className={`plan ${state} align-center secrets-usage`}>
            <p className="card-description-p">
              {value}%{exceeded}
            </p>
            <div className={`progress-container progress-${state}`}>
              <Progress max="100" value={value}>
                <span className="progress-value"></span>
              </Progress>
              <p className="card-description">{subTitle}</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
