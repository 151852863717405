export const optionsStart = [
  { value: "Right now", label: "Right now" },
  { value: "Minutes", label: "Minutes" },
  { value: "Hours", label: "Hours" },
  { value: "Days", label: "Days" },
  { value: "Specific date", label: "Specific date" },
];

export const optionsEnd = [
  { value: "Minutes", label: "Minutes" },
  { value: "Hours", label: "Hours" },
  { value: "Days", label: "Days" },
  { value: "Specific date", label: "Specific date" },
  { value: "Never expire", label: "Never expire" },
];

export const customStyles = {
  menu: (provided) => {
    return {
      ...provided,
      verticalAlign: "middle",
      width: "100%",
      color: "red",
      backgroundColor: "#878e98",
      border: "1px solid #252a30",
      padding: "0 5px",
      justifyContent: "center",
      alignItems: "center",
      height: "172px",
    };
  },

  option: (provided) => {
    return {
      ...provided,
      backgroundColor: "#252a30",
      border: "1px solid #000",
      color: "#fff",
      height: 30,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      marginBottom: "3px",
      borderRadius: "4px",
    };
  },
};

export const customStyles2 = {
  menu: (provided) => {
    return {
      ...provided,
      verticalAlign: "middle",
      width: "100%",
      color: "red",
      backgroundColor: "#878e98",
      border: "1px solid #252a30",
      padding: "0 5px",
      justifyContent: "center",
      alignItems: "center",
      height: "172px",
    };
  },

  option: (provided) => {
    return {
      ...provided,
      backgroundColor: "#252a30",
      border: "1px solid #000",
      color: "#fff",
      height: 30,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      marginBottom: "3px",
      borderRadius: "4px",
    };
  },
};

export const inputProps = {
  placeholder: "Pick a datetime",
  className: "form-control",
};
