import {
  faCheck,
  faInfoCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";
import * as UaParser from "ua-parser-js";
export const SecretAccessLogs = ({ date, data }: any) => {
  if (date && date.value && data.get(date.value)) {
    const parser = new UaParser();
    if (
      data.get(date.value) &&
      data.get(date.value)[0] &&
      data.get(date.value)[0].userAgent
    ) {
      parser.setUA(data.get(date.value)[0].userAgent);
    }

    const parserResult = parser.getResult();

    return (
      <div className="mt-4 container access-logs-container">
        <div className="ReactTable -striped -highlight">
          <table className="rt-table">
            <thead className="rt-thead">
              <tr className="rt-tr">
                <th className="rt-th rt-resizable-header per-8">
                  <div className="rt-resizable-header-content"></div>
                </th>
                <th className="rt-th rt-resizable-header per-12">
                  <div className="rt-resizable-header-content">
                    Time{" "}
                    <FontAwesomeIcon
                      id="timeAccessLog"
                      icon={faInfoCircle}
                      className="text-info"
                    />
                    <UncontrolledTooltip
                      delay={0}
                      placement="top"
                      target="timeAccessLog"
                    >
                      {Intl.DateTimeFormat()
                        .resolvedOptions()
                        .timeZone.replace("_", " ")}
                    </UncontrolledTooltip>
                  </div>
                </th>
                <th className="rt-th rt-resizable-header per-50">
                  <div className="rt-resizable-header-content">Description</div>
                </th>
                <th className="rt-th rt-resizable-header per-22">
                  <div className="rt-resizable-header-content">Source IP</div>
                </th>
                <th className="rt-th rt-resizable-header per-8">
                  <div className="rt-resizable-header-content"></div>
                </th>
              </tr>
            </thead>
            <tbody className="rt-tbody">
              {data.get(date.value).map((cont, i) => {
                let dateVal = new Date(cont.timestamp);
                let dateValTime = `${("0" + dateVal.getHours()).slice(-2)}:${(
                  "0" + dateVal.getMinutes()
                ).slice(-2)}:${("0" + dateVal.getSeconds()).slice(-2)}`;
                return (
                  <tr className="rt-tr" key={i}>
                    <td className="rt-td per-8">
                      {cont.success ? (
                        <>
                          <FontAwesomeIcon
                            id={`successStatus${i}`}
                            icon={faCheck}
                            className="text-success"
                          />
                          <UncontrolledTooltip
                            delay={0}
                            placement="right"
                            target={`successStatus${i}`}
                          >
                            Success
                          </UncontrolledTooltip>
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            id={`failStatus${i}`}
                            icon={faTimes}
                            className="text-danger"
                          />
                          <UncontrolledTooltip
                            delay={0}
                            placement="right"
                            target={`failStatus${i}`}
                          >
                            Failed
                          </UncontrolledTooltip>
                        </>
                      )}
                    </td>
                    <td className="rt-td per-12">{dateValTime}</td>
                    <td className="rt-td per-50">
                      <div id={`detDesc${i}`}>{cont.description}</div>
                      <UncontrolledTooltip
                        delay={0}
                        placement="right"
                        target={`detDesc${i}`}
                      >
                        {cont.description}
                      </UncontrolledTooltip>
                    </td>
                    <td className="rt-td per-22">{cont.sourceIp}</td>
                    <td className="rt-td per-8">
                      <FontAwesomeIcon
                        id={`extraInfo${i}`}
                        icon={faInfoCircle}
                        className="text-info"
                      />
                      <UncontrolledTooltip
                        delay={0}
                        target={`extraInfo${i}`}
                        placement="left"
                      >
                        <div className="text-left">
                          <b>OS:</b>{" "}
                          {parserResult.os && parserResult.os.name
                            ? parserResult.os.name
                            : "Unkown"}{" "}
                          {parserResult.os && parserResult.os.version
                            ? parserResult.os.version
                            : ""}
                          <br />
                          <b>Browser:</b>{" "}
                          {parserResult.browser && parserResult.browser.name
                            ? parserResult.browser.name
                            : "Unkown"}{" "}
                          {parserResult.browser && parserResult.browser.major
                            ? `(${parserResult.browser.major})`
                            : ""}
                        </div>
                      </UncontrolledTooltip>
                    </td>
                  </tr>

                  /*<Row>
                <Col sm="1">
                  {cont.success ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} className="text-danger" />
                  )}
                </Col>
                <Col sm="3">{dateValTime}</Col>
                <Col className="text-left" sm="7">
                  {cont.description}
                </Col>
                <Col sm="1" className="text-left">
                  <FontAwesomeIcon
                    id={`extraInfo${i}`}
                    icon={faInfoCircle}
                    className="text-primary"
                  />
                  <UncontrolledTooltip
                    delay={0}
                    target={`extraInfo${i}`}
                    placement="top"
                  >
                    <div className="text-left">
                      <b>OS:</b>{" "}
                      {parserResult.os && parserResult.os.name
                        ? parserResult.os.name
                        : "Unkown"}{" "}
                      {parserResult.os && parserResult.os.version
                        ? parserResult.os.version
                        : ""}
                      <br />
                      <b>Browser:</b>{" "}
                      {parserResult.browser && parserResult.browser.name
                        ? parserResult.browser.name
                        : "Unkown"}{" "}
                      {parserResult.browser && parserResult.browser.major
                        ? `(${parserResult.browser.major})`
                        : ""}
                      <br />
                      <b>IP:</b> {cont.sourceIp}
                    </div>
                  </UncontrolledTooltip>
                </Col>
              </Row>*/
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    return <div className="mt-2"></div>;
  }
};
