import { months } from "../constants/dashboard";

export const parseData = (details) => {
  let renewDate =
    details.subscription && details.subscription.expiry
      ? new Date(details.subscription.expiry * 1000)
      : "";

  if (renewDate) {
    renewDate =
      ("0" + renewDate.getDate()).slice(-2) +
      "/" +
      ("0" + (renewDate.getMonth() + 1)).slice(-2) +
      "/" +
      renewDate.getFullYear();
  }

  let endDate =
    details.subscription && details.subscription.cancelled
      ? new Date(details.subscription.cancelled * 1000)
      : "";

  if (endDate) {
    endDate =
      ("0" + endDate.getDate()).slice(-2) +
      "/" +
      ("0" + (endDate.getMonth() + 1)).slice(-2) +
      "/" +
      endDate.getFullYear();
  }

  const data = {
    subscription: details.subscription
      ? {
          ...details.subscription,
          expiry: `${renewDate}`,
          cancelled: `${endDate}`,
          secretsLeft: `${parseInt(details.subscription.secretsLeft)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          bytesLeft: `${details.subscription.bytesLeft
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          secretTotalUsedPercent: (
            (details.subscription.secretsUsed * 100) /
            (details.subscription.secretsLeft +
              details.subscription.secretsUsed)
          ).toFixed(0),
          secretTotalUsed: `[${details.subscription.secretsUsed
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/${(
            details.subscription.secretsLeft + details.subscription.secretsUsed
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}]`,
          dataTotalUsedPercent: (
            (details.subscription.bytesUsed * 100) /
            (details.subscription.bytesLeft + details.subscription.bytesUsed)
          ).toFixed(0),
          dataTotalUsed: `[${details.subscription.bytesUsed
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/${(
            details.subscription.bytesLeft + details.subscription.bytesUsed
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}]`,
          secretsStorageUsedPercent: (
            (details.counters.secretsInDatabase * 100) /
            details.subscription.plan.limits.secrets
          ).toFixed(0),
          secretsLimit: details.subscription.plan.limits.secrets,
          secretsInDatabase: details.counters.secretsInDatabase,
          secretsStorageUsed: `[${details.counters.secretsInDatabase
            .toString()
            .replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )}/${details.subscription.plan.limits.secrets
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}]`,
          bytesStorageUsedPercent: (
            (details.counters.bytesInDatabase * 100) /
            details.subscription.plan.limits.bytes
          ).toFixed(0),
          bytesLimit: details.subscription.plan.limits.bytes,
          bytesInDatabase: details.counters.bytesInDatabase,
          bytesStorageUsed: `[${details.counters.bytesInDatabase
            .toString()
            .replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )}/${details.subscription.plan.limits.bytes
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}]`,
          templatesUsedPercent: (
            (details.counters.templatesInDatabase * 100) /
            details.subscription.plan.limits.templates
          ).toFixed(0),
          templatesLimit: details.subscription.plan.limits.templates,
          templatesInDatabase: details.counters.templatesInDatabase,
          templatesUsed: `[${details.counters.templatesInDatabase
            .toString()
            .replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )}/${details.subscription.plan.limits.templates
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}]`,
        }
      : null,
    counters: details.counters,
    consumption: details.consumption,
  };

  return data;
};

export const parseConsumptionsDaily = (details) => {
  let dailySecretsDataObj = {
    labels: [],
    datasets: [
      {
        label: "Secrets",
        data: [],
        fill: true,
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  let dailyBytesDataObj = {
    labels: [],
    datasets: [
      {
        label: "Bytes",
        data: [],
        fill: true,
        borderColor: "rgba(225,78,202,1)",
      },
    ],
  };

  let fixedQty = 10;
  details.consumption.daily.forEach((item) => {
    if (fixedQty === 0) {
      return;
    }
    fixedQty--;
    dailySecretsDataObj.datasets[0].data.unshift(item.secrets);
    dailyBytesDataObj.datasets[0].data.unshift(item.bytes);

    let dateParts = item.date.split("T")[0].split("-");
    dailySecretsDataObj.labels.unshift(`${dateParts[2]} ${months[dateParts[1]]}`);
    dailyBytesDataObj.labels.unshift(`${dateParts[2]} ${months[dateParts[1]]}`);
  });

  const dailySecretsData = (canvas) => {
    const ctx = canvas.getContext("2d");
    /*** Gradient ***/
    const gradient = ctx.createLinearGradient(0, 0, 0, 150);
        gradient.addColorStop(0, 'rgba(75,192,192,0.5)');   
        gradient.addColorStop(1, 'rgba(75,192,192,0)');

        dailySecretsDataObj.datasets[0].backgroundColor = gradient;

    return dailySecretsDataObj;

  };

  const dailyBytesData = (canvas) => {
    const ctx = canvas.getContext("2d");
    /*** Gradient ***/
    const gradient = ctx.createLinearGradient(0, 0, 0, 150);
        gradient.addColorStop(0, 'rgba(225,78,202,0.5)');   
        gradient.addColorStop(1, 'rgba(225,78,202,0)');

        dailyBytesDataObj.datasets[0].backgroundColor = gradient;

    return dailyBytesDataObj;

  };

  return { dailySecretsData, dailyBytesData };
};

export const parseConsumptionsMonthly = (details) => {
  let monthlySecretsDataObj = {
    labels: [],
    datasets: [
      {
        label: "Secrets",
        data: [],
        fill: true,
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  let monthlyBytesDataObj = {
    labels: [],
    datasets: [
      {
        label: "Bytes",
        data: [],
        fill: true,
        borderColor: "rgba(225,78,202,1)",
      },
    ],
  };

  let fixedQty = 10;
  details.consumption.monthly.forEach((item) => {
    if (fixedQty === 0) {
      return;
    }
    fixedQty--;
    monthlySecretsDataObj.datasets[0].data.unshift(item.secrets);
    monthlyBytesDataObj.datasets[0].data.unshift(item.bytes);

    let dateParts = item.date.split("T")[0].split("-");
    monthlySecretsDataObj.labels.unshift(
      `${months[dateParts[1]]} ${dateParts[0]}`
    );
    monthlyBytesDataObj.labels.unshift(`${months[dateParts[1]]} ${dateParts[0]}`);
  });

  const monthlySecretsData = (canvas) => {
    const ctx = canvas.getContext("2d");
    /*** Gradient ***/
    const gradient = ctx.createLinearGradient(0, 0, 0, 150);
        gradient.addColorStop(0, 'rgba(75,192,192,0.5)');   
        gradient.addColorStop(1, 'rgba(75,192,192,0)');

        monthlySecretsDataObj.datasets[0].backgroundColor = gradient;

    return monthlySecretsDataObj;

  };

  const monthlyBytesData = (canvas) => {
    const ctx = canvas.getContext("2d");
    /*** Gradient ***/
    const gradient = ctx.createLinearGradient(0, 0, 0, 150);
        gradient.addColorStop(0, 'rgba(225,78,202,0.5)');   
        gradient.addColorStop(1, 'rgba(225,78,202,0)');

        monthlyBytesDataObj.datasets[0].backgroundColor = gradient;

    return monthlyBytesDataObj;

  };

  return { monthlySecretsData, monthlyBytesData };
};
