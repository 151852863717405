import { useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from "reactstrap";
import { CustomSpinner } from "../../components/Spinner/CustomSpinner";
import { fetchSecretDetail } from "../../services/services";
import { CustomAccessLogsModal } from "./CustomAccessLogsModal";
import { CustomItemProfileModal } from "./CustomItemProfileModal";

interface Details {
  secret: Secret;
  id: string;
  showModal: boolean;
}

interface Secret {
  availableFrom: number;
  creation: number;
  expiry: number;
  lastModified: number;
  lockedByOwner: boolean;
  opened: boolean;
  otl: boolean;
  relativeCreation: string;
  secretErased: boolean;
  secretId: string;
  type: string;
}

interface Params {
  detail: Details;
  setDetails: any;
  setSecretId: any;
  isLoading: any;
  setShowAlert: any;
  setAlert: any;
  hideAlert: any;
}

export const SecretDetailModal = ({
  detail,
  setDetails,
  setSecretId,
  isLoading,
  setShowAlert,
  setAlert,
  hideAlert,
}: Params) => {
  const [horizontalTabs, sethorizontalTabs] = useState("profile");

  const { id } = detail;

  useEffect(() => {
    if (id) getSecretDetail();
  }, [id]);

  const [secretDetail, setSecretDetail] = useState({});
  const getSecretDetail = async () => {
    const { details } = await fetchSecretDetail(id);
    if (details) {
      setSecretDetail(details);
    } else {
      setDetails({
        secret: {},
        id: "",
        showModal: false,
      });

      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          onConfirm={() => hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Close"
          btnSize=""
        >
        The secret wasn't found
        </ReactBSAlert>
      );
      
      setShowAlert(true);
    }
  };

  const changeActiveTab = (e, tabState, tabName) => {
    e.preventDefault();
    switch (tabState) {
      case "horizontalTabs":
        sethorizontalTabs(tabName);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Modal
        className="secret-list"
        isOpen={detail.showModal}
        toggle={() => {
          setDetails((prevDetails: Details) => ({
            ...prevDetails,
            showModal: false,
          }));
        }}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setDetails((prevDetails: Details) => ({
                ...prevDetails,
                showModal: false,
              }));
            }}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Secret details</h6>
        </div>
        <ModalBody className="text-center secret-details">
          <Row>
            <Card>
              <CardBody>
                <Nav
                  className="nav-hide-sm nav-pills-primary justify-content-center"
                  pills
                >
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#"
                      className={horizontalTabs === "profile" ? "active" : ""}
                      onClick={(e) =>
                        changeActiveTab(e, "horizontalTabs", "profile")
                      }
                    >
                      Attributes
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      className={`${
                        horizontalTabs === "settings" ? "active" : ""
                      }`}
                      /* onClick={(e) =>
                        changeActiveTab(e, "horizontalTabs", "settings")
                      } */
                      id="security-settings"
                      href="#"
                    >
                      Security settings
                    </NavLink>
                    <UncontrolledTooltip
                      delay={0}
                      placement="top"
                      target="security-settings"
                    >
                      Coming soon
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#"
                      className={`${
                        horizontalTabs === "options" ? "active" : ""
                      }`}
                      onClick={(e) =>
                        changeActiveTab(e, "horizontalTabs", "options")
                      }
                      id="access-logs"
                    >
                      Access logs
                    </NavLink>
                    {/*<UncontrolledTooltip
                      delay={0}
                      placement="top"
                      target="access-logs"
                    >
                      Coming soon
                    </UncontrolledTooltip>*/}
                  </NavItem>
                </Nav>
                <div className="nav-show-sm">
                  <Select
                    className="react-select"
                    placeholder="Choose an option"
                    name="selectTab"
                    onChange={(value) => {
                      sethorizontalTabs(value.value);
                    }}
                    value={{
                      value: horizontalTabs,
                      label:
                        horizontalTabs == "profile"
                          ? "Attributes"
                          : "Access Logs",
                    }}
                    options={[
                      { value: "profile", label: "Attributes" },
                      { value: "options", label: "Access Logs" },
                    ]}
                  />
                </div>
                <TabContent className="tab-space" activeTab={horizontalTabs}>
                  <TabPane tabId="profile">
                    {!secretDetail || !secretDetail.creation ? (
                      <div className="secret-detail">
                        <hr />
                        <CustomSpinner />
                      </div>
                    ) : (
                      <CustomItemProfileModal
                        secretDetail={secretDetail}
                        setSecretId={setSecretId}
                        isLoading={isLoading}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="settings"></TabPane>
                  <TabPane tabId="options">
                    {!secretDetail || !secretDetail.creation ? (
                      <div className="secret-detail">
                        <hr />
                        <CustomSpinner />
                      </div>
                    ) : (
                      <CustomAccessLogsModal secretDetail={secretDetail} />
                    )}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Row>
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setDetails((prevDetails: Details) => ({
                ...prevDetails,
                showModal: false,
              }));
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};
