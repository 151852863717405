import ReactDOM from "react-dom";
import "react-notification-alert/dist/animate.css";
import { App } from "./App";
import "./assets/css/nucleo-icons.css";
import "./assets/demo/demo.css";
import "./assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import swConfig from "./sw-config";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.register(swConfig);
