import { dataTypes } from "../types";

export const dataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case dataTypes.profile:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
