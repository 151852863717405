import { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { Col } from "reactstrap";
import FrontendConfig from "../../assets/js/config";
import useAPIError from "../../commons/hooks/useAPIError";
import { Context } from "../../context/auth/Context";
import { DataContext } from "../../context/data/DataContext";
import { dataTypes, types } from "../../context/types";
import { fetchLoginSSO } from "../../services/auth";
import {
  challenge_from_verifier,
  generateRandomString,
} from "../../services/common";
import { fetchUserProfile } from "../../services/services";

const SSO = () => {
  const history = useHistory();
  const location = useLocation();
  const { addNotify } = useAPIError();

  const { dispatch }: any = useContext(Context);

  // Context for profile
  const { dispatch2 } = useContext(DataContext);

  const [alert, setAlert] = useState(<></>);
  const [showAlert, setShowAlert] = useState(false);

  const handleSSO = async (idp: string) => {
    const idpURL = encodeURIComponent(idp);
    const redirect = encodeURIComponent(FrontendConfig.sso.redirect);
    const cid = encodeURIComponent(FrontendConfig.sso.cid);
    const type = encodeURIComponent(FrontendConfig.sso.type);
    const scope = encodeURIComponent(FrontendConfig.sso.scope);
    const state = encodeURIComponent(generateRandomString());
    const verifier = generateRandomString();
    const code_challenge = encodeURIComponent(
      await challenge_from_verifier(verifier)
    );

    localStorage.setItem("verifier", verifier);

    window.location.assign(
      `${FrontendConfig.auth_url}/oauth2/authorize?identity_provider=${idpURL}&redirect_uri=${redirect}&response_type=${type}&client_id=${cid}&scope=${scope}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=S256`
    );
  }

  const warningWithConfirmMessage = (title: string, message: string) => {
    setShowAlert(true);
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          history.push("/auth/login");
        }}
        confirmBtnBsStyle="primary"
        confirmBtnText="Try again"
      >
        {message}
      </ReactBSAlert>
    );
  };

  const infoWithConfirmMessage = (idp: string) => {
    setShowAlert(true);
    setAlert(
      <ReactBSAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title="Important"
        onConfirm={() => {handleSSO(idp)}}
        confirmBtnBsStyle="primary"
        confirmBtnText="I understand"
      >
        <>
          We detected you already have a regular account (username and
          password).
          <br /> From now on, you can only sign in to SharePass using your
          Single Sing-On (SSO) account. <br />
          Please, try to sign in again.
        </>
      </ReactBSAlert>
    );
  };

  useEffect(() => {
    

    const checkSSOSignIn = async () => {

      const urlHandler = new URLSearchParams(location.search);
      const code = urlHandler.get("code");
      const verifier = localStorage.getItem("verifier") || "null";
     
      //const state = urlHandler.get("state");
      let idp = localStorage.getItem("idp");//state ? (state.split("_"))[0] : false;
      
      if (code && verifier !== "null") {
        const body = {
          code: code,
          verifier: verifier,
        };

        const { isLogged, token, expiresIn, idToken, refreshToken, sessionToken} =
          await fetchLoginSSO(body);

        if (!isLogged) {
          warningWithConfirmMessage(
            "Error",
            "We couldn't authenticate you, please try with a different account."
          );
        } else {
          const { redirect, details } = await fetchUserProfile(token);

          if (!redirect) {
            const {
              consumption,
              counters,
              email,
              lastname,
              lightTheme,
              sidebarToggle,
              advancedMode,
              name,
              revealMessage,
              sendMessage,
              sso,
              subscription,
              userId,
              isAdmin,
              welcomeScreen,
              publicKeysCred,
              webhook,
              defaultSecretTemplate,
              defaultSecretTemplateReceive,
              keyPair,
              purposeMode,
              instance,
            } = details;

            if (localStorage.getItem("isMobile")) {
              
              const expireTime = expiresIn * 1000 + Date.now();
              localStorage.setItem("token", token);
              localStorage.setItem("refreshToken", refreshToken);
              localStorage.setItem("expiresIn", expireTime);
              localStorage.setItem("userId", userId);
              localStorage.setItem("idToken", idToken);

              window.location = `sharepassapp://app/dashboard/${sessionToken}`;
            } else {
              const expireTime = expiresIn * 1000 + Date.now();
              localStorage.setItem("token", token);
              localStorage.setItem("refreshToken", refreshToken);
              localStorage.setItem("expiresIn", expireTime);
              localStorage.setItem("userId", userId);
              localStorage.setItem("idToken", idToken);

              /*dispatch2({
                type: dataTypes.profile,
                payload: { ...details },
              });*/
              dispatch2({
                type: dataTypes.profile,
                payload: {
                  consumption,
                  counters,
                  revealMessage,
                  sendMessage,
                  sso,
                  subscription,
                  welcomeScreen,
                  publicKeysCred,
                  webhook,
                  defaultSecretTemplate,
                  defaultSecretTemplateReceive,
                  keyPair,
                },
              });

              dispatch({
                type: types.login,
                payload: {
                  name,
                  lastName: lastname,
                  email,
                  userDetails: { name, lastname, email },
                  lightTheme,
                  isAdmin,
                  sidebarToggle,
                  advancedMode,
                  purposeMode,
                  instance,
                },
              });
            }
          } else {
            warningWithConfirmMessage(
              "Error",
              "We couldn't authenticate you, please try with a different account."
            );
          }
        }
      } else if(verifier === "null" && localStorage.getItem("isMobile")){
        await handleSSO(idp);
      } else {
        if (urlHandler.get("error_description")) {
          if (
            urlHandler
              .get("error_description")
              ?.includes("Already found an entry for username")
          ) {
            infoWithConfirmMessage(idp);
          } else {
            warningWithConfirmMessage(
              "Error",
              "We couldn't authenticate you, please try with a different account."
            );
          }
        } else {
          warningWithConfirmMessage("Error", "Incorrect URL!");
        }
      }
    };

    checkSSOSignIn();
  }, []);

  //redirect-positioning

  return (
    <>
      {showAlert && alert}
      <div className="content">
        <div className="row">
          <Col className="text-center" lg="12" md="12" sm="12" xs="12">
            <h3>SIGNING IN, PLEASE WAIT ...</h3>
          </Col>
        </div>
      </div>
    </>
  );
};

export default SSO;
