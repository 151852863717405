import React, { useCallback, useState } from "react";

export const APIErrorContext = React.createContext({
  error: null,
  addNotify: (message: string, status: string) => {},
  removeError: () => {},
});

export default function APIErrorProvider({ children }: any) {
  const [error, setError] = useState<any>(null);

  const removeError = () => setError(null);

  const addNotify = (message: string, status: string) =>
    setError({ message, status });

  const contextValue: any = {
    error,
    addNotify: useCallback((message, status) => addNotify(message, status), []),
    removeError: useCallback(() => removeError(), []),
  };

  return (
    <APIErrorContext.Provider value={contextValue}>
      {children}
    </APIErrorContext.Provider>
  );
}
