import { ErrorMessage, Field } from "formik";
import React from "react";
import { FormGroup, Label, Row, Col } from "reactstrap";
import { CustomErrorField } from "./CustomErrorField";

export const InputUpdateProfile = () => {
  return (
    <Row>
      <Col lg="6" md="6" sm="6" xs="12">
        <Label>First name</Label>
        <FormGroup>
          <Field type="text" name="firstname" />
        </FormGroup>
        <ErrorMessage name="firstname" component={CustomErrorField} />
      </Col>
      <Col lg="6" md="6" sm="6" xs="12">
        <Label>Last name</Label>
        <FormGroup>
          <Field type="text" name="lastname" />
        </FormGroup>
        <ErrorMessage name="lastname" component={CustomErrorField} />
      </Col>
    </Row>
  );
};