
const VideoWrapper = () => {
  return (
    <video playsInline muted loop="loop" autoPlay="autoplay" id="bg-video">
        <source src="https://kbi.media/wp-content/uploads/2022/05/Kbi-Media-Reel-1.m4v" type="video/mp4" />
    </video>
  );
};

export default VideoWrapper;
