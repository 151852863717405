import { faApple, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { UncontrolledTooltip } from "reactstrap";
import SSOButton from "../SSOButton";

interface Props {
  handleSSO: any;
  loading: boolean;
}

export const MobileSSOButtons = ({ handleSSO, loading }: Props) => {
  return (
    <>
      <hr />
      <SSOButton
        id="ssoGoogleBtn"
        handleSSO={() => {
          handleSSO("Google");
        }}
        icon={faGoogle}
        loading={loading}
        color="google"
        text="Sign in with Google"
        className="btn-small-login-social"
      />
      <UncontrolledTooltip delay={0} target="ssoGoogleBtn" placement="top">
        Sign in with Google
      </UncontrolledTooltip>
      <SSOButton
        id="ssoWWPassBtn"
        handleSSO={() => {
          handleSSO("WWPass");
        }}
        loading={loading}
        color="twitter"
        iconImg={require("../../../assets/img/wwpass.png").default}
        text="Sign in with WWPass"
        className="btn-small-login-social"
      />
      <UncontrolledTooltip delay={0} target="ssoWWPassBtn" placement="top">
        Sign in with WWPass
      </UncontrolledTooltip>
      <SSOButton
        id="ssoAppleBtn"
        handleSSO={() => {
          handleSSO("SignInWithApple");
        }}
        loading={loading}
        color="black"
        icon={faApple}
        text="Sign in with Apple"
        className="btn-small-login-social"
      />
      <UncontrolledTooltip delay={0} target="ssoAppleBtn" placement="top">
        Sign in with Apple
      </UncontrolledTooltip>
    </>
  );
};
