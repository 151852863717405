import {
  faCamera,
  faImage,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Html5Qrcode } from "html5-qrcode";
import React, { useState } from "react";
import QrReader from "react-weblineindia-qrcode-scanner";
import { Label } from "reactstrap";
import { LockIconTooltip } from "./LockIconTooltip";

export const InputQrWizard = ({ setQrData, qrData }) => {
  const resetQr = () => {
    setQrData("No QR code scanned yet");

    if (document.getElementById("reader")) {
      document.getElementById("reader").value = "";
    }
  };

  const [scanWithCamera, setScanWithCamera] = useState(false);
  const [scanWithFile, setScanWithFile] = useState(false);

  const [webScan, setWebScan] = useState({
    delay: 100,
    result: "No data",
  });
  const handleScan = (data) => {
    if (data) {
      setQrData(data);
      setWebScan((prevScan) => ({ ...prevScan, result: data }));
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files.length === 0) {
      return;
    }

    const imageFile = e.target.files[0];

    let fileQrcodeScanner = new Html5Qrcode("reader");
    // Scan QR Code
    fileQrcodeScanner
      .scanFile(imageFile, true)
      .then(async (decodedText) => {
        setQrData(decodedText);
      })
      .catch(async (err) => {
        setQrData("No QR code detected.");
      });
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };

  return (
    <div>
      {!scanWithCamera && !scanWithFile && (
        <div className="scan-box">
          <FontAwesomeIcon
            icon={faImage}
            color="#0d6efd"
            width="40px"
            className="font-qr"
            onClick={() => {
              setScanWithCamera(false);
              setScanWithFile(true);
            }}
          />
          <FontAwesomeIcon
            icon={faCamera}
            color="#0d6efd"
            width="40px"
            className="font-qr"
            onClick={() => {
              setScanWithCamera(true);
              setScanWithFile(false);
            }}
          />
        </div>
      )}
      {(scanWithFile || scanWithCamera) && (
        <div
          className={`input-camera ${scanWithCamera ? "custom-border" : ""}`}
        >
          {scanWithFile && (
            <input
              type="file"
              onChange={handleChange}
              id="reader"
              accept="image/*"
              className="input-file"
            />
          )}
          {scanWithCamera && (
            <QrReader
              delay={webScan.delay}
              style={previewStyle}
              onError={handleError}
              onScan={handleScan}
            />
          )}
        </div>
      )}
      <Label>
        QR data
        <LockIconTooltip />
      </Label>
      <div
        className={`eee-field custom-qr-data ${
          qrData === "No QR code scanned yet" ? "danger-txt" : "info-txt"
        }`}
      >
        {qrData !== "No QR code scanned yet" && (
          <FontAwesomeIcon
            icon={faTimesCircle}
            color="#0d6efd"
            className="custom-font-icon"
            onClick={resetQr}
          />
        )}
        {qrData}
      </div>
    </div>
  );
};
