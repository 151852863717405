import {
  faEye,
  faHourglassStart,
  faIdCardAlt,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { InputAccessibility } from "./InputAccessibility";
import { InputAuthorization } from "./InputAuthorization";
import { InputAvailability } from "./InputAvailability";
import { InputVisibility } from "./InputVisibility";
import { NavItemSetting } from "./NavItemSetting";
import FrontendConfig from "../../assets/js/config";


const tryRequire = () => {
  try {
    const env = FrontendConfig.env;
    return require("../../envs/" + env + "/views/components/SecretSettings");
  } catch (err) {
    return null;
  }
}

export const SecretSettings = tryRequire() ? tryRequire().SecretSettings 
:  ({ admin, setConfig, config, qty2Aux}: any) => {
  const [pageTabs, setpageTabs] = useState("visibility");

  const history = useHistory();

  /*const [openedCollapseOne, setopenedCollapseOne] = useState(
    history.location.pathname === "/admin/template/edit" ||
      history.location.pathname === "/admin/template/new-template" ||
      history.location.pathname === "/admin/settings/profile"
  );*/

  const changeActiveTab = (e: any, tabState: string, tabName: string) => {
    e.preventDefault();
    switch (tabState) {
      case "pageTabs":
        setpageTabs(tabName);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Nav
        className="nav-pills-primary nav-pills-icons justify-content-center"
        pills
      >
        <NavItemSetting
          icon={faEye}
          pageTabs={pageTabs}
          changeActiveTab={changeActiveTab}
          tabName="visibility"
          title="Visibility"
        />
        <NavItemSetting
          icon={faHourglassStart}
          pageTabs={pageTabs}
          changeActiveTab={changeActiveTab}
          tabName="availability"
          title="Availability"
          classes={config.errorAvailability ? "error-tab" : ""}
        />
        <NavItemSetting
          icon={faIdCardAlt}
          pageTabs={pageTabs}
          changeActiveTab={changeActiveTab}
          tabName="accessibility"
          title="Accessibility"
        />
        <NavItemSetting
          icon={faShieldAlt}
          pageTabs={pageTabs}
          changeActiveTab={changeActiveTab}
          tabName="authorization"
          title="Authorization"
          classes={config.errorAuthorization ? "error-tab" : ""}
        />
      </Nav>
      <TabContent
        className="tab-space tab-subcategories secret-setting"
        activeTab={pageTabs}
      >
        <TabPane tabId="visibility">
          <InputVisibility config={config} setConfig={setConfig} />
        </TabPane>
        <TabPane tabId="availability">
          <InputAvailability
            config={config}
            setConfig={setConfig}
            qty2Aux={qty2Aux}
          />
        </TabPane>
        <TabPane tabId="accessibility">
          <InputAccessibility config={config} setConfig={setConfig} />
        </TabPane>
        <TabPane tabId="authorization">
          <InputAuthorization admin={admin? true: false} config={config} setConfig={setConfig} />
        </TabPane>
      </TabContent>
    </>
  );
};
