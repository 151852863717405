import { faFile } from "@fortawesome/free-regular-svg-icons";
import {
  faAddressCard,
  faCheckCircle,
  faCode,
  faComment,
  faKey,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, UncontrolledTooltip } from "reactstrap";

interface Params {
  title: string;
  value: string;
  hasIcon: boolean;
}

export const SecretDetailsItem = ({ title, value, hasIcon }: Params) => {
  let secretType = {};

  if (title == "Type") {
    switch (value) {
      case "password":
        secretType.icon = faKey;
        secretType.iconName = "Password";
        break;
      case "message":
        secretType.icon = faComment;
        secretType.iconName = "Message";
        break;
      case "credentials":
        secretType.icon = faAddressCard;
        secretType.iconName = "Credentials";
        break;
      case "custom":
        secretType.icon = faCode;
        secretType.iconName = "JSON";
        break;
      case "QR":
        secretType.icon = faQrcode;
        secretType.iconName = "QR";
        break;
      case "file":
        secretType.icon = faFile;
        secretType.iconName = "File";
        break;
      default:
        break;
    }
  }

  return (
    <h5>
      <div className="flex">
        <Col md={4} className="right">
          <b>{title}</b>
        </Col>
        {!hasIcon && (
          <Col md={8} className="left">
            {title == "Type" ? (
              <>
                <FontAwesomeIcon id={`type-${value}`} icon={secretType.icon} />
                <UncontrolledTooltip
                  delay={0}
                  target={`type-${value}`}
                  placement="right"
                >
                  {secretType.iconName}
                </UncontrolledTooltip>
              </>
            ) : (
              value
            )}
          </Col>
        )}
        {hasIcon && (
          <div className="info-list">
            <FontAwesomeIcon
              id="PinTooltip"
              icon={faCheckCircle}
              className={value ? "text-success" : ""}
              color={value ? "" : "#ccc"}
            />
          </div>
        )}
      </div>
    </h5>
  );
};
