import React from "react";
import { Col, Label, Row } from "reactstrap";

interface Params {
  config: any;
  setConfig: any;
}

export const InputAccessibility = ({ config, setConfig }: Params) => {
  const handleToggle = () => {
    let toggle = !(
      typeof config.lockedByOwner !== "undefined" && config.lockedByOwner
    );
    setConfig((prevConfig: any) => ({ ...prevConfig, lockedByOwner: toggle }));
  };
  return (
    <Row className="mt-2">
      <Col md="3"></Col>
      <Col md="6">
        <Label>
          You can unlock the secret later on, accessing to "My secrets" section.
        </Label>

        <div
          id="switch-accessibility"
          onClick={() => {
            handleToggle();
          }}
          checked={
            typeof config.lockedByOwner !== "undefined" && config.lockedByOwner
          }
          className="btn-lock-unlock text-center"
        >
          {typeof config.lockedByOwner !== "undefined" &&
          config.lockedByOwner ? (
            <i className="fa fa-2x fa-lock text-danger"></i>
          ) : (
            <i className="fa fa-2x fa-unlock text-success"></i>
          )}
        </div>
      </Col>
    </Row>
  );
};
