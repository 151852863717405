import { faCertificate, faCog, faCopy, faDice, faInfoCircle, faKey, faLock, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  CustomInput,
  FormGroup,
  Label,
  Row,
  Input,
  UncontrolledTooltip
} from "reactstrap";
import useAPIError from "../../commons/hooks/useAPIError";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Context } from "../../context/auth/Context";
//import {kyber1024} from "crystals-kyber"
import { dataTypes, types } from "../../context/types";
import {
  fetchUpdateGeneralSettings
} from "../../services/services";
import {
  generateCRYSTALSKyberKeyPair,
  generateRSAKeyPair,
  generateRandomString,
} from "../../utils/crypto";
import { faCreativeCommons, faKeybase } from "@fortawesome/free-brands-svg-icons";
import { SweetAlertRenderProps } from "react-bootstrap-sweetalert/dist/types";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

export const EnvelopeSettings = ({ tabName, dispatchProfile, profile }: any) => {

  const history = useHistory();

  // Get name from context
  const { dispatch } = useContext(Context);

  // Notify
  const { addNotify } = useAPIError();

  /* Store initial values to the form */
  const [rsaEncryption, setRsaEncryption] = useState(
      profile &&
      profile.keyPair &&
      profile.keyPair.alg == "rsa"
  );

  const [crystalsKyberEncryption, setCrystalsKyberEncryption] = useState(
    profile &&
    profile.keyPair &&
    profile.keyPair.alg == "crystalsKyber"
  );

  const [disabledEnvelope, setDisabledEnvelope] = useState(
    profile &&
    (!profile.keyPair ||
    !profile.keyPair.alg)
  );

  const [copied, setCopied] = useState(false);
  const [masterKey,setMasterKey] = useState("");
  const [masterKeyType,setMasterKeyType] = useState("password");
  const [alg,setAlg] = useState("");
  const [processing, setProcessing] = useState(false);
  const [hasError, setHasError] = useState(false);
  
  /*
   * Show alert before delete a credential
   */
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  /*
  * Show alert before delete a credential
  */
  const [showConfirmDisableModal, setShowConfirmDisableModal] = useState(false);
  
  const handleAlgSwitchs = (alg:string, active: boolean) => {
    switch(alg){
      case "rsa":
        setRsaEncryption(active);
        break;
      case "crystalsKyber":
        setCrystalsKyberEncryption(active);             
        break;
      default:
        setDisabledEnvelope(active);
        break;
    };
  };

  const createKeyPair = async (alg: string) => {
      setAlg(alg);
      setShowConfirmModal(true); 
      handleAlgSwitchs(alg, profile.keyPair && profile.keyPair.alg == alg ? true : false);
  };
  
  const disableKeyPair = async () => {
    setShowConfirmDisableModal(true);
  };

  const generateRandomMasterKey = function () {
    let generatedRandomString = generateRandomString(32);
    navigator.clipboard.writeText(generatedRandomString);
    setMasterKey(generatedRandomString);
  };

  const copyMasterKey = function () {
    navigator.clipboard.writeText(masterKey);
    setCopied(true);
    setTimeout(()=>{
      setCopied(false);
    }, 3000);
  };


  return (
    <>
      {showConfirmModal && <ReactBSAlert
        style={{ display: "block", marginTop: "-200px" }}
        title="Encrypt and save keys"
        customClass="disabled"
        onConfirm={async () => {
          console.log(masterKey);
          if(!processing){

            if(masterKey.length != 32){
              setHasError(true);
              return false;
            }

            setProcessing(true);

            let keyPairData;
            switch(alg){
              case "rsa":
                keyPairData = await generateRSAKeyPair(masterKey);
                break;
              case "crystalsKyber":
                keyPairData = await generateCRYSTALSKyberKeyPair(masterKey);              
                break;
            };

            console.log("keyPairData", keyPairData);

            if(keyPairData){
              let body = {
                keyPair: {
                  alg: alg,
                  publicKey: keyPairData["publicKey"],
                  sealedPrivateKey: keyPairData["sealedPrivateKey"],
                  iv: keyPairData["iv"],
                  active: true
                }
              };
             
              let response = await fetchUpdateGeneralSettings(body);

              if(response.status == "Success"){

                let newKeyPair = profile.keyPair || {};

                newKeyPair = {
                  publicKey: keyPairData["publicKey"],
                  sealedPrivateKey: keyPairData["sealedPrivateKey"],
                  iv: keyPairData["iv"],
                  alg: alg
                }
        
                dispatchProfile({
                  type: dataTypes.profile,
                  payload: {
                    ...profile,
                    profileTabName: tabName,
                    keyPair: newKeyPair,
                  },
                });

                addNotify("New key pair added successfully", "success");
                handleAlgSwitchs(alg,true);
                setShowConfirmModal(false);
              }else{
                addNotify("There was an error adding your key pair", "danger");
                handleAlgSwitchs(alg, profile.keyPair && profile.keyPair.alg == alg ? true : false);
                setShowConfirmModal(false);
              }
            }else{
              addNotify("There was an error adding your key pair", "danger");
              handleAlgSwitchs(alg, profile.keyPair && profile.keyPair.alg == alg ? true : false);
              setShowConfirmModal(false);
            }

            setMasterKey("");

            setProcessing(false);   
          }      
        }}
        onCancel={() => {
          if(!processing){
            setShowConfirmModal(false);
            setMasterKey('');
            setMasterKeyType('password');
          }
        }}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Encrypt!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
          <p>
            You need to specify a master key of <strong>32 characters</strong> that will be required for opening confidential information sent to you. 
            This key needs to be saved safe and secure for its usage. Our recommendation is for you to use Static Password feature for Yubikeys OTP.
          </p>
            
          <FormGroup className={ hasError?"form-formik has-danger":"form-formik"}>
            <label>Master key</label>
            <div className="input-group input-group-masterkey">
              <div className="input-group-prepend">
                <span 
                  className="input-group-text cursor-p"
                  onClick={() => setMasterKeyType(masterKeyType == "password" ? "text" : "password")}
                >
                <FontAwesomeIcon
                    icon={masterKeyType == "password" ? faEye : faEyeSlash}
                    color="#0d6efd"
                  />
                </span>
              </div>
              <Input
                id="masterKey"
                name="masterKey"
                type={masterKeyType}
                placeholder="Master key"
                onChange={(e) => {
                  setMasterKey(e.target.value);
                  if(e.target.value.length == 32){
                    setHasError(false);
                  }
                }}
                className="form-control"
                value={masterKey}
                disabled={processing}
              />
              <div className="input-group-append">
                <span 
                  className="input-group-text cursor-p"
                  onClick={() => {
                    generateRandomMasterKey();
                  }}
                >
                <FontAwesomeIcon
                    icon={faDice}
                    color="#0d6efd"
                  />
                </span>
              </div>
              <div className="input-group-append">
                <span 
                  className="input-group-text text-primary cursor-p"
                  onClick={() => {
                    if(!copied){
                      copyMasterKey();
                    }
                  }}
                >
                {!copied ? <FontAwesomeIcon
                    icon={faCopy}
                    color="#0d6efd"
                  /> : "Copied!"}
                </span>
              </div>
            </div>
            <div style={{height:"40px"}}>
              {hasError && <div className="text-danger  mt-2" role="alert">
                <span>The master key needs to be 32 characters.</span>
              </div>}
              {processing && <div className="mt-2">
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                ></FontAwesomeIcon>{" "}
                This operation may take few seconds...
              </div>}
            </div>
          </FormGroup>
      </ReactBSAlert>}

      {showConfirmDisableModal && <ReactBSAlert
        style={{ display: "block", marginTop: "-200px" }}
        title="Are you sure?"
        customClass="disabled"
        onConfirm={async () => {
          if(!processing && profile.keyPair && profile.keyPair.alg){
            setProcessing(true);

            let body = {
              keyPair: {
                alg: profile.keyPair.alg,
                publicKey: profile.keyPair.publicKey,
                sealedPrivateKey: profile.keyPair.sealedPrivateKey,
                iv: profile.keyPair.iv,
                active: false
              }
            };
           
            let response = await fetchUpdateGeneralSettings(body);

            if(response.status == "Success"){
      
              dispatchProfile({
                type: dataTypes.profile,
                payload: {
                  ...profile,
                  profileTabName: tabName,
                  keyPair: {},
                },
              });

              addNotify("Envelope encryption has been disabled successfully.", "success");
              handleAlgSwitchs("rsa", false);
              handleAlgSwitchs("crystalsKyber", false);
              handleAlgSwitchs("disabled", true);
              setShowConfirmDisableModal(false);
            }else{
              addNotify("There was an error disabling envelope encryption.", "danger");
              handleAlgSwitchs("disabled", false);
              setShowConfirmDisableModal(false);
            }

            setProcessing(false);
          }
        }}
        onCancel={() => {
          if(!processing){
            setShowConfirmDisableModal(false);
          }
        }}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Ok"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >

          <p>
            You won't be able to create more envelopes until you reactivate a new encryption algorithm. Old envelopes will keep to use the same master key to be opened.
          </p>
          <div style={{height:"40px"}}>
              {processing && <div className="mt-2">
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                ></FontAwesomeIcon>{" "}
                This operation may take few seconds...
              </div>}
            </div>
      </ReactBSAlert>}
      <Row>
        <Col lg="12" md="12">
          <Card className="custom-card profile">
            <CardBody>
              <CardTitle tag="h3">Encryption setup</CardTitle>
              <Row>
                <Col lg="12" md="12">
                  <p>Set up your encryption keys to securely and easily receive confidential information from third parties.</p>
                  <Row>
                    {false && <Col md="4">
                        <div className="d-flex align-items-center ns-mode-switch">
                          <div className="mr-2">
                          <h4>DISABLED</h4>
                          </div>
                          <CustomInput
                            type="switch"
                            id="disabledEnvelope"
                            name="disabledEnvelope"
                            onChange={() => {
                              if(!disabledEnvelope){
                                disableKeyPair();
                              }
                            }}
                            checked={disabledEnvelope}
                          />
                        </div>
                    </Col>}
                    <Col md="4">
                        <div className="d-flex align-items-center ns-mode-switch">
                          <div className="mr-2">
                          <h4>{rsaEncryption? "Enabled" : "Disabled"}</h4>
                          </div>
                          <CustomInput
                            type="switch"
                            id="rsaKeyPair"
                            name="rsaKeyPair"
                            onChange={() => {
                              if(!rsaEncryption){
                                createKeyPair("rsa");
                              }else{
                                disableKeyPair();
                              }
                            }}
                            checked={rsaEncryption}
                          />
                        </div>
                        <small>The key encapsulation mechanism algorithm used is <b>RSA 4096</b>.</small>
                    </Col> 
                    <Col style={{display:"none"}} md="4">
                        <div className="d-flex align-items-center ns-mode-switch">
                          <div className="mr-2">
                          <h4>CRYSTALS-Kyber 1024</h4>
                          </div>
                          <CustomInput
                            type="switch"
                            id="crystalsKyberKeyPair"
                            name="crystalsKyberKeyPair"
                            onChange={() => {
                              if(!crystalsKyberEncryption){
                                createKeyPair("crystalsKyber");
                              }
                            }}
                            checked={crystalsKyberEncryption}
                          />
                        </div>
                    </Col>
                  </Row>
                  <br />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
