import { ErrorMessage, Field } from "formik";
import React from "react";
import { QRCode } from "react-qrcode-logo";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { CustomErrorField } from "./CustomErrorField";

export const MFAFormVerify = ({
  TOPTURL,
  secret,
  notify,
  values,
  setFieldValue,
}: {
  TOPTURL: string;
  secret: string;
  notify: any;
  values: any;
  setFieldValue: any;
}) => {
  return (
    <>
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <div className="top10">
            <Label>MFA QR code</Label>
            <div>
              <QRCode
                value={TOPTURL}
                size={250}
                logoImage="/assets/img/SP.png"
                logoWidth={40}
              />
            </div>
          </div>
          <Row>
            <Col lg="8" md="8" sm="8" xs="8">
              <Label>Secret</Label>
              <FormGroup className="flex">
                <Input
                  type="text"
                  className="form-control clickable"
                  readOnly={true}
                  value={secret}
                  onClick={() => {
                    navigator.clipboard.writeText(secret);

                    notify("Copied!", "tr", "success");
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="8" md="8" sm="8" xs="8">
              <Label>Enter your verification code</Label>
              <FormGroup>
                <input
                  id="verifyCode"
                  type="text"
                  className="form-control"
                  value={values.verifyCode}
                  onChange={(e) => {
                    const re = /^[0-9\b]{1,6}$/;
                    // if value is not blank, then test the regex
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setFieldValue("verifyCode", e.target.value);
                    }
                  }}
                />
              </FormGroup>
              <ErrorMessage name="verifyCode" component={CustomErrorField} />
            </Col>
          </Row>

          <Row>
            <Col lg="8" md="8" sm="8" xs="8">
              <Label>Friendly device name</Label>
              <FormGroup>
                <Field type="text" name="friendlyDevice" />
              </FormGroup>
              <ErrorMessage
                name="friendlyDevice"
                component={CustomErrorField}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
