import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

export const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ||
        (rest.location &&
          (
            rest.location.pathname == "/auth/enroll-security-key" ||
            rest.location.pathname.startsWith("/auth/envelope")
          )) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/admin/dashboard" />
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};
