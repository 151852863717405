import { ErrorMessage, Field } from "formik";
import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { CustomErrorField } from "./CustomErrorField";

export const SecurityKeyInput = ({
  values,
  setFieldValue,
}: {
  values?: any;
  setFieldValue?: any;
}) => {
  return (
    <>
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <Row>
            <Col lg="8" md="8" sm="8" xs="8">
              <Label>Key name</Label>
              <FormGroup>
                <Field type="text" name="keyName" />
              </FormGroup>
              <ErrorMessage name="keyName" component={CustomErrorField} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
