export const customColumns = [
  {
    Header: "Created",
    accessor: "created",
  },
  {
    Header: (
      <>
        Private
        <br />
        description
      </>
    ),
    accessor: "privDesc",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Label",
    accessor: "label",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Opened",
    accessor: "opened",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Lock",
    accessor: "locked",
    sortable: true,
    filterable: false,
  },
  {
    Header: "Status",
    accessor: "status",
    sortable: false,
    filterable: false,
  },
  {
    Header: "",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const usersColumns = [
  {
    Header: "Name",
    accessor: "name",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Status",
    accessor: "status",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Created",
    accessor: "created",
    sortable: true,
    filterable: false,
  },
  {
    Header: "Last activity",
    accessor: "lastActivity",
    sortable: false,
    filterable: false,
  },
  {
    Header: "",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
  
];

export const templateColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const securityKeyColumns = [
  {
    Header: "Creation",
    accessor: "createdAt",
    sortable: true,
    filterable: false,
    canFilter:false
  },
  {
    Header: "Name",
    accessor: "name",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Label",
    accessor: "label",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Uses",
    accessor: "uses",
    sortable: false,
    filterable: false,
  },
  {
    Header: "Last use",
    accessor: "lastUse",
    sortable: false,
    filterable: false,
  },
  {
    Header: "",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const initiaFilterlValues = {
  otl: "Any",
  type: "",
  privDesc: "",
  publicDesc: "",
  lockedByOwner: "Any",
  label: "",
  color: "",
  pin: "Any",
  expired: "Any",
  ipList: "Any",
  opened: "Any",
};
