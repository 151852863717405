interface FilterParams {
  otl?: boolean;
  type?: string;
  privateDescription?: string;
  publicDescription?: string;
  lockedByOwner?: boolean;
  label?: CustomLabel; // An object that matches the codeName AND/OR the color attribute
  color?: string;
  pin?: boolean; // A boolean if the secret contains a pin (static or dynamic) in the pin attribute
  expired?: boolean; // A boolean that indicates the secrets that have the expiry value already expired
  ipList?: boolean; // A boolean if the secret contains at least one IP address restriction in the ipList attribute
  opened?: boolean;
}

interface CustomLabel {
  codeName?: string;
  color?: string;
}

export const filterBody = (values: any) => {
  let filterParams: FilterParams = {};

  // Strings
  if (values.privDesc !== "") filterParams.privateDescription = values.privDesc;

  if (values.publicDesc !== "")
    filterParams.publicDescription = values.publicDesc;

  if (values.label !== "" && values.label !== "Any")
    filterParams.label = { codeName: values.label };

  if (values.color !== "" && values.color !== "Any")
    filterParams.label = {
      ...filterParams.label,
      color: values.color,
    };

  if (values.type !== "" && values.type !== "Any")
    filterParams.type = values.type;

  // Booleans
  if (values.otl !== "Any") {
    filterParams.otl = !(values.otl === "Yes");
  }

  if (values.lockedByOwner !== "Any") {
    filterParams.lockedByOwner = values.lockedByOwner === "Yes";
  }

  if (values.pin !== "Any") {
    filterParams.pin = values.pin === "Yes";
  }

  if (values.expired !== "Any") {
    filterParams.expired = values.expired === "Yes";
  }

  if (values.opened !== "Any") {
    filterParams.opened = values.opened === "Yes";
  }

  if (values.ipList !== "Any") {
    filterParams.ipList = values.ipList === "Yes";
  }

  return { ...filterParams };
};
