import { ErrorMessage, Field } from "formik";
import React from "react";
import { FormGroup, Label } from "reactstrap";
import { CustomErrorField } from "./CustomErrorField";
import { LockIconTooltip } from "./LockIconTooltip";

export const InputMessageWizard = () => {
  return (
    <div>
      <Label>
        Message
        <LockIconTooltip />
      </Label>
      <FormGroup>
        <Field className="eee-field" as="textarea" name="message" />
      </FormGroup>
      <ErrorMessage name="message" component={CustomErrorField} />
    </div>
  );
};
