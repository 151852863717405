import FrontendConfig from "../assets/js/config";


const tryRequire = () => {
  try {
    const env = FrontendConfig.env;
    return require("../envs/" + env + "/utils/settings");
  } catch (err) {
    return null;
  }
}


export const parseLabels = tryRequire() ? tryRequire().parseLabels 
:  (data) => {
  data.forEach((label) => {
    label.label = label.codeName;
    label.value = label.id? label.id : label.codeName;
  });

  return data;
};

export const parseClassLabels = tryRequire() ? tryRequire().parseClassLabels 
:  (data) => {
  data &&
    data.forEach((label) => {
      label.label = label.codeName;
      label.value = label.id? label.id : label.codeName;
      label.color = getClassName(label.color);
    });
  return data;
};

export const parsePublicKeys = tryRequire() ? tryRequire().parsePublicKeys 
:  (data) => {
  data &&
    data.forEach((publicKey) => {
      publicKey.valueAux = publicKey.value;
      publicKey.labelAux = publicKey.label;
      publicKey.label = publicKey.name;
      publicKey.value = publicKey.id;
    });
  return data;
};

const getClassName = tryRequire() ? tryRequire().getClassName 
:  (color) => {
  let className;
  switch (color) {
    case "#1d8cf8":
      className = "info";
      break;
    case "#00f2c3":
      className = "success";
      break;
    case "#ff8d72":
      className = "warning";
      break;
    case "#f63b3b":
      className = "danger";
      break;
    case "#0d6efd":
      className = "primary";
      break;
    default:
      className = color;
      className = "primary";
      break;
  }

  return className;
};

export const getColorName = tryRequire() ? tryRequire().getColorName 
:  (color) => {
  let colorName;
  switch (color) {
    case "info":
      colorName = "#1d8cf8";
      break;
    case "success":
      colorName = "#00f2c3";
      break;
    case "warning":
      colorName = "#ff8d72";
      break;
    case "danger":
      colorName = "#f63b3b";
      break;
    default:
      colorName = "#0d6efd";
      break;
  }

  return colorName;
};
