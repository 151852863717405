import { faCog, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import useAPIError from "../../commons/hooks/useAPIError";
import { Context } from "../../context/auth/Context";
import { DataContext } from "../../context/data/DataContext";
import { types } from "../../context/types";
import { b64decode, b64encode } from "../../services/common";
import {
  fetchLabels,
  fetchNewSecretCredentialParseAdd,
  fetchNewSecretCredentialRequest,
} from "../../services/settings";
import { getColorName, parseClassLabels } from "../../utils/settings";
import { CustomErrorField } from "../components/CustomErrorField";
import { CustomModalLabel } from "../components/CustomModalLabel";
const NewSecurityKeyLocal = ({ setMode }: any) => {
  const history = useHistory();

  // Notify
  const { addNotify } = useAPIError();
  const { user, dispatch } = useContext(Context);

  // Context for profile
  const { profile, dispatch2 } = useContext(DataContext);

  /* Show label modal */
  const [modalClassic, setModalClassic] = useState(false);

  const [multipleSelect, setmultipleSelect] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);

  /*
   * We use this condition to put only one label, if we want to add more labels
   * in the future, we will need to delete this condition.
   */
  useEffect(() => {
    if (multipleSelect.length > 1) {
      setmultipleSelect((prevValues) => [prevValues[1]]);
    }
  }, [multipleSelect]);

  const addNewLabel = () => {
    setModalClassic(!modalClassic);
  };

  /*
   * Listen close modal
   * After close modal (maybe succes or cancelled by the user) we need to update the array of labels
   * In the future, we will remove, and by replaced by hooks of redux or context.
   */
  useEffect(() => {
    const getLabels = async () => {
      const { status, details, redirect } = await fetchLabels();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        const auxLabels = parseClassLabels(details);
        setLabelOptions([
          {
            label: "Select an option",
            value: "Select an option",
            isDisabled: true,
          },
          ...auxLabels,
        ]);
      }
    };

    if (!modalClassic) getLabels();
  }, [modalClassic]);

  return (
    <>
      <h3>Local security keys</h3>
      <div>
        <p>
          Use the following form to add a security key (e.g. Yubikey), if you
          have it with you and you will send it to the respective user after.
        </p>
      </div>
      <Row>
        <Col lg="8" md="8" sm="12" xs="12">
          <Formik
            initialValues={{
              keyName: "",
            }}
            validate={(values) => {
              let errors: { keyName?: string } = {};
              const error = "This field is required";

              if (!values.keyName) {
                errors.keyName = error;
              }

              if (values.keyName && values.keyName.length > 30) {
                errors.keyName = "Use 30 chars max";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              let endData: any = {
                keyName: values.keyName,
              };

              if (
                multipleSelect.length > 0 &&
                multipleSelect[0].color &&
                multipleSelect[0].codeName
              ) {
                endData["label"] = {
                  codeName: multipleSelect[0].codeName,
                  color: getColorName(multipleSelect[0].color),
                };
              }

              try {
                const { status, details, message, redirect } =
                  await fetchNewSecretCredentialRequest();

                if (redirect) {
                  dispatch({
                    type: types.logout,
                  });
                  history.push("/auth/login");
                } else if (status === "Success") {
                  let credOptions = details;
                  let challenge = credOptions.challenge;
                  credOptions.user.id = b64decode(credOptions.user.id);
                  credOptions.challenge = b64decode(credOptions.challenge);

                  if (credOptions.excludeCredentials) {
                    for (
                      let i = 0, size = credOptions.excludeCredentials.length;
                      i < size;
                      i++
                    ) {
                      credOptions.excludeCredentials[i].id = b64decode(
                        credOptions.excludeCredentials[i].id
                      );
                    }
                  }

                  credOptions.user.displayName = values.keyName;
                  credOptions.user.name = values.keyName;

                  //----------create credentials using available authenticator
                  const cred = await navigator.credentials
                    .create({
                      publicKey: credOptions,
                    })
                    .catch(function (error) {
                      console.log(error);
                      throw error;
                    });
                  //console.log("cred", cred.response.getTransports());
                  // parse credentials response to extract id and public-key, this is the information needed to register the user in Cognito
                  const credential: {
                    id?: any;
                    rawId?: any;
                    type?: any;
                    challenge?: any;
                    response?: any;
                    transports?: any;
                  } = {};
                  credential.id = cred && cred.id ? cred.id : null;
                  credential.rawId = b64encode(
                    cred && cred.rawId ? cred.rawId : null
                  );
                  credential.type = cred && cred.type ? cred.type : null;
                  credential.challenge = challenge;

                  if (cred && cred.response) {
                    const clientDataJSON = b64encode(
                      cred.response.clientDataJSON
                    );
                    const attestationObject = b64encode(
                      cred.response.attestationObject
                    );
                    credential.response = {
                      clientDataJSON,
                      attestationObject,
                    };

                    credential.transports = JSON.stringify(
                      cred.response.getTransports()
                    );
                  }
                  endData = { ...endData, ...credential };

                  const parseResult = await fetchNewSecretCredentialParseAdd(
                    endData
                  );

                  const { status, redirect } = parseResult;

                  if (redirect) {
                    dispatch({
                      type: types.logout,
                    });
                    history.push("/auth/login");
                  } else if (status === "Success") {
                    setmultipleSelect([]);
                    resetForm();
                    addNotify("Security key added!", "success");
                  } else {
                    let customMessage =
                      "There was an error adding your security key.";

                    if (message) {
                      customMessage = message;
                    }

                    addNotify(customMessage, "danger");
                  }
                } else {
                  let customMessage =
                    "There was an error creating the credentials.";

                  if (message) {
                    customMessage = message;
                  }

                  addNotify(customMessage, "danger");
                }

                setSubmitting(false);
              } catch (error: any) {
                console.log(error);
                addNotify(error.message, "danger");
              }
            }}
          >
            {({ isSubmitting, resetForm }) => (
              <Form className="form-formik">
                <Row>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <Row>
                      <Col lg="6" md="6" sm="12" xs="12">
                        <Label>Key name <small>(visible for the recipient too)</small></Label>
                        <FormGroup>
                          <Field
                            type="text"
                            name="keyName"
                            disabled={isSubmitting}
                          />
                        </FormGroup>
                        <ErrorMessage
                          name="keyName"
                          component={CustomErrorField}
                        />
                      </Col>
                      <Col lg="6" md="6" sm="12" xs="12">
                        <FormGroup>
                          <div className="box-label">
                            {labelOptions.length <= 1 ? (
                              <Label>
                                You don't have a label yet.{" "}
                                <b className={"cursor-p"} onClick={addNewLabel}>
                                  Create one
                                </b>
                              </Label>
                            ) : (
                              <div>
                                <Label className="">Label</Label>
                                <FontAwesomeIcon
                                  icon={faCog}
                                  className="left5 cursor-p"
                                  onClick={addNewLabel}
                                />
                              </div>
                            )}
                          </div>
                          <CustomModalLabel
                            isOpen={modalClassic}
                            toggle={addNewLabel}
                          />
                          <Select
                            className={`react-select ${
                              multipleSelect[0]
                                ? multipleSelect[0].color
                                : "info"
                            }`}
                            classNamePrefix="react-select"
                            placeholder="Choose label"
                            name="multipleSelect"
                            closeMenuOnSelect
                            isMulti
                            value={multipleSelect}
                            onChange={(value) => setmultipleSelect(value)}
                            options={labelOptions}
                            isDisabled={
                              (labelOptions.length <= 1 && !multipleSelect) ||
                              isSubmitting
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="btn-fill btn-sm"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {!isSubmitting ? "Add key" : "PLEASE WAIT..."}
                    </Button>
                    <Button
                      className="btn-fill btn-sm"
                      color="danger"
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => {
                        setmultipleSelect([]);
                        resetForm();
                        setMode("");
                      }}
                    >
                      Cancel
                    </Button>
                    {!isSubmitting ? (
                      <></>
                    ) : (
                      <div className="mt-1">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="spinner"
                        ></FontAwesomeIcon>{" "}
                        This operation may take few seconds...
                      </div>
                    )}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default NewSecurityKeyLocal;
