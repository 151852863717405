import React from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import welcome from "../../assets/img/welcome-sharepass.png";

export const CustomModalWelcome = ({ isOpen, toggle, toggleRedirect }: any) => {
  return (
    <Modal
      className="modal-welcome"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <div className="modal-header justify-content-center">
        <h2 className="mb-1">Welcome to SharePass!</h2>
      </div>

      <ModalBody className="text-center ">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <img src={welcome} />
              </CardBody>
            </Card>
            <h4>
              We are glad you have chosen us to strengthen the security of your
              communication channels.
              <br />
              Would you like to SharePass your first secret?
            </h4>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-danger btn-welcome pull-left"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <i className="fa fa-sign-out-alt"></i> Later
        </button>
        <button
          className="btn btn-primary btn-welcome pull-right"
          data-dismiss="modal"
          type="button"
          onClick={toggleRedirect}
        >
          <i className="fa fa-plus-circle"></i> New secret
        </button>
      </ModalFooter>
    </Modal>
  );
};
