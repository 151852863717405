import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";

export const LockIconTooltip = () => {
  return (
    <>
      <FontAwesomeIcon
        id="lockIcon"
        icon={faLock}
        color="#ffa538"
        width="40px"
      />
      <UncontrolledTooltip delay={0} target="lockIcon" placement="right">
        Content being sent within highlighted inputs will be protected by
        end-to-end encryption.
      </UncontrolledTooltip>
    </>
  );
};
