import { createContext } from "react";
interface ContextState {
  profile: any;
  dispatch2: any;
}

export const DataContext = createContext<ContextState>({
  profile: {},
  dispatch2: () => {},
});
