/*eslint-disable*/
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router";
import { useFilters, useSortBy, useTable } from "react-table";
// reactstrap components
import { FormGroup, Input } from "reactstrap";
import listBGDark from "../../assets/img/list-bg-dark.png";
import listBGLight from "../../assets/img/list-bg-light.png";

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <FormGroup>
      <Input
        type="email"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function ReactTemplateTable({ update, columns, data, isLoading, admin }) {
  const history = useHistory();
  const [numberOfRows, setNumberOfRows] = React.useState(10);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  let userDetails = localStorage.getItem("user");

  let lightTheme = JSON.parse(userDetails).lightTheme;

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useSortBy
  );

  const [isScrolled, setIsScrolled] = useState(false);
  const onScroll = (e) => {
    const { path } = e;
    setIsScrolled(path[0].scrollTop > 0);
  };

  const goToNewTemplate = () => {
    if(admin){
      history.push("/adminportal/template/new-template");
    }else{
      history.push("/admin/template/new-template");
    }
  };

  return (
    <>
      <div className="ReactTable -striped -highlight ">
        <table {...getTableProps()} className="rt-table">
          <thead className="rt-thead -header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames("rt-th rt-resizable-header", {
                      "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                      "-sort-asc": column.isSorted && !column.isSortedDesc,
                      "-sort-desc": column.isSorted && column.isSortedDesc,
                    })}
                  >
                    <div className="rt-resizable-header-content">
                      {column.render("Header")}
                    </div>
                    {/* Render the columns filter UI */}
                    <div>
                      {headerGroup.headers.length - 1 === key
                        ? null
                        : column.canFilter
                        ? column.render("Filter")
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <InfiniteScroll
            dataLength={rows.length}
            hasMore={true}
            scrollableTarget="scrollable-template-div"
            initialScrollY={20}
            onScroll={onScroll}
          >
            {isScrolled && <div className="shadow-top"></div>}
            <div
              id="scrollable-template-div"
              className={`content-scroll ${isLoading ? "disabled" : ""}`}
            >
              <tbody {...getTableBodyProps()} className="rt-tbody">
                {rows.length ? (
                  rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="rt-tr -even">
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={`rt-td ${isLoading ? "disabled" : ""}`}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : isLoading ? (
                  <tr>
                    <td style={{ width: "50vw" }} className="text-center">
                      <img src={lightTheme ? listBGLight : listBGDark} />
                    </td>
                    <td style={{ width: "50vw" }} className="text-center">
                      <img src={lightTheme ? listBGLight : listBGDark} />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      style={{ width: "100vw" }}
                      className="text-center"
                      colSpan={2}
                    >
                      <h4 className="mt-5">
                        You don't have any template. Create your first one{" "}
                        <span
                          className="u-link-cursor"
                          onClick={goToNewTemplate}
                        >
                          here
                        </span>
                        !
                      </h4>
                    </td>
                  </tr>
                )}
              </tbody>
            </div>
            {isScrolled && <div className="shadow-bottom"></div>}
          </InfiniteScroll>
        </table>
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default ReactTemplateTable;
