import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";

const SSOButton = ({
  id,
  handleSSO,
  icon,
  color,
  text,
  loading,
  iconImg,
  className,
}: {
  id: string;
  handleSSO: any;
  icon?: any;
  color: string;
  text: string;
  loading: boolean;
  iconImg?: any;
  className: string;
}) => {
  return (
    <>
      <Button
        id={id}
        onClick={handleSSO}
        className={`mb-3 btn-block ${className}  ${loading ? "disabled" : ""} `}
        color={color}
        type="button"
      >
        {icon ? (
          <FontAwesomeIcon icon={icon} color="#fff" />
        ) : iconImg ? (
          <img className="wwpass-icon" alt="..." src={iconImg} />
        ) : (
          <></>
        )}{" "}
        {"  "}
        {text}
      </Button>
    </>
  );
};

export default SSOButton;
