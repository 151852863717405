import { ErrorMessage, Field } from "formik";
import React, {useState} from "react";
import { FormGroup, Label } from "reactstrap";
import { CustomErrorField } from "./CustomErrorField";
import {LockIconTooltip} from "../components/LockIconTooltip";
import Dropzone from 'react-dropzone';


export const InputFile = ({ setFileData, errors, setErrors, allowedFileSize } : any) => {
  const initialFileHolder = <div>Drag 'n' drop some file here, or click to select.</div>;
  const [holderContent, setHolderContent] = useState(initialFileHolder);

  return (
    <>
      
      <div>
        <Label>File <small>(Max {allowedFileSize? allowedFileSize : "10 MB"})</small>
          <LockIconTooltip /></Label>
        
          <Dropzone multiple={false} maxSize={10000000} onDrop={(acceptedFiles, fileRejections) => {
            
            /**
             * As we are accepting one single file this is rather valid or not
             */
            if(fileRejections.length == 0){
              setHolderContent(<div>{acceptedFiles[0].name}</div>);
              setFileData(acceptedFiles[0]);
              setErrors("");
            }else{
              setFileData(new File([],""));
              setHolderContent(initialFileHolder);
              fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                  if (err.code === "file-too-large") {
                    setErrors("File is larger than 10 MB");
                  }
        
                  if (err.code === "file-invalid-type") {
                    setErrors(`${err.message}`);
                  }
                });
              });
            }
            
          }}>
            {({getRootProps, getInputProps}) => (
              <section>
                <div className="file-secret-spot" {...getRootProps()}>
                  <input name="file" {...getInputProps()} />
                  {holderContent}

                  <p style={{ color: "red", padding: 5, margin: 0, fontSize: 14 }}>
                    {errors}
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
      </div>
    </>
  );
};