import { faEye, faInfoCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Label,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import useAPIError from "../../commons/hooks/useAPIError";
import { isMobileTablet } from "../../utils/secret";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { decryptDataRSA, decryptKeyRSA } from "../../utils/crypto";

import { fetchSecretDetail } from "../../services/services";

import CredentialsTemplate from "../../views/components/EnvelopeTemplates/CredentialsTemplate";
import CustomTemplate from "../../views/components/EnvelopeTemplates/CustomTemplate";
import FileTemplate from "../../views/components/EnvelopeTemplates/FileTemplate";
import MessageTemplate from "../../views/components/EnvelopeTemplates/MessageTemplate";
import PasswordTemplate from "../../views/components/EnvelopeTemplates/PasswordTemplate";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";

export const EnvelopeReceive = () => {
  const history = useHistory();

  // Notify
  const { addNotify } = useAPIError();

  const [envelopeId, setEnvelopeId] = useState("");
  const [envelopeType, setEnvelopeType] = useState("");

  const [trustDevice, setTrustDevice] = useState( sessionStorage.getItem('masterkey')?  true : false);

  const [isLoading, setIsLoading] = useState(false);

  const [copy, setCopy] = useState(false);
  const setCopied = () => {
    addNotify("Link copied!", "success");
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1200);
  };

  const [decryptedEnvelopeContent, setDecryptedEnvelopeContent] = useState("");
  const [decryptedSymmetricKey, setDecryptedSymmetricKey] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {    
    const state = history.location.state;
    
    if (!state || !state.id) {
      history.push("/admin/dashboard");
    } else {
      setEnvelopeId(state.id);
    }
  }, [history]);

  const getSecretDetails = async function (){
    try{

      const masterKeyFinal = sessionStorage.getItem('masterkey')?  sessionStorage.getItem('masterkey') : masterKey;

      if(masterKeyFinal && masterKeyFinal.length != 32){
        setHasError(true);
        return false;
      }

      setIsLoading(true);
      let envelopeData = await fetchSecretDetail(envelopeId, true);
      
      if(envelopeData.details){

        setEnvelopeType(envelopeData.details.type);

        if(!processing){

          let encryptedData = envelopeData.details.secret;
          if(envelopeData.details.type == 'file'){
            let symmetricKey = await decryptKeyRSA(masterKeyFinal, envelopeData.details.decKey);
            setDecryptedSymmetricKey(symmetricKey);
          }

          let decryptedData;
          switch(envelopeData.details.decKey.alg){
            case "rsa":
              if(envelopeData.details.type != 'file'){
                decryptedData = await decryptDataRSA(masterKeyFinal, encryptedData, envelopeData.details.decKey);
                setDecryptedEnvelopeContent(decryptedData);
              }else{
                setDecryptedEnvelopeContent(encryptedData);
              }
              
              break;
            case "crystalsKyber":
              //decryptedData = await generateCRYSTALSKyberKeyPair(masterKey);              
              break;
          };

          setMasterKey("");

          if(trustDevice){
            sessionStorage.setItem('masterkey', masterKeyFinal);
          }else{
            sessionStorage.removeItem('masterkey');
          }
        }  
        
        setIsLoading(false);
      }else{
        throw "Error opening your envelope";
      }
      

    }catch(Error){
      setMasterKey("");
      setIsLoading(false);
      setErrorMessage("There was an error while opening your envelope.");
    }
  }

  const goToNewEnvelope = () => {
    history.push("/admin/envelope/add");
  };

  const copyRichText = (text: String, state: any) => {
    const { revealMessage } = state.data;

    if(isMobileTablet()){
      navigator.clipboard.writeText(text.toString());
    } else {
      const listener = function (ev: any) {
        ev.preventDefault();
        ev.clipboardData.setData(
          "text/html",
          '<a href="' + text + '" target="_blank">' + revealMessage + "</a>"
        );
        ev.clipboardData.setData("text/plain", text);
      };
      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
    }
    
  };

  const handleTrustDevice = () => {
    if(trustDevice){
      sessionStorage.removeItem('masterkey');
      addNotify("Master key removed successfully!", "success");
    }
    setTrustDevice(!trustDevice)
  };

  const [masterKeyType,setMasterKeyType] = useState("password");
  const [masterKey,setMasterKey] = useState("");
  const [processing, setProcessing] = useState(false);
  const [hasError, setHasError] = useState(false);

  return (
    <>
      {errorMessage && <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Error"
        onConfirm={() => {
          setErrorMessage("");
        }}
        confirmBtnBsStyle="primary"
        confirmBtnText="Ok"
        btnSize=""
      >
        {errorMessage}
      </ReactBSAlert>}
    
      <div className="content">
        { !decryptedEnvelopeContent ? 
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Your envelope is ready to be opened!
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row className="form-formik">
                    {sessionStorage.getItem('masterkey') && trustDevice ?
                    <Col md="4" sm="6" xs="12">
                      <p>
                        Previously used master key will be reused.
                      </p>
                    </Col>
                    :
                    <Col md="4" sm="6" xs="12">
                      <p>
                        You need to enter the master key of <strong>32 characters</strong> to decrypt the envelope content.
                      </p>
                        
                      <FormGroup className={ hasError?"has-danger":""}>
                        <label>Master key</label>
                        
                        <div className="input-group input-group-masterkey">
                          <div className="input-group-prepend">
                            <span 
                              className="input-group-text cursor-p"
                              onClick={() => setMasterKeyType(masterKeyType == "password" ? "text" : "password")}
                            >
                              <FontAwesomeIcon
                                icon={masterKeyType == "password" ? faEye : faEyeSlash}
                                color="#0d6efd"
                              />
                            </span>
                          </div>
                          <input 
                            id="masterKey"
                            name="masterKey"
                            type={masterKeyType}
                            placeholder="Master key"
                            onChange={(e) => {
                              setMasterKey(e.target.value);
                              if(e.target.value.length == 32){
                                setHasError(false);
                              }
                            }}
                            className="form-control"
                            value={masterKey}
                            disabled={isLoading}
                          />
                        </div>
                        <div style={{height:"40px"}}>
                          {hasError && <div className="text-danger  mt-2" role="alert">
                            <span>The master key needs to be 32 characters.</span>
                          </div>}
                          {isLoading && <div className="mt-2">
                            <FontAwesomeIcon
                              icon={faSpinner}
                              className="spinner"
                            ></FontAwesomeIcon>{" "}
                            This operation may take few seconds...
                          </div>}
                        </div>

                      </FormGroup>
                    </Col>
                    }
                  </Row>
                  <Row>
                    <Col className="text-center" md="4" sm="6" xs="12">
                      <Button
                        className={`btn-round ${
                          isLoading ? "disabled" : ""
                        }`}
                        color="primary"
                        size="lg"
                        type="button"
                        onClick={getSecretDetails}
                      >
                        {isLoading
                          ? "Please wait..."
                          : "Open"}
                      </Button>

                      {false && <FormGroup check>
                        <Label check>
                          <Input onClick={handleTrustDevice} checked={trustDevice} type="checkbox" />
                          <span className="form-check-sign" />
                          Trust this device

                          <FontAwesomeIcon
                            id="trustDeviceEnvelope"
                            icon={faInfoCircle}
                            color="#0d6efd"
                            width="40px"
                            className="left4"
                          />
                        </Label>
                        <UncontrolledTooltip
                          delay={0}
                          target="trustDeviceEnvelope"
                          placement="bottom"
                        >
                          The master key will be remembered until your browser is closed.
                        </UncontrolledTooltip>
                      </FormGroup>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        :
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    You have decrypted the envelope conent correctly!
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row className="form-formik">
                    <Col md="4" sm="6" xs="12">
                      {envelopeType === "password" && (
                        <PasswordTemplate secret={decryptedEnvelopeContent} />
                      )}
                      {(envelopeType === "message" || envelopeType === "QR") && (
                        <MessageTemplate secret={decryptedEnvelopeContent} />
                      )}
                      {envelopeType === "credentials" && (
                        <CredentialsTemplate data={decryptedEnvelopeContent} />
                      )}
                      {envelopeType === "custom" && <CustomTemplate secret={decryptedEnvelopeContent} />}
                     
                      {envelopeType === "file" && (
                        <FileTemplate secretPassword={decryptedSymmetricKey} data={decryptedEnvelopeContent} />
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </div>
    </>
  );
};
