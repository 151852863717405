import {
  faCheckCircle,
  faEye,
  faFilterCircleXmark,
  faRedoAlt,
  faSearch,
  faTag
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  FormGroup,
  Input,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import useAPIError from "../../../commons/hooks/useAPIError";
import UserReactTables from "../../../components/ReactTable/UsersReactTable";
import { usersColumns, initiaFilterlValues } from "../../../constants/reactTable";
import { Context } from "../../../context/auth/Context";
import { types } from "../../../context/types";
import {
  fetchSecretDelete,
  fetchSecrets,
  fetchUsers,
  fetchGlobalCounters,
  fetchUserStatusUpdate
} from "../../../services/services";
import { filterBody } from "../../../utils/filter";
import { SecretDetailModal } from "../../components/SecretDetailModal";
import moment from "moment";
import { CustomWidgetInfo } from "../../../components/Customs/CustomWidgetInfo";


export const UsersList = () => {
  // Notify
  const { addNotify } = useAPIError();

  const [data, setData] = useState([]);
  const [emailFilter, setEmailFilter] = useState("");
  const [flagSearch, setFlagSearch] = useState(true);
  const [lastItem, setLastItem] = useState({
    id: "",
    creation: 0
  });


  const history = useHistory();

  // Get name from context
  const { dispatch } = useContext(Context);

  const getUsers = async (body = {}, previous = false) => {
    setFlagSearch(false);
    setIsLoading(true);

    const { details, redirect, lastEvaluatedId } = await fetchUsers(body);


    if (redirect) {
      dispatch({
        type: types.logout,
      });
      history.push("/auth/login");
    } else if (details) {
      const { users } = details;
      
      setLastItem(lastEvaluatedId);

      const auxData = parseDataTable(users);

      setData((prevData) => {
        if (previous) return [...prevData, ...auxData];
        else return auxData;
      });
    }

    setIsLoading(false);
  };

  const parseDataTable = (dataTable: any) => {
    return dataTable.map((prop: any, key: number) => {
      return {
        id: prop.userId,
        email: prop.email,
        created: (
          <div className="date-column">
            <Label id={`date-${prop.creation.toString()}`}>
              {moment(prop.creation * 1000).fromNow().toString()}
            </Label>
            <UncontrolledTooltip
              delay={0}
              target={`date-${(prop.creation).toString()}`}
              placement="right"
            >
              {moment(prop.creation * 1000).format("ddd D MMM YYYY HH:mm:ss").toString()}
            </UncontrolledTooltip>
          </div>
        ),
        lastActivity: (prop.lastActivity ?
          <div className="date-column">
            <Label id={`date-m-${prop.lastActivity.toString()}`}>
              {moment(prop.lastActivity * 1000).fromNow().toString()}
            </Label>
            <UncontrolledTooltip
              delay={0}
              target={`date-m-${prop.lastActivity.toString()}`}
              placement="right"
            >
              {moment(prop.lastActivity * 1000).format("ddd D MMM YYYY HH:mm:ss").toString()}
            </UncontrolledTooltip>
          </div> : "-"
        ),
        name: <div className="users-list-block">
          <div className="d-inline-block">
            <Label>{prop.name} {prop.lastname}</Label>
            <div className="users-list-email"><small>{prop.email}</small></div>
          </div>
        </div>,
        lastname: prop.lastname,
        status: (
          <div
            id={`switch-${prop.userId})`}
            onClick={() => {
                setUserId(prop.userId);
            }}
            checked={prop.enabled}
            className={`btn-lock-unlock`}
          >
            {!prop.enabled ? (
              <>
                <i id={`user-status-${prop.userId}`} className="fa fa-user text-danger"></i>
                <UncontrolledTooltip
                  delay={0}
                  target={`user-status-${prop.userId}`}
                  placement="right"
                >
                  Disabled
                </UncontrolledTooltip>
              </>
            ) : (
              <>
                <i id={`user-status-${prop.userId}`} className="fa fa-user text-success"></i>
                <UncontrolledTooltip
                    delay={0}
                    target={`user-status-${prop.userId}`}
                    placement="right"
                  >
                    Enabled
                  </UncontrolledTooltip>
                </>
            )}
          </div>
        ),
        /*actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              onClick={() => {
                setDetails((prevProps) => ({
                  showModal: true,
                  secret: prop,
                  id: prop.userId,
                }));
              }}
              color="info"
              size="sm"
              className="btn-icon btn-link"
            >
              <FontAwesomeIcon
                icon={faEye}
                color="#1d8cf8"
                width="40px"
                className="font-awesome top5 font-awesome"
              />
            </Button>
          </div>
        ),*/
      };
    });
  };

  /*
   * Show alert before delete a secret
   */
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const warningWithConfirmMessage = (id: string) => {
    setShowAlert(true);
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          setAuxId(id);
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="success"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this secret!
      </ReactBSAlert>
    );
  };

  /*
   * Hide alert when delete a secret
   */
  const hideAlert = () => {
    setShowAlert(false);
    setAlert({});
    setDetails({ secret: {}, id: "", showModal: false });
  };

  /*
   * Delete a secret
   */
  const [auxId, setAuxId] = useState("");
  useEffect(() => {
    // Delete from the database
    const deleteSecret = async () => {
      const body = { userId: auxId };

      const { status, redirect } = await fetchSecretDelete(body);

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        const newData = data.filter((secret: any) => secret.id !== auxId);
        setData(newData);
        setAuxId("");

        setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Deleted!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            Your secret has been deleted.
          </ReactBSAlert>
        );
      }
    };

    const isDeleted = data.find((secret: any) => secret.id === auxId);
    if (auxId !== "" && isDeleted) {
      deleteSecret();
    }
  }, [auxId, data]);

  /*
   * Show modal with details of secret
   */
  const [details, setDetails] = useState({
    secret: {},
    id: "",
    showModal: false,
  });

  useEffect(() => {
    if (flagSearch) getUsers();
  });

  useEffect(() => {
    const currentURL = new URLSearchParams(history.location.search);

    if (currentURL.get("show_details")) {
      const userId = currentURL.get("show_details");

      setDetails({
        showModal: true,
        secret: {},
        id: userId,
      });
    }
  }, []);

  /*
   * Update secret (locked field)
   */
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isLoading) {
      setUserId("");
      return;
    }
    if (userId) {
      /* Update secret and data table */
      const updateUser = async (newStatus: boolean, userId: string) => {
        const body = {
          username: userId,
          enable: newStatus
        };

        const { status, redirect, message } = await fetchUserStatusUpdate(body);

        /* Update data table */
        if (redirect) {
          dispatch({
            type: types.logout,
          });
          history.push("/auth/login");
        } else if (status === "Success") {
          addNotify("The user was successfully updated", "success");
          setData(newData);
          setIsLoading(false);
        }else{
          addNotify(message || "There was an error trying to update the user status", "danger");
          setUserId("");
          setIsLoading(false);
        }
      };

      setIsLoading(true);

      let newData: any = [...data];
      let user: any = data.find((s: any) => {
        return s.id === userId;
      });

      /* Update secret on the database */
      updateUser(!user.status.props.checked, user.id);

      if (user) {
        const userStatus = { ...user.status };
        let props = {
          ...userStatus.props,
          checked: !userStatus.props.checked,
          children: userStatus.props.checked ? (
            <>
              <i id={`user-status-${user.id}`} className="fa fa-user text-danger"></i>
              <UncontrolledTooltip
                delay={0}
                target={`user-status-${user.id}`}
                placement="right"
              >
                Disabled
              </UncontrolledTooltip>
            </>
          ) : (
            <>
              <i id={`user-status-${user.id}`} className="fa fa-user text-success"></i>
              <UncontrolledTooltip
                  delay={0}
                  target={`user-status-${user.id}`}
                  placement="right"
                >
                  Enabled
                </UncontrolledTooltip>
              </>
          ),
        };
        const finalUserStatus = { ...userStatus, props };



        const newUser = {
          ...user,
          status: finalUserStatus,
        };

        const i = data.findIndex((item: any) => item.id === userId);
        newData[i] = newUser;

        
        setUserId("");
      }
    }
  }, [userId, data, isLoading, addNotify]);

  // TODO
  // Try to put scrollbar on top by useRef
  const reloadList = async () => {
    if (isLoading) return;
    setLastItem({
      id: "",
      creation: 0
    });

    let body:any = {};

    if (emailFilter) {
      body["email"] = emailFilter;
    }

    await getUsers(body);
  };

  const fetchMoreData = async () => {
    setIsLoading(true);

    let body:any = {};

    if (emailFilter) {
      body["email"] = emailFilter;
    }

    if (lastItem && lastItem.id) {
      body["exclusiveStartId"] = lastItem;
    }

    await getUsers(body, true);
  };

  const [isFilterActive, setIsFilterActive] = useState(false);

  // Remove all filters
  const removeFilter = async () => {

    if (isLoading) return;
    setLastItem({
      id: "",
      creation: 0
    });

    setEmailFilter("");

    if (isFilterActive) {
      setIsFilterActive(false);
      await getUsers();
    }
  };

  const dataFinal = React.useMemo(() => data, [data]);
  const [lastData, setLastData] = useState([]);
  useEffect(() => {
    setLastData(dataFinal);
  });

  const [dataWidgets, setDataWidgets] = useState();

  
  useEffect(() => {
    
    const getGlobalCounters = async () => {
      
      const { details, redirect } = await fetchGlobalCounters();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (details) {
        setDataWidgets(details);
      }
      
    };

    if (!dataWidgets) {
      getGlobalCounters();
    }
  }, [dataWidgets]);

  return (
    <>
      <div className="content react-table">
        {showAlert && alert}
        {details.showModal && (
          <SecretDetailModal
            detail={details}
            setDetails={setDetails}
            setUserId={setUserId}
            isLoading={isLoading}
            setShowAlert={setShowAlert}
            setAlert={setAlert}
            hideAlert={hideAlert}
          />
        )}
        <Row>
          <CustomWidgetInfo
            icon="tim-icons icon-single-02"
            title="Users"
            value={(dataWidgets && dataWidgets.totalUsers) || "--"}
            subTitle="Total users"
            state="success"
            lg="4"
            md="4"
          />
          <CustomWidgetInfo
            title="Secrets"
            value={
              dataWidgets &&
              dataWidgets.totalSecretsSharePassed
                ? dataWidgets.totalSecretsSharePassed
                : "--"
            }
            subTitle="Total secrets SharePassed"
            state="info"
            lg="4"
            md="4"
          />
          <CustomWidgetInfo
            icon="tim-icons icon-send"
            title="Data"
            value={(dataWidgets && dataWidgets.totalDataSharePassed) || "--"}
            subTitle="Total data SharePassed"
            state="danger"
            lg="4"
            md="4"
          />
        </Row>
        <Row className="mt-2">
          <Col xs={12} md={12}>
            <Card className="m0">
              <CardBody className="overflow-x-auto">
                <div className="flex">
                  <Col xs={4} md={4} className="p0">
                  <FormGroup>
                    
                    <Input
                      id="emailFilter"
                      name="emailFilter"
                      type="text"
                      placeholder="Email"
                      onChange={(e) => {
                        setIsFilterActive(true);
                        setEmailFilter(e.target.value);
                      }}
                      className="form-control"
                      value={emailFilter}
                      onKeyPress={(e) => {
                        if(e.key === 'Enter' && e.target.value.length > 0){
                          reloadList();
                        }
                      }}
                    />
                  </FormGroup>
                  </Col>
                  <Col xs={8} md={8}>
                    <Button
                      className="btn-fill remove-filter"
                      color="primary"
                      disabled={isLoading || !emailFilter}
                      id="idSearch"
                      onClick={reloadList}
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                      />
                    </Button>
                    <UncontrolledTooltip
                      delay={0}
                      target="idSearch"
                      placement="right"
                    >
                      Search
                    </UncontrolledTooltip>
                    <Button
                      className="btn-fill remove-filter"
                      color="primary"
                      disabled={isLoading || !emailFilter}
                      id="idTimes"
                      onClick={removeFilter}
                    >
                      <FontAwesomeIcon
                        icon={faFilterCircleXmark}
                        color={isFilterActive ? "#bfbfc5" : ""}
                      />
                    </Button>
                    <UncontrolledTooltip
                      delay={0}
                      target="idTimes"
                      placement="right"
                    >
                      Clear filter
                    </UncontrolledTooltip>
                    <Button
                      className="btn-fill m0"
                      color="primary"
                      disabled={isLoading}
                      onClick={reloadList}
                      id="idRefreshBtn"
                    >
                      <FontAwesomeIcon icon={faRedoAlt} color="#fff" />
                    </Button>
                    <UncontrolledTooltip
                      delay={0}
                      target="idRefreshBtn"
                      placement="right"
                    >
                      Refresh
                    </UncontrolledTooltip>
                  </Col>
                </div>
                <hr />
                <UserReactTables
                  columns={usersColumns}
                  data={lastData}
                  isLoading={isLoading}
                  lastItem={lastItem}
                  update={fetchMoreData}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
