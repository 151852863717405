import { ErrorMessage, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, Label } from "reactstrap";
import useAPIError from "../../commons/hooks/useAPIError";
import { Context } from "../../context/auth/Context";
import { types } from "../../context/types";
import { fetchDeleteLabel, fetchLabels } from "../../services/settings";
import { fetchAdminDeleteLabel, fetchAdminLabels } from "../../services/adminServices";
import { parseLabels } from "../../utils/settings";
import { CustomErrorField } from "./CustomErrorField";
import { CustomSelectFormik } from "./CustomSelectFormik";

export const RemoveLabelForm = ({ toggle, admin }: any) => {
  // Notify
  const { addNotify } = useAPIError();

  // Labels
  const [labels, setLabels] = useState([]);

  const history = useHistory();

  // Get name from context
  const { dispatch } = useContext(Context);

  useEffect(() => {
    const getLabels = async () => {
      const { status, details, redirect } = admin? await fetchAdminLabels() : await fetchLabels();
      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        if (details.length > 0) {
          const auxLabels = parseLabels(details);
          setLabels(auxLabels);
        }
      } else {
        addNotify("An error was ocurred when trying to load labels", "danger");
      }
    };

    if (labels.length === 0) getLabels();
  }, [labels, addNotify]);

  return (
    <Formik
      initialValues={{ codeName: "" }}
      validate={(values) => {
        const errors: any = {};

        if (!values.codeName) {
          errors.codeName = "Required";
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {

        const body = values;

        const { 
          status, 
          details, 
          message, 
          redirect 
        } = admin? await fetchAdminDeleteLabel({id: body.codeName}) : await fetchDeleteLabel(body);

        resetForm();

        if (redirect) {
          dispatch({
            type: types.logout,
          });
          history.push("/auth/login");
        } else if (status === "Success") {
          addNotify("The label was successfully deleted", "success");

          setLabels((prevLabels) =>
            prevLabels.filter((label: any) => details && details.codeName && label.label !== details.codeName || label.value !== body.codeName)
          );
        } else {
          addNotify(message, "danger");
        }

        // Close modal
        toggle();
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="form-formik">
          <div className="new-label">
            <Label>Label</Label>
            <FormGroup>
              <CustomSelectFormik
                placeholder="Select label"
                options={labels}
                value={values.codeName}
                onChange={(value: any) => {
                  setFieldValue("codeName", admin? value.id : value.codeName)
                }}
              />
              <ErrorMessage name="codeName" component={CustomErrorField} />
            </FormGroup>
          </div>
          <hr />
          <button
            type="submit"
            disabled={isSubmitting || !values.codeName}
            className="btn btn-danger"
          >
            Delete
          </button>
        </Form>
      )}
    </Formik>
  );
};
