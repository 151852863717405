import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";

export const CustomIconPaymentSoon = ({ icon }: any) => {
  return (
    <>
      <FontAwesomeIcon className="payment-icon" icon={icon} id="comingSon" />
      <UncontrolledTooltip delay={0} target="comingSon" placement="bottom">
        Coming soon
      </UncontrolledTooltip>
    </>
  );
};
