import { ErrorMessage, Field } from "formik";
import React from "react";
import { FormGroup, Label } from "reactstrap";
import { LockIconTooltip } from "../components/LockIconTooltip";
import { CustomErrorField } from "./CustomErrorField";

export const InputCredential = () => {
  return (
    <div>
      <Label>
        Username
        <LockIconTooltip />
      </Label>
      <FormGroup>
        <Field className="eee-field" type="text" name="credentialEmail" />
      </FormGroup>
      <ErrorMessage name="credentialEmail" component={CustomErrorField} />
      <Label>
        Password
        <LockIconTooltip />
      </Label>
      <FormGroup>
        <Field
          className="eee-field"
          type="password"
          name="credentialPassword"
        />
      </FormGroup>
      <ErrorMessage name="credentialPassword" component={CustomErrorField} />
      <Label>
        Hostname
        <LockIconTooltip />
      </Label>
      <FormGroup>
        <Field
          className="eee-field"
          type="text"
          name="credentialHostname"
          placeholder="Optional"
        />
      </FormGroup>
      <ErrorMessage name="credentialHostname" component={CustomErrorField} />
      <Label>
        2FA Secret
        <LockIconTooltip />
      </Label>
      <FormGroup>
        <Field
          className="eee-field"
          type="text"
          name="credential2Fa"
          placeholder="Optional"
        />
      </FormGroup>
      <ErrorMessage name="credential2Fa" component={CustomErrorField} />
    </div>
  );
};
