import { faPlus, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import useAPIError from "../../../commons/hooks/useAPIError";
import { securityKeyColumns } from "../../../constants/reactTable";
import { Context } from "../../../context/auth/Context";
import { types } from "../../../context/types";
import {
  fetchAdminDeleteSecretCredential,
  fetchAdminSecretCredentials,
} from "../../../services/adminServices";
import ReactSecurityKeyTable from "../../components/ReactSecurityKeyTable";

export const SecurityKeyList = () => {
  const history = useHistory();

  // Notify
  const { addNotify } = useAPIError();

  // Get name from context
  const {
    user: { token, refreshToken, expiresIn, userId },
    dispatch,
  } = useContext(Context);

  const [securityKeys, setSecurityKeys] = useState([]);
  const [securityKeysAux, setSecurityKeysAux] = useState([]);
  const [securityKeyId, setSecurityKeyId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Notify
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  // For delete template
  const [auxId, setAuxId] = useState("");

  const parseLabelContent = (label: any) => {
    return label && label.codeName ? (
      <div>
        <FontAwesomeIcon
          id="PinTooltip"
          className="right3"
          icon={faTag}
          color={label.color}
        />{" "}
        {label.codeName}
      </div>
    ) : (
      "-"
    );
  };

  const parseCreateDate = (date: number) => {
    let dateFromNow = moment(date).fromNow().toString();
    let dateToShow = moment(date).format("ddd D MMM YYYY HH:mm:ss").toString();
    return (
      <div className="date-column">
        <Label id={`date-${date.toString()}`}>{dateFromNow}</Label>
        <UncontrolledTooltip
          delay={0}
          target={`date-${date.toString()}`}
          placement="right"
        >
          {dateToShow}
        </UncontrolledTooltip>
      </div>
    );
  };

  useEffect(() => {
    const getSecurityKeys = async () => {
      setIsLoading(true);
      const { details, status, redirect } = await fetchAdminSecretCredentials();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        setIsLoading(false);
        /*
         * Show alert before delete a secret
         */

        const warningWithConfirmMessage = (id: string, name: string) => {
          setShowAlert(true);
          setAlert(
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Are you sure?"
              onConfirm={() => {
                setAuxId(id);
              }}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="success"
              confirmBtnText="Yes, delete it!"
              cancelBtnText="Cancel"
              showCancel
              btnSize=""
            >
              You are about to delete <i><b>{name}</b></i>. This action cannot be undone! <br />
              Note: This key DOES NOT get deleted from existings secrets.
            </ReactBSAlert>
          );
        };

        /*
         * Here we parse data for the table
         * Params: array of securityKeys from backend
         */
        const parsedDataSecurityKeyTable = (datatable: any) => {
          return datatable.map((prop: any, key: number) => {
            return {
              id: prop.id,
              name: prop.name,
              lastUse:
                prop.lastUse === 0 || prop.lastUse === "0"
                  ? "Never used"
                  : parseCreateDate(prop.lastUse),
              uses: prop.uses,
              createdAt: parseCreateDate(prop.createdAt),
              label: parseLabelContent(prop.label),
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a like kind of action */}

                  <Button
                    onClick={() => {
                      warningWithConfirmMessage(prop.id, prop.name);
                    }}
                    color="danger"
                    size="sm"
                    className={"btn-icon btn-link like"}
                  >
                    <i className="tim-icons icon-trash-simple danger" />
                  </Button>
                </div>
              ),
            };
          });
        };

        if (details.length > 0) {
          const parsedData = parsedDataSecurityKeyTable(details);
          setSecurityKeys(parsedData);
          setSecurityKeysAux(details);
        }
      }
    };

    if (securityKeys.length === 0) {
      getSecurityKeys();
    }
  }, [securityKeys, token, refreshToken, expiresIn, userId, dispatch, history]);

  useEffect(() => {
    // Delete from the database
    const deleteTemplate = async () => {
      const body = { id: auxId };

      const { status, redirect } = await fetchAdminDeleteSecretCredential(body);

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        const newData = securityKeys.filter(
          (secret: any) => secret.id !== auxId
        );
        setSecurityKeys(newData);
        setAuxId("");

        setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Deleted!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            Your security key has been deleted.
          </ReactBSAlert>
        );
      }
    };

    const isDeleted = securityKeys.find((secret: any) => secret.id === auxId);
    if (auxId !== "" && isDeleted) {
      deleteTemplate();
    }
  }, [auxId, securityKeys, token, refreshToken, expiresIn, userId, dispatch]);

  /*
   * Hide alert when delete a secret
   */
  const hideAlert = () => {
    setShowAlert(false);
    setAlert({});
    //setDetails({ secret: {}, id: "", showModal: false });
  };

  const fetchMoreData = async () => {
    setIsLoading(true);

    console.log("load more data...");
  };

  const goToNewSecurityKey = () => {
    history.push("/adminportal/security-key/new-security-key");
  };

  return (
    <div className="content react-table">
      {showAlert && alert}
      <Row>
        <Col md="12">
          <Card md="6">
            <CardBody>
              <Col className="text-right">
                <Button
                  className="btn-fill"
                  color="primary"
                  onClick={goToNewSecurityKey}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add new
                </Button>
                <hr />
              </Col>
              <Col xs={12} md={12}>
                <ReactSecurityKeyTable
                  data={securityKeys}
                  columns={securityKeyColumns}
                  isLoading={isLoading}
                  update={fetchMoreData}
                  lastItem={"asasa"}
                  admin={true}
                />
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
