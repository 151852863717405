export const getClasses = (stars) => {
  /*
   *gold golor => #f5c451
   */
  let cardClass, classBtn, colorIcon, sizeIcon;

  switch (stars) {
    case 4:
      cardClass = "card-primary";
      classBtn = "primary";
      colorIcon = "#0d6efd";
      sizeIcon = "large";
      break;
    case 3:
      cardClass = "card-success";
      classBtn = "success";
      colorIcon = "#00f2c3";
      sizeIcon = "medium";
      break;
    case 2:
      cardClass = "card-warning";
      classBtn = "warning";
      colorIcon = "#ff8d72";
      sizeIcon = "small";
      break;
    default:
      cardClass = "card-danger";
      classBtn = "danger";
      colorIcon = "#f63b3b";
      sizeIcon = "mini";
      break;
  }

  return { cardClass, classBtn, colorIcon, sizeIcon };
};
