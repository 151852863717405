// core components
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import React, { useContext, useEffect, useRef, useState } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import logo from "../../assets/img/ymtech.png";
import Footer from "../../components/Footer/Footer.js";
import AdminNavbar from "../../../../components/Navbars/AdminNavbar.js";
import Sidebar from "../../../../components/Sidebar/Sidebar.js";
//import { DataContext } from "../../context/data/DataContext";
import { Context } from "../../../../context/auth/Context";
import routes from "../../routes.js";
import { fetchUpdateGeneralSettings } from "../../../../services/services";
import FrontendConfig from "../../../../assets/js/config";

let ps: any;

const AdminDefault = (props: any) => {
  // Context for user
  const { user } = useContext(Context);

  const [opacity, setOpacity] = useState(0);
  const [sidebarOpened, setSidebarOpened] = useState(
    user.sidebarToggle || false
  );

  const mainPanelRef: any = useRef(null);
  const notificationAlertRef: any = useRef(null);
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;

    if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);

  const [isWebApp, setIsWebApp] = useState(true);
  /*useEffect(() => {
    setIsWebApp(window.location.hostname.length <= 25);
  }, []);*/

  useEffect(() => {
    let innerMainPanelRef = mainPanelRef;
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current);
      mainPanelRef.current &&
        mainPanelRef.current.addEventListener("ps-scroll-y", showNavbarButton);
      let tables: any = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", showNavbarButton);
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
        innerMainPanelRef.current &&
          innerMainPanelRef.current.removeEventListener(
            "ps-scroll-y",
            showNavbarButton
          );
      }
      window.removeEventListener("scroll", showNavbarButton);
    };
  }, []);
  const showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      (document.scrollingElement && document.scrollingElement.scrollTop > 50) ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
    ) {
      setOpacity(1);
    } else if (
      document.documentElement.scrollTop <= 50 ||
      (document.scrollingElement &&
        document.scrollingElement.scrollTop <= 50) ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
    ) {
      setOpacity(0);
    }
  };
  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: any) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes: any) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute: any = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const handleMiniClick = () => {
    setToggleConfig();
    document.body.classList.toggle("sidebar-mini");
  };
  const toggleSidebar = () => {
    //setToggleConfig();
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle("nav-open");
  };
  const closeSidebar = () => {
    //setToggleConfig();
    setSidebarOpened(false);
    document.documentElement.classList.remove("nav-open");
  };

  const setToggleConfig = async () => {
    console.log("make request here");

    const { status } = await fetchUpdateGeneralSettings({
      sidebarToggle: !sidebarOpened,
    });

    /*@TODO: Temporary patch */
    let userTemp: any = localStorage.getItem("user");
    if (userTemp) {
      console.log(userTemp);
      userTemp = JSON.parse(userTemp);
      userTemp.sidebarToggle = !sidebarOpened;
      localStorage.setItem("user", JSON.stringify(userTemp));
    }

    /*if (status === "Success") {
      dispatch2({
        type: dataTypes.user,
        payload: {
          ...user,
          sidebarToggle: !sidebarOpened,
        },
      });
    }*/

    //setIsLoading(false);
  };

  return (
    <div className={`${isWebApp ? "wrapper" : "wrapper-extension"}`}>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
        <button
          className="minimize-sidebar btn btn-link btn-just-icon"
          onClick={handleMiniClick}
        >
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      <Sidebar
        {...props}
        routes={routes}
        activeColor="blue"
        logo={{
          outterLink: `https://${window.location.hostname}/admin/dashboard`,
          text: "YMTech",
          imgSrc: logo,
        }}
        closeSidebar={closeSidebar}
      />
      <div className="main-panel" ref={mainPanelRef}>
        <AdminNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(routes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
        />
        <Switch {...props}>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/dashboard" />
        </Switch>
        {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid />
          )
        }
      </div>
    </div>
  );
};

/**
 * Environment override file if exists
 */
 const tryRequire = () => {
  try {
    const env = FrontendConfig.env;
    return require("../../envs/" + env + "/layouts/Admin/Admin");
  } catch (err) {
    return null;
  }
}

 const Admin = tryRequire() ? tryRequire().default : AdminDefault;

export default Admin;
