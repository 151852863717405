import React, { useEffect, useRef } from "react";
import ReactNotificationAlert from "react-notification-alert";
import useAPIError from "../../commons/hooks/useAPIError";

export const APIErrorNotification = () => {
  const { error, removeError } = useAPIError();

  // Notify
  const notificationAlertRef = useRef<any>();
  const notify = (notifyType: string, message: string) => {
    const type = notifyType === "" ? "success" : notifyType;

    const options = {
      place: "tr",
      message,
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };

    notificationAlertRef &&
      notificationAlertRef.current &&
      notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    if (error) {
      const { message, status } = error;
      notify(status, message);
      removeError();
    }
  }, [error, removeError]);

  return <ReactNotificationAlert ref={notificationAlertRef} />;
};
