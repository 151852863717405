import { ErrorMessage, Field } from "formik";
import React from "react";
import { FormGroup, Label, Row, Col } from "reactstrap";
import { CustomErrorField } from "./CustomErrorField";

export const InputChangePassword = () => {
  return (<>
    <Row>
      <Col lg="6" md="6" sm="6" xs="12">
        <Label>Current password</Label>
        <FormGroup>
          <Field type="password" name="password" placeholder="********" />
        </FormGroup>
        <ErrorMessage name="password" component={CustomErrorField} />
      </Col>
    </Row>
    <Row>
    <Col lg="6" md="6" sm="6" xs="12">
      <Label>New password</Label>
      <FormGroup>
        <Field type="password" name="newPassword" />
      </FormGroup>
      <ErrorMessage name="newPassword" component={CustomErrorField} />
    </Col>
    <Col lg="6" md="6" sm="6" xs="12">
      <Label>Confirm</Label>
      <FormGroup>
        <Field type="password" name="confirmNewPassword" />
      </FormGroup>
      <ErrorMessage name="confirmNewPassword" component={CustomErrorField} />
    </Col>
  </Row>
  </>);
};