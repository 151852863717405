import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import Select from "react-select";
import {
  Col,
  CustomInput,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import {
  inputProps,
  optionsEnd,
  optionsStart,
} from "../../constants/inputAvailability";
import { validateDate } from "../../utils/templates";

interface Params {
  config: any;
  setConfig: any;
  qty2Aux: number;
}

export const InputAvailability = ({ config, setConfig, qty2Aux }: Params) => {
  const [timeZone, setTimeZone] = useState("");

  useEffect(() => {
    const currentTimeZone = Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.replace("_", " ");

    setTimeZone(currentTimeZone);
  }, []);

  const [selectedOption, setSelectedOption] = useState({
    value: config.availability.opt,
    label: config.availability.opt,
  });

  const [selectedOption2, setSelectedOption2] = useState({
    value: config.availability.opt2,
    label: config.availability.opt2,
  });

  const [qty, setQty] = useState(
    config.availability && config.availability.qty ? config.availability.qty : 0
  );
  const [qty2, setQty2] = useState(
    config.availability && config.availability.qty2
      ? config.availability.qty2
      : 7
  );

  const [datetime, setDatetime] = useState(
    config.availability && config.availability.datetime
      ? config.availability.datetime
      : undefined
  );

  const [datetime2, setDatetime2] = useState(
    config.availability && config.availability.datetime2
      ? config.availability.datetime2
      : undefined
  );
  const [isSwitched, setIsSwitched] = useState(
    config.availability && config.availability.isSwitched ? true : false
  );

  const [isSwitched2, setIsSwitched2] = useState(
    config.availability && config.availability.isSwitched2 ? true : false
  );

  const [classFlex, setClassFlex] = useState(false);
  const [classFlex2, setClassFlex2] = useState(false);

  useEffect(() => {
    if (
      selectedOption &&
      (selectedOption.value === "Specific date" ||
        selectedOption.value === "Right now")
    )
      setClassFlex(false);
    else if (!classFlex) setClassFlex(true);

    if (selectedOption.value !== "Specific date") {
      setDatetime(undefined);
    }
  }, [selectedOption, classFlex]);

  useEffect(() => {
    if (
      selectedOption2 &&
      (selectedOption2.value === "Specific date" ||
        selectedOption2.value === "Never expire")
    )
      setClassFlex2(false);
    else if (!classFlex2) setClassFlex2(true);

    if (selectedOption2.value !== "Specific date") {
      setDatetime2(undefined);
    }
  }, [selectedOption2, classFlex2]);

  const [errorDate, seterrorDate] = useState(false);
  const [errorDate2, seterrorDate2] = useState(false);
  useEffect(() => {
    const getAvailability = () => ({
      opt: null,
      opt2: null,
      qty: null,
      qty2: null,
      dateTimeStart: null,
      dateTimeEnd: null,
      isSwitched,
      isSwitched2,
    });

    const option = selectedOption.value;
    const option2 = selectedOption2.value;

    if (datetime && datetime < Date.now()) {
      seterrorDate(true);
    } else {
      seterrorDate(false);
    }

    // Validate start & end date
    if (datetime2 && datetime && datetime2 <= datetime) seterrorDate2(true);
    else if (datetime2 && datetime && datetime2 > datetime)
      seterrorDate2(false);
    else seterrorDate2(validateDate(option, qty, datetime2));

    let availability = getAvailability();

    if (selectedOption || config.availability.opt) {
      if (option) availability.opt = option;
      else if (config.availability.opt && config.availability.opt !== "")
        availability.opt = config.availability.opt;
    }

    if (selectedOption2 || config.availability.opt2) {
      if (option2) availability.opt2 = option2;
      else if (config.availability.opt2 && config.availability.opt2 !== "")
        availability.opt2 = config.availability.opt2;
    }

    if (qty) availability.qty = qty;
    if (qty2) availability.qty2 = qty2;

    // If not work, try to send a flag to load from template/config
    if (datetime || config.availability.dateTimeStart) {
      if (config.availability.dateTimeStart && !datetime)
        availability.dateTimeStart = config.availability.dateTimeStart;
      else if (datetime) availability.dateTimeStart = datetime;
    }

    // If not work, try to send a flag to load from template/config
    if (datetime2 || config.availability.dateTimeEnd) {
      if (config.availability.dateTimeEnd && !datetime2)
        availability.dateTimeEnd = config.availability.dateTimeEnd;
      else if (datetime2) availability.dateTimeEnd = datetime2;
    }

    setConfig((prevConfig) => ({
      ...prevConfig,
      availability,
      update: false,
      error: errorDate2 || errorDate,
      errorAvailability: errorDate2 || errorDate,
    }));
  }, [
    selectedOption,
    selectedOption2,
    qty,
    qty2,
    datetime,
    datetime2,
    isSwitched,
    isSwitched2,
    config.availability.dateTimeEnd,
    config.availability.dateTimeStart,
    config.availability.opt,
    config.availability.opt2,
    errorDate,
    errorDate2,
    setConfig,
  ]);

  useEffect(() => {
    const auxAvailability = { ...config.availability };

    const applyConfig = () => {
      setConfig((prevConfig: any) => ({
        ...prevConfig,
        availability: auxAvailability,
      }));
    };

    /*if (
      config.availability &&
      config.availability.dateTimeStart &&
      datetime != config.availability.dateTimeStart
    ) {*/
    if (!qty && config.availability && config.availability.datetime) {
      setDatetime(config.availability.dateTimeStart);
      auxAvailability.dateTimeStart = config.availability.dateTimeStart;
      applyConfig();
    }

    /*if (
      config.availability &&
      config.availability.dateTimeEnd &&
      datetime2 != config.availability.dateTimeEnd
    ) {*/
    if (!qty2 && config.availability && config.availability.datetime2) {
      setDatetime2(config.availability.dateTimeEnd);
      auxAvailability.dateTimeEnd = config.availability.dateTimeEnd;
      applyConfig();
    }
  }, [
    qty,
    config.availability,
    qty2,
    selectedOption,
    selectedOption2,
    datetime,
    datetime2,
    setConfig,
    isSwitched,
    isSwitched2,
  ]);

  const handleMultipleUses = () => {
    if (isSwitched) setIsSwitched2(false);
    setIsSwitched(!isSwitched);
  };

  const handleLockAfterUses = () => {
    setIsSwitched2(!isSwitched2);
  };

  /* 
   * Whe need to refresh isSwitched & isSwitched22 value because we can't initialice when render the first time
     Check what's happen here.. 
     Same problem at InputAccssibility
  */
  useEffect(() => {
    if (config.update && config.availability) {
      const optAux = config.availability.opt;
      const optAux2 = config.availability.opt2;

      // Allow multiples uses && locl after each uses.
      setIsSwitched(config.availability.isSwitched);
      setIsSwitched2(config.availability.isSwitched2);

      /*
       * Update  selectedOption  with config from template
       * Start date
       */
      if (optAux && optAux !== selectedOption.value) {
        setSelectedOption({
          value: optAux,
          label: optAux,
        });

        if (optAux !== "Right now" && optAux !== "Specific date") {
          setQty(config.availability.qty);
        } /* else if (optAux === "Specific date")
          setDatetime(config.availability.dateTimeStart);*/
      }

      /*
       * Update  selectedOption2  with config from template
       * TTL (time to live)
       */
      if (optAux2 && optAux2 !== selectedOption2.value) {
        setSelectedOption2({
          value: optAux2,
          label: optAux2,
        });

        if (optAux2 !== "Right now" && optAux2 !== "Specific date") {
          setQty2(config.availability.qty2);
        } /* else if (optAux2 === "Specific date") {
          setDatetime2(config.availability.dateTimeEnd);
        }*/
      }

      if (
        optAux === "Specific date" &&
        config.availability &&
        config.availability.dateTimeStart
      ) {
        setDatetime(config.availability.dateTimeStart);
      } else {
        setDatetime(undefined);
      }

      if (
        optAux2 === "Specific date" &&
        config.availability &&
        config.availability.dateTimeEnd
      ) {
        setDatetime2(config.availability.dateTimeEnd);
      } else {
        setDatetime2(undefined);
      }

      // Check why we need tu update this value the first time
      // When create  a new secret
      if (qty2Aux) setQty2(qty2Aux);
    }
  }, [config /* , selectedOption, selectedOption2 */]);

  return (
    <div className="availability">
      <Row className="mt-2">
        <Col md="3"></Col>
        <Col md="6">
          <p className="font12">{timeZone}</p>
          <Label className="font14 m0">
            Start date
            <FontAwesomeIcon
              className="left4"
              id="info-circle"
              icon={faInfoCircle}
              color="#0d6efd"
            />
          </Label>
          <UncontrolledTooltip delay={0} target="info-circle" placement="right">
            The secret will not be available until the starting date.
          </UncontrolledTooltip>
          <div className={classFlex && selectedOption ? "flex-center" : ""}>
            <Row>
              <Col>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  value={selectedOption}
                  onChange={setSelectedOption}
                  options={optionsStart}
                />
              </Col>
              <Col>
                {selectedOption &&
                  selectedOption.value !== "Right now" &&
                  selectedOption.value !== "Specific date" && (
                    <Input
                      className="input-availability"
                      type="number"
                      value={qty}
                      onChange={(e) =>
                        (e.target.value === "" ||
                          (parseInt(e.target.value) >= 0 &&
                            parseInt(e.target.value) <= 7890000)) &&
                        setQty(e.target.value)
                      }
                    />
                  )}
                {selectedOption && selectedOption.value === "Specific date" && (
                  <div>
                    <Datetime
                      inputProps={inputProps}
                      value={datetime}
                      dateFormat={"DD/MM/YY"}
                      onChange={(e) => setDatetime(new Date(e._d).getTime())}
                    />
                    {errorDate && (
                      <div>
                        <p className="error">
                          <b>Start date</b> must be greather or equal than{" "}
                          <b>now</b>
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <Label className="m0 top10">
            End date
            <FontAwesomeIcon
              className="left4"
              id="info-circle-end"
              icon={faInfoCircle}
              color="#0d6efd"
            />
          </Label>
          <UncontrolledTooltip
            delay={0}
            target="info-circle-end"
            placement="right"
          >
            Expired secrets are automatically erased.
          </UncontrolledTooltip>
          <div className={classFlex2 && selectedOption2 ? "flex-center" : ""}>
            <Row>
              <Col>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  value={selectedOption2}
                  onChange={setSelectedOption2}
                  options={optionsEnd}
                  placeholder="Single Select"
                />
              </Col>
              <Col>
                {selectedOption2 &&
                  selectedOption2.value !== "Right now" &&
                  selectedOption2.value !== "Specific date" &&
                  selectedOption2.value !== "Never expire" && (
                    <Input
                      className="input-availability"
                      type="number"
                      value={qty2}
                      onChange={(e) =>
                        (e.target.value === "" ||
                          (parseInt(e.target.value) >= 0 &&
                            parseInt(e.target.value) <= 90)) &&
                        setQty2(e.target.value)
                      }
                    />
                  )}
                {selectedOption2 && selectedOption2.value === "Specific date" && (
                  <div>
                    <Datetime
                      inputProps={inputProps}
                      value={datetime2}
                      onChange={(e) => setDatetime2(new Date(e._d).getTime())}
                      dateFormat={"DD/MM/YY"}
                    />
                    {errorDate2 && (
                      <div>
                        <p className="error">
                          <b>End date</b> must be greather than{" "}
                          <b>Start date</b>
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <div className="flex">
            <div className="top10">
              <Label>Allow multiple uses</Label>
              <CustomInput
                type="switch"
                id="switch-1"
                onChange={handleMultipleUses}
                checked={isSwitched}
              />
            </div>
            {isSwitched && (
              <div className="left25 top10">
                <Label>Lock after each uses</Label>
                <div>
                  <div className="dv-switch laeu-switch">
                    <CustomInput
                      type="switch"
                      id="switch-2"
                      onChange={handleLockAfterUses}
                      checked={isSwitched2}
                    />
                  </div>
                  {!isSwitched2 && (
                    <div className="laeu-warning">
                      <Label className="m0 top10 ">
                        <FontAwesomeIcon
                          className="left4"
                          id="info-circle-unlocked"
                          icon={faInfoCircle}
                          color="#f63b3b"
                        />{" "}
                        Warning
                      </Label>
                      <UncontrolledTooltip
                        delay={0}
                        target="info-circle-unlocked"
                        placement="top"
                      >
                        Not recommended
                      </UncontrolledTooltip>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
