import { createContext } from "react";
interface ContextState {
  user: any;
  dispatch: any;
}

export const Context = createContext<ContextState>({
  user: {},
  dispatch: () => {},
});
