import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";

export const SecretError = () => {
  const history = useHistory();

  const goToDashboard = () => {
    history.push("/admin/secret/new-secret");
  };

  const [message, setMessage] = useState("");
  useEffect(() => {
    const state: any = history.location.state;

    if (!state) {
      history.push("/admin/dashboard");
    } else {
      setMessage(state.data);
    }
  }, [history]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Oops!</CardTitle>
            </CardHeader>
            <CardBody>
              <Alert color="danger">
                <span>
                  <span>{message}</span>
                </span>
              </Alert>
              <div className="text-center">
                <Button color="primary" onClick={goToDashboard}>
                  <FontAwesomeIcon
                    className="right5"
                    icon={faRedoAlt}
                    color="#fff"
                  />{" "}
                  Try Again!
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
