import {
  faBitcoin,
  faCcMastercard,
  faCcVisa,
  faEthereum,
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import {
  faCreditCardAlt,
  faMoneyCheckAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import useAPIError from "../../commons/hooks/useAPIError";
import { CustomIconPayment } from "../../components/Pricing/CustomIconPayment";
import { CustomIconPaymentSoon } from "../../components/Pricing/CustomIconPaymentSoon";
import { PriceCard } from "../../components/Pricing/PriceCard";
import { CustomSpinner } from "../../components/Spinner/CustomSpinner";
import { Context } from "../../context/auth/Context";
import { types } from "../../context/types";
import {
  fetchPlanLink,
  fetchPlansAuth,
  fetchPortalLink,
} from "../../services/services";

export const SubscriptionPlans = () => {
  // Notify
  const { addNotify } = useAPIError();

  const [isLoadingRequest, setIsLoadingRequest] = useState(true);

  const [horizontalTabs, sethorizontalTabs] = React.useState("plans");
  const [isLoading, setIsLoading] = useState(false);
  const changeActiveTab = (e, tabState, tabName) => {
    e.preventDefault();
    switch (tabState) {
      case "horizontalTabs":
        sethorizontalTabs(tabName);
        break;
    }
  };

  const history = useHistory();

  // Get name from context
  const { dispatch } = useContext(Context);

  const [data, setData] = useState([]);

  const subscribeLink = async (e: Event) => {
    setIsLoading(true);

    let id = e ? e.target.dataset.id : null;
    const { details, message, redirect } = await fetchPlanLink(id);

    if (redirect) {
      dispatch({
        type: types.logout,
      });
      history.push("/auth/login");
    } else if (details && details.url) {
      window.location.href = details.url;
    } else {
      let messageShow;
      if (message) {
        messageShow = message;
      } else {
        messageShow = "Something went wrong, please try again";
      }

      addNotify(messageShow, "danger");
    }
    setIsLoading(false);
  };

  const manageLink = async (e: Event) => {
    setIsLoading(true);

    const { details, message, redirect } = await fetchPortalLink();

    if (redirect) {
      dispatch({
        type: types.logout,
      });
      history.push("/auth/login");
    } else if (details && details.url) {
      window.location.href = details.url;
    } else {
      let messageShow = "Something went wrong, please try again";

      if (message) {
        messageShow = message;
      }

      addNotify(messageShow, "danger");
    }
  };

  /*
   * Uncomment to remove btn toggole on nav-bar
   * over /admin/settings/plans
   */
  /* useEffect(() => {
    document.body.classList.toggle("pricing-page");
    return function cleanup() {
      document.body.classList.toggle("pricing-page");
    };
  }); */

  useEffect(() => {
    const getPlans = async () => {
      const { details, redirect } = await fetchPlansAuth();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (details) {
        const plans: never[] = details.plans;
        const currentPlan = details.currentPlan ? details.currentPlan : null;

        const dataPlans = [] as any;

        dataPlans.push(
          plans.map((prop, key) => {
            return JSON.parse(prop.details);
          })
        );

        if (currentPlan) {
          dataPlans.push(currentPlan);
        }

        setData(dataPlans);
        setIsLoadingRequest(false);
      }
    };

    if (data.length === 0) getPlans();
  }, [data]);

  return (
    <div className="content">
      <Card className="card-price">
        <CardBody>
          <div className="center-sp">
            <Nav className="nav-pills-info nav-pills-icons" pills>
              <NavItem>
                <NavLink
                  data-toggle="tab"
                  href="#"
                  className={horizontalTabs === "plans" ? "active" : ""}
                  onClick={(e) => changeActiveTab(e, "horizontalTabs", "plans")}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faMoneyCheckAlt}
                      color={`${horizontalTabs === "plans" ? "#fff" : ""}`}
                      width="40px"
                      className="font-awesome tim-icons"
                    />
                    <br /> Plans
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  data-toggle="tab"
                  href="#"
                  className={
                    horizontalTabs === "billing-portal" ? "active" : ""
                  }
                  onClick={(e) =>
                    changeActiveTab(e, "horizontalTabs", "billing-portal")
                  }
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faCreditCardAlt}
                      color={`${
                        horizontalTabs === "billing-portal" ? "#fff" : ""
                      }`}
                      width="40px"
                      className="font-awesome tim-icons"
                    />
                    <br /> Billing Portal
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <hr className="hr-ligth" />
          {isLoadingRequest && <CustomSpinner />}
          {!isLoadingRequest && (
            <TabContent className="tab-space" activeTab={horizontalTabs}>
              <TabPane tabId="plans">
                <CardTitle className="text-center" tag="h3">
                  Pick the best monthly plan for you
                  <br />
                  <br />
                </CardTitle>
                <Row className="">
                  {data.length > 0 &&
                    data[0].map((item, i) => {
                      let buttonText = "Try it for FREE";

                      if (data.length === 2) {
                        if (data[1].plan.stars > item.metadata.stars) {
                          buttonText = "Downgrade";
                        } else if (data[1].plan.stars < item.metadata.stars) {
                          buttonText = "Upgrade";
                        } else {
                          buttonText = "Manage";
                        }
                      }

                      // Return the element. Also pass key
                      return (
                        <PriceCard
                          key={i}
                          buttonText={buttonText}
                          name={item.name}
                          subscribeLink={
                            data.length === 2 &&
                            data[1].plan.stars === item.metadata.stars
                              ? manageLink
                              : subscribeLink
                          }
                          id={item.prices[0].id}
                          secretsStorage={item.metadata.storage.secrets}
                          charStorage={item.metadata.storage.bytes}
                          secretsTransfer={item.metadata.transfer.secrets}
                          charTransfer={item.metadata.transfer.bytes}
                          stars={item.metadata.stars}
                          templates={item.metadata.templates}
                          price={item.prices[0].price / 100}
                          savings={item.metadata.savings}
                          webhooks={item.metadata.webhooks}
                          selectedClass={
                            data.length === 2 &&
                            data[1].plan.stars === item.metadata.stars
                          }
                          isLoading={isLoading}
                        />
                      );
                    })}
                </Row>
              </TabPane>
              <TabPane tabId="billing-portal">
                <div className="text-center">
                  <h3>Billing Portal</h3>
                  <p>
                    You will be redirected to a different portal to manage your
                    subscription and payment details, you can come back to
                    SharePass portal by clicking exit or back button.
                  </p>
                  <p>&nbsp;</p>
                  {isLoading && (
                    <div>
                      <CustomSpinner />
                      <br />
                    </div>
                  )}
                  <div className="text-center">
                    <button
                      disabled={isLoading}
                      className="btn btn-sm"
                      onClick={manageLink}
                    >
                      <i className="fas fa-external-link-alt"></i> Go to Billing
                      Portal
                    </button>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          )}

          <hr className="hr-ligth" />
        </CardBody>
        <CardFooter>
          <div className="payments">
            Payments supported
            <div className="payment-options">
              <CustomIconPayment icon={faCcVisa} />
              <CustomIconPayment icon={faCcMastercard} />
              <CustomIconPayment icon={faCreditCard} />
              <CustomIconPaymentSoon icon={faBitcoin} />
              <CustomIconPaymentSoon icon={faEthereum} />
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
