import {
  URL_LABEL,
  URL_SECRET_WEBAUTHN_EXISTENCE,
  URL_SECRET_WEBAUTHN_CHECK,
  URL_SECRET_WEBAUTHN_CREATE,
  URL_SECRET_WEBAUTHN_DELETE,
  URL_SECRET_WEBAUTHN_CANCEL,
  URL_SECRET_WEBAUTHN_LIST,
  URL_SECRET_WEBAUTHN_PARSE,
  URL_SECRET_WEBAUTHN_REMOTE_ADD
} from "../constants/urls";
import { isLoggedIn } from "./common";

export const fetchNewLabel = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_LABEL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("Error when trying to create a new label", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchCheckRequestCode = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SECRET_WEBAUTHN_CHECK, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("Error when trying to check the request code", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchCheckExistence = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SECRET_WEBAUTHN_EXISTENCE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      }
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("Error when trying to check the request code", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchLabels = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_LABEL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
        global: true
      },
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("Error when trying to create a new label", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchDeleteLabel = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_LABEL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to delete a label", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchNewSecretCredentialRemoteRequest = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SECRET_WEBAUTHN_REMOTE_ADD, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(
          "Error when trying to create a new secret public credential",
          error
        );
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchNewSecretCredentialParseAdd = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SECRET_WEBAUTHN_PARSE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(
          "Error when trying to create a new secret public credential",
          error
        );
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchNewSecretCredentialRequest = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SECRET_WEBAUTHN_CREATE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(
          "Error when trying to create a new public key request object",
          error
        );
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchSecretCredentials = async (global = false) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");

    let headers = {
      "Content-Type": "application/json",
      Auth: newToken === "" ? token : newToken,
    };
  
    if(global){
      headers["global"] = true;
    }
    
    return await fetch(URL_SECRET_WEBAUTHN_LIST, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("Error when trying to get all secret credentials", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchDeleteSecretCredential = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SECRET_WEBAUTHN_DELETE, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to delete a secret credential", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchCancelEnrollment = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SECRET_WEBAUTHN_CANCEL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to cancel a security key enrollment", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};
