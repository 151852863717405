import { useRef, useState } from "react";

import { EnvelopeIncompleteURL } from "../components/EnvelopeIncompleteURL";
import { EnvelopeOpen } from "../components/EnvelopeOpen";
import FrontendConfig from "../../assets/js/config";

const EnvelopeCompleteDefault = () => {

  const secretPart = window.location.pathname.split('/');
	const currentPath = window.location.hash.split('/');

  const fullId = secretPart[3] + currentPath[1];

  return (
    
    
      
      
        (currentPath.length != 2 && currentPath.length != 3) || secretPart.length != 5?
        <div className="content">
          <EnvelopeIncompleteURL/>
        </div>
        :
        <EnvelopeOpen id={fullId} pin={currentPath.length == 3 ? currentPath[2] : null}/>
      

  );
};

const tryRequire = () => {
  try {
    const env = FrontendConfig.env;
    return require("../../envs/" + env + "/views/pages/EnvelopeComplete");
  } catch (err) {
    return null;
  }
}


const EnvelopeComplete = tryRequire() ? tryRequire().default : EnvelopeCompleteDefault;
export default EnvelopeComplete;
