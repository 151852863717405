import { _arrayBufferToBase64, encryptSecret } from "./secret";
import { rsaEncrypt, crystalsKyberEncrypt } from "./crypto";

/* When create a new secret */
export const createEnvelopeBodyRequest = async (data: any, publicKey:any) => {
    
  let body = {};
  /*
   * Generate secret
   */
  let secretContent;

  const endData = data.data;
  switch (data.type) {
    case "password":
      secretContent = endData;
      break;
    case "message":
      secretContent = endData;
      break;
    case "credentials":
      secretContent = JSON.stringify({
        user: endData.email,
        password: endData.password,
        hostname: endData.hostname,
        secret: endData.f2a,
      });
      break;
    case "QR":
    case "custom":
    case "file":
      secretContent = endData;
      break;
    default:
      break;
  }

  
  let randomWord = window.crypto.getRandomValues(new Uint8Array(32));
  let strRandomWord = _arrayBufferToBase64(randomWord).replace(/=/g, "");

  strRandomWord = strRandomWord.replace(/\//g, "a");
  strRandomWord = strRandomWord.replace(/\+/g, "b");

  let encryptedData = await encryptSecret(strRandomWord, secretContent);

  body.secret = _arrayBufferToBase64(encryptedData);
  body.alg = publicKey.alg;
  body.iv = publicKey.iv;
  body.token = data.sendingToken.token;
  body.secretId = data.secretId;

  /**
   * Encrypt symmetric key with asymmetric public key and algorithm configured
   */
  switch(publicKey.alg){
    case "rsa":
      body.key = await rsaEncrypt(publicKey,strRandomWord);
    break;
    case "crystalsKyber":
      body.key = crystalsKyberEncrypt(publicKey,strRandomWord);
    break;
  }

  return { body };
  
};

