import sjcl from "sjcl";

const toUTF8Array = (str) => {
  let utf8 = [];
  for (let i = 0; i < str.length; i++) {
    let charcode = str.charCodeAt(i);
    if (charcode < 0x80) utf8.push(charcode);
    else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(
        0xe0 | (charcode >> 12),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    }
    // surrogate pair
    else {
      i++;
      // UTF-16 encodes 0x10000-0x10FFFF by
      // subtracting 0x10000 and splitting the
      // 20 bits of 0x0-0xFFFFF into two halves
      charcode =
        0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    }
  }
  return utf8;
};

const toBitArrayCodec = (bytes) => {
  let out = [],
    i,
    tmp = 0;
  for (let i = 0; i < bytes.length; i++) {
    tmp = (tmp << 8) | bytes[i];
    if ((i & 3) === 3) {
      out.push(tmp);
      tmp = 0;
    }
  }
  if (i & 3) {
    out.push(sjcl.bitArray.partial(8 * (i & 3), tmp));
  }
  return out;
};

const encodeBase32 = (secretkey) => {
  const bytes = toUTF8Array(secretkey);
  const bits = toBitArrayCodec(bytes);
  return sjcl.codec.base32.fromBits(bits);
};

const makeRandomString = (length) => {
  let result = [];
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
};

export const generateRandomSecret2FA = () =>
  encodeBase32(makeRandomString(256)).substring(0, 32);
