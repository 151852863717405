import { Button, Modal, ModalBody } from "reactstrap";

export const CustomModal = ({ modalClassic, toggleModalClassic, textBody }) => {
  return (
    <>
      <Modal isOpen={modalClassic} toggle={toggleModalClassic}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalClassic}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Terms and conditions</h6>
        </div>
        <ModalBody className="text-center modal-o">
          <p>{textBody}</p>
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalClassic}
          >
            Close
          </Button>
          {/* <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalClassic}
          >
            Close
          </Button> */}
        </div>
      </Modal>
    </>
  );
};
