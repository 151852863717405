import { types } from "../types";

export const appReducer = (state = {}, action: any) => {
  /*@TODO: BEGIN - Temporary patch */
  let userTemp: any = localStorage.getItem("user");

  if (userTemp) {
    userTemp = JSON.parse(userTemp);

    if (userTemp.logged) {
      state.advancedMode = userTemp.advancedMode;
      state.sidebarToggle = userTemp.sidebarToggle;
      //state.purposeMode = userTemp.purposeMode;

      if (action.payload) {
        action.payload.advancedMode = userTemp.advancedMode;
        action.payload.sidebarToggle = userTemp.sidebarToggle;
        //action.payload.purposeMode = userTemp.purposeMode;
      }
    }
  }
  /*@TODO: END - Temporary patch */

  switch (action.type) {
    case types.login:
      return { ...action.payload, logged: true };
    case types.refresh:
      return {
        ...state,
        expiresIn: action.payload.expiresIn,
        token: action.payload.token,
      };
    case types.userDetails:
      return {
        ...state,
        userDetails: action.payload.userDetails,
      };
    case types.lightTheme:
      return {
        ...state,
        lightTheme: action.payload.lightTheme,
      };
    case types.purposeMode:
      return {
        ...state,
        purposeMode: action.payload.purposeMode,
      };
    case types.logout:
      return { logged: false };
    default:
      return state;
  }
};
