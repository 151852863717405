import React, { useState } from "react";

function PasswordTemplate({ secret }) {
  const [inputType, setInputType] = useState("password");
  const [textToCopy, setTextToCopy] = useState(secret);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnCopyContent, setBtnCopyContent] = useState(
    <i className="fas fa-copy"></i>
  );

  function revealData() {
    if (inputType == "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(textToCopy);
    setBtnDisabled(true);

    /* Alert the copied text */
    setBtnCopyContent("Copied!");

    setTimeout(function () {
      setBtnDisabled(false);
      setBtnCopyContent(<i className="fas fa-copy"></i>);
    }, 3000);
  }


  return (
    <>
      <div className="mb-3">
        <i className="fas fa-check-circle"></i> You can reveal the secret
        password delivered.
      </div>
      <div className="input-group">
        <div className="input-group-prepend">
          <button
            className="btn btn-outline-primary wider-prepend"
            type="button"
            onClick={revealData}
          >
            <i className="fas fa-eye"></i>
          </button>
        </div>
        <input
          type={inputType}
          className="form-control"
          readOnly
          value={secret}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-primary copy-data"
            type="button"
            disabled={btnDisabled}
            onClick={copyToClipboard}
          >
            {btnCopyContent}
          </button>
        </div>
      </div>
    </>
  );
}

export default PasswordTemplate;
