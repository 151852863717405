import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  UncontrolledDropdown
} from "reactstrap";
import useAPIError from "../../commons/hooks/useAPIError";
import { allColors, secretTypes } from "../../constants/newSecret";
import { Context } from "../../context/auth/Context";
import { types } from "../../context/types";
import { fetchLabels } from "../../services/settings";
import { filterBody } from "../../utils/filter";
import { parseLabels } from "../../utils/settings";
import { CustomCheckbox } from "../components/CustomCheckbox";
import { CustomErrorField } from "../components/CustomErrorField";
import { CustomSelectFormik } from "../components/CustomSelectFormik";

interface Values {
  otl: string;
  type: string;
  privDesc: string;
  publicDesc: string;
  label: string;
  color: string;
  lockedByOwner: string;
  pin: string;
  expired: string;
  ipList: string;
  opened: string;
}

interface StartId {
  id: string;
  timestamp: number;
  reload?: boolean;
}

interface FilterParams {
  otl?: boolean;
  type?: string;
  privateDescription?: string;
  publicDescription?: string;
  lockedByOwner?: boolean;
  label?: CustomLabel; // An object that matches the codeName AND/OR the color attribute
  color?: string;
  pin?: boolean; // A boolean if the secret contains a pin (static or dynamic) in the pin attribute
  expired?: boolean; // A boolean that indicates the secrets that have the expiry value already expired
  ipList?: boolean; // A boolean if the secret contains at least one IP address restriction in the ipList attribute
  opened?: boolean;
}
interface BodySearch {
  bin: boolean;
  purpose: string;
  exclusiveStartId?: StartId;
  params?: FilterParams;
  asc?: boolean;
}

interface CustomLabel {
  codeName?: string;
  color?: string;
}

interface Error {}

export const SecretsFilter = ({
  isLoading,
  getSecrets,
  initialValues,
  setInitialValues,
  setIsFilterActive,
}: any) => {
  // To be able to close dropdown after submit
  const myRef = useRef<any>(null);

  // To handle routing
  const history = useHistory();

  // Notify
  const { addNotify } = useAPIError();

  const { dispatch } = useContext(Context);

  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const getLabels = async () => {
      const { status, details, redirect } = await fetchLabels();
      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        if (details.length > 0) {
          let auxLabels = parseLabels(details);

          auxLabels = [
            { label: "Any", value: "any", color: "Any" },
            ...auxLabels,
          ];
          setLabels(auxLabels);
        }
      } else {
        addNotify("An error was ocurred when trying to load labels", "danger");
      }
    };

    if (labels.length === 0) getLabels();
  }, [labels, addNotify, dispatch, history]);

  return (
    <UncontrolledDropdown ref={myRef}>
      <DropdownToggle
        caret
        className="btn-block"
        color="primary"
        data-toggle="dropdown"
        id="dropdownMenuButton"
        type="button"
        disabled={isLoading}
      >
        <FontAwesomeIcon icon={faFilter} /> Filter
      </DropdownToggle>
      <DropdownMenu aria-labelledby="dropdownMenuButton">
        <div className="p10">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={(value) => {
              /*   let errors: Error = {};
              if (
                value.type === "" &&
                value.privDesc === "" &&
                value.publicDesc === "" &&
                value.label === ""
              )
                errors.label = "Pleas, fill at least one field";

              return errors; */
            }}
            onSubmit={async (
              values: Values,
              { setSubmitting }: FormikHelpers<Values>
            ) => {
              setSubmitting(true);
              const filterParams: FilterParams = filterBody(values);

              // Save filter params
              setInitialValues({ ...values });

              // Set if filter is active after submit
              const isActive =
                Object.keys(filterParams).length > 0 &&
                filterParams.constructor === Object;
              setIsFilterActive(isActive);

              let body: BodySearch = {
                bin: false,
                purpose: "send",
                ...filterParams,
              };

              if (isActive) await getSecrets(body);
              myRef.current && myRef.current.toggle();
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form className="form-formik">
                <div className="flex">
                  <FormGroup className="right5">
                    <Label>
                      Private description
                      <small className="left5">(case sensitive)</small>
                    </Label>
                    <Field
                      id="privDesc"
                      name="privDesc"
                      type="text"
                      placeholder="Contains..."
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Public description
                      <small className="left5">(case sensitive)</small>
                    </Label>
                    <Field
                      id="publicDesc"
                      name="publicDesc"
                      type="text"
                      placeholder="Contains..."
                      className="form-control"
                    />
                  </FormGroup>
                </div>
                <div className="flex">
                  <FormGroup>
                    <Label>Label by name</Label>
                    <CustomSelectFormik
                      disabled={values.color !== "Any" && values.color !== ""}
                      placeholder="Any"
                      options={labels}
                      value={values.label}
                      onChange={(value: any) =>
                        setFieldValue("label", value.label)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Label by color</Label>
                    <CustomSelectFormik
                      disabled={values.label !== "Any" && values.label !== ""}
                      placeholder="Any"
                      options={allColors}
                      value={values.color}
                      onChange={(value: any) =>
                        (value.label !== "Any" &&
                          setFieldValue("color", value.value)) ||
                        setFieldValue("color", "Any")
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Type</Label>
                    <CustomSelectFormik
                      placeholder="Any"
                      options={secretTypes}
                      value={values.type}
                      onChange={(value: any) =>
                        (value.label !== "Any" &&
                          setFieldValue("type", value.value)) ||
                        setFieldValue("type", "Any")
                      }
                    />
                  </FormGroup>
                </div>
                <div className="flex filter">
                  <FormGroup className="check">
                    <Label className="m0">Allow multiple uses</Label>
                    <div className="flex">
                      <CustomCheckbox
                        handleCheck={setFieldValue}
                        field="otl"
                        initialValue={initialValues.otl}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="check">
                    <Label className="m0">Locked by owner</Label>
                    <div className="flex">
                      <CustomCheckbox
                        handleCheck={setFieldValue}
                        field="lockedByOwner"
                        initialValue={initialValues.lockedByOwner}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="check">
                    <Label className="m0">Have any pin?</Label>
                    <div className="flex">
                      <CustomCheckbox
                        handleCheck={setFieldValue}
                        field="pin"
                        initialValue={initialValues.pin}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="check">
                    <Label className="m0">Expired?</Label>
                    <div className="flex">
                      <CustomCheckbox
                        handleCheck={setFieldValue}
                        field="expired"
                        initialValue={initialValues.expired}
                      />
                    </div>
                  </FormGroup>
                </div>
                <div className="flex">
                  <FormGroup className="check">
                    <Label className="m0">Opened?</Label>
                    <div className="flex">
                      <CustomCheckbox
                        handleCheck={setFieldValue}
                        field="opened"
                        initialValue={initialValues.opened}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="check">
                    <Label className="m0">
                      Contains IP address restriction?
                    </Label>
                    <div className="flex">
                      <CustomCheckbox
                        handleCheck={setFieldValue}
                        field="ipList"
                        initialValue={initialValues.ipList}
                      />
                    </div>
                  </FormGroup>
                </div>
                <div className="text-center top10">
                  <ErrorMessage name="label" component={CustomErrorField} />
                </div>
                <Button
                  className="btn-fill btn-forgot m0"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  <FontAwesomeIcon
                    id="PinTooltip"
                    icon={faSearch}
                    color="#fff"
                  />{" "}
                  Search
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
