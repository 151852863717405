import React, { useEffect, useState } from "react";
import Select from "react-select";

export const CustomSelectFormik = ({
  onChange,
  options,
  value,
  className,
  placeholder,
  disabled,
}: any) => {
  const defaultValue = (options: any, value: any) => {
    let opt = options.find((options: any) => options.value === value);

    if (!opt) {
      opt = null;
    }
    return opt;
  };

  const [customClass, setCustomClass] = useState("");
  useEffect(() => {
    const color = options.find((opt: any) => opt.value === value);

    if (color) setCustomClass(color.name);
  }, [value, options]);

  return (
    <div className={className}>
      <Select
        isDisabled={disabled}
        placeholder={placeholder}
        className={`react-select ${customClass}`}
        classNamePrefix="react-select"
        value={defaultValue(options, value)}
        onChange={(value) => onChange(value)}
        options={options}
      />
    </div>
  );
};
