import React from "react";
import { InfoProps } from "../../commons/models/models";

export const PasswordResult = ({ font }: InfoProps) => {
  const { bgColor, color, text, description, noData } = font;
  if (noData) return <></>;
  else
    return (
      <div>
        <div className="flex mTop10">
          <div className={`password-strength ${bgColor}`}></div>
          <div
            className={`password-strength ${
              bgColor !== "bg-danger" ? bgColor : ""
            } `}
          ></div>
          <div
            className={`password-strength ${
              bgColor === "bg-success" ? bgColor : ""
            }`}
          ></div>
        </div>
        <div className={`display-strength ${color}`}>
          <label className="semi-bold">
            {text} <span className="strength-txt">{description}</span>
          </label>
        </div>
      </div>
    );
};
