import {
  faCheckCircle,
  faGem,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card, CardBody, CardTitle, Col } from "reactstrap";
import { getClasses } from "../../utils/plans";
import { CustomItemPrice } from "./CustomItemPrice";

interface PriceProps {
  subscribeLink: any;
  buttonText: string;
  id: string;
  name: string;
  secretsStorage: number;
  charStorage: number;
  secretsTransfer: number;
  charTransfer: number;
  stars: number;
  templates: number;
  price: number;
  savings: string;
  selectedClass: boolean;
  webhooks: boolean;
  isLoading: boolean;
}

export const PriceCard = ({
  subscribeLink,
  buttonText,
  id,
  name,
  secretsStorage,
  charStorage,
  secretsTransfer,
  charTransfer,
  stars,
  templates,
  price,
  savings,
  selectedClass,
  webhooks,
  isLoading,
}: PriceProps) => {
  const { cardClass, classBtn, colorIcon, sizeIcon } = getClasses(stars);

  return (
    <Col lg="3" md="12" sm="12" xs="12">
      <Card
        className={`card-item-price ${cardClass} ${
          selectedClass ? "plan-selected" : ""
        }`}
      >
        <CardBody>
          <CardTitle className="flex" tag="h4">
            <br />
            <br />
            <div>
              <FontAwesomeIcon
                className={`icon-price ${sizeIcon}`}
                icon={faGem}
                color={colorIcon}
              />
            </div>
            <div className="card-price-title">
              <div className="custom-title">{name}</div>
              <div className="custom-sub-title">
                <span>{savings}</span>
              </div>
            </div>
            <div className="price-title">${price}</div>
          </CardTitle>
          <hr />
          <CustomItemPrice title="secrets storage" value={secretsStorage} />
          <CustomItemPrice title="chars storage" value={charStorage} />
          <CustomItemPrice title="secrets/month" value={secretsTransfer} />
          <CustomItemPrice title="chars/month" value={charTransfer} />
          <CustomItemPrice title="templates" value={templates} />
          <div className="custom-sub-item">
            <FontAwesomeIcon
              className="check-item"
              icon={webhooks ? faCheckCircle : faTimesCircle}
              color={webhooks ? "#00f2c3" : "#6f6f73"}
            />
            {/* <div className="title-bold">{templates}</div> */}
            <div className="item-sub-title">Webhooks</div>
          </div>
          <hr />
          <div className="btn-plan">
            <Button
              onClick={subscribeLink}
              className="btn-round btn-just-icon"
              data-id={id}
              color={classBtn}
              disabled={isLoading}
            >
              {buttonText}
            </Button>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
