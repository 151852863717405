import { faChartBar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Line } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import FrontendConfig from "../assets/js/config";
import { CustomDashboardAlert } from "../components/Customs/CustomDashboardAlert";
import { CustomWidgetInfo } from "../components/Customs/CustomWidgetInfo";
import { WidgetConsumes } from "../components/Customs/WidgetConsumes";
import { WidgetSubscription } from "../components/Customs/WidgetSubscription";
import { chartOptions } from "../constants/dashboard";
import { Context } from "../context/auth/Context";
import { DataContext } from "../context/data/DataContext";
import { dataTypes, types } from "../context/types";
import {
  fetchPutWelcomescreen,
  fetchSetSubscription,
  fetchUserProfile,
} from "../services/services";
import {
  parseConsumptionsDaily,
  parseConsumptionsMonthly,
  parseData,
} from "../utils/dashboard";
import { CustomModalWelcome } from "../views/components/CustomModalWelcome";

const DashboardDefault = (props) => {
  // Context
  const { dispatch } = useContext(Context);

  // Context for profile
  const {
    profile,
    profile: { consumption, counters, subscription },
    dispatch2,
  } = useContext(DataContext);

  /* Show label modal */
  const [welcomeModal, setWelcomeModal] = useState(
    consumption && profile && typeof profile.welcomeScreen != "undefined"
      ? profile.welcomeScreen
      : false
  );

  const toggleWelcomeModal = () => {
    setWelcomeModal(!welcomeModal);

    fetchPutWelcomescreen({ welcomeScreen: false });

    dispatch2({
      type: dataTypes.profile,
      payload: { ...profile, ...{ welcomeScreen: false } },
    });
  };

  const toggleWelcomeRedirectModal = () => {
    setWelcomeModal(!welcomeModal);

    fetchPutWelcomescreen({ welcomeScreen: false });

    dispatch2({
      type: dataTypes.profile,
      payload: { ...profile, ...{ welcomeScreen: false } },
    });

    history.push("/admin/secret/new-secret");
  };

  // To show notifications at top
  const [notify, setNotify] = useState(0);

  const history = useHistory();

  const [alertExceeded, setAlertExceeded] = useState({
    showAlert: false,
    title: "",
    title2: null,
    color: "",
  });

  const [secretUsageStatus, setSecretUsageStatus] = useState();
  const [storageUsageStatus, setStorageUsageStatus] = useState();
  const [templatesUsageStatus, setTemplatesUsageStatus] = useState();

  const [data, setData] = useState();
  const [alert, setAlert] = useState();

  const [dailySecretsChartData, setDailySecretsChartData] = useState({
    data: {},
    options: chartOptions,
  });
  const [dailyBytesChartData, setDailyBytesChartData] = useState({
    data: {},
    options: chartOptions,
  });

  const [monthlySecretsChartData, setMonthlySecretsChartData] = useState({
    data: {},
    options: chartOptions,
  });
  const [monthlyBytesChartData, setMonthlyBytesChartData] = useState({
    data: {},
    options: chartOptions,
  });

  const [dailyBtnClass, setDailyBtnClass] = useState("btn-primary btn-sm");
  const [monthlyBtnClass, setMonthlyBtnClass] = useState(
    "btn-primary btn-sm btn-simple"
  );

  const [showDailyData, setShowDailyData] = useState(true);
  useEffect(() => {
    if (showDailyData) {
      // Setup classes for btn daily & monthly
      setMonthlyBtnClass("btn-primary btn-sm btn-simple");
      setDailyBtnClass("btn-primary btn-sm");
    } else {
      // Setup classes for btn daily & monthly
      setDailyBtnClass("btn-primary btn-sm btn-simple");
      setMonthlyBtnClass("btn-primary btn-sm");
    }

    if (data) {
      if (data.consumption) {
        if (data.consumption.daily) {
          const { dailySecretsData, dailyBytesData } =
            parseConsumptionsDaily(data);

          setDailySecretsChartData((prevValues) => ({
            options: prevValues.options,
            data: dailySecretsData,
          }));

          setDailyBytesChartData((prevValues) => ({
            options: prevValues.options,
            data: dailyBytesData,
          }));
        }

        if (data.consumption.monthly) {
          const { monthlySecretsData, monthlyBytesData } =
            parseConsumptionsMonthly(data);

          // Monthly info for charts
          setMonthlySecretsChartData((prevValues) => ({
            options: prevValues.options,
            data: monthlySecretsData,
          }));
          setMonthlyBytesChartData((prevValues) => ({
            options: prevValues.options,
            data: monthlyBytesData,
          }));
        }
      }
    }
  }, [showDailyData, data]);

  const hideAlert = () => {
    setAlert(null);
  };

  const goToSubscribe = () => {
    history.push("/admin/settings/plans");
  };

  useEffect(() => {
    const showPlanAlert = (title, plan, style) => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={title}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle={style}
          btnSize=""
        >
          You are now subscribed to plan <strong> {plan} </strong>, enjoy!
        </ReactBSAlert>
      );
    };

    const checkSubscription = async () => {
      const currentURL = new URLSearchParams(history.location.search);

      if (
        currentURL.get("id") &&
        currentURL.get("state") &&
        currentURL.get("state") === "succeeded"
      ) {
        const subscriptionId = currentURL.get("id");

        const { details, redirect } = await fetchSetSubscription({
          subscriptionId,
        });

        if (redirect) {
          dispatch({
            type: types.logout,
          });
          history.push("/auth/login");
        } else if (details && details.subscription) {
          getUserDetails();

          showPlanAlert(
            "Subscription completed!",
            details.subscription.plan.name,
            "success"
          );
        }
      } else {
        getUserDetails();
      }
    };

    const getUserDetails = async () => {
      const detailsContext = { consumption, counters, subscription };

      if (detailsContext.consumption) {
        setData(parseData(detailsContext));
      } else {
        //const { details, redirect } = await fetchUserDetails();
        const { details, redirect } = await fetchUserProfile();

        if (redirect) {
          dispatch({
            type: types.logout,
          });
          history.push("/auth/login");
        } else if (details) {
          dispatch2({
            type: dataTypes.profile,
            payload: { ...details },
          });
          setData(parseData(details));
        }
      }
    };

    if (!data) {
      checkSubscription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch, history]);

  useEffect(() => {
    const statusPercent = (percent) => {
      let status;

      if (percent < 30) {
        status = "info";
      } else if (percent < 70) {
        status = "success";
      } else if (percent < 90) {
        status = "warning";
      } else {
        status = "danger";
      }

      return status;
    };

    if (
      data &&
      data.subscription &&
      data.subscription.secretsStorageUsedPercent
    ) {
      setSecretUsageStatus(
        statusPercent(data.subscription.secretsStorageUsedPercent)
      );
    }

    if (
      data &&
      data.subscription &&
      data.subscription.bytesStorageUsedPercent
    ) {
      setStorageUsageStatus(
        statusPercent(data.subscription.bytesStorageUsedPercent)
      );
    }

    if (data && data.subscription && data.subscription.templatesUsedPercent) {
      setTemplatesUsageStatus(
        statusPercent(data.subscription.templatesUsedPercent)
      );
    }

    let exceededSecrets = 0;
    let exceededBytes = 0;
    let exceededTemplates = 0;

    if (data && data.subscription) {
      if (
        data.subscription.secretsLimit &&
        data.subscription.secretsLimit < data.subscription.secretsInDatabase
      ) {
        exceededSecrets =
          data.subscription.secretsInDatabase - data.subscription.secretsLimit;
      }

      if (
        data.subscription.bytesLimit &&
        data.subscription.bytesLimit < data.subscription.bytesInDatabase
      ) {
        exceededBytes =
          data.subscription.bytesInDatabase - data.subscription.bytesLimit;
      }

      if (
        data.subscription.templatesLimit &&
        data.subscription.templatesLimit < data.subscription.templatesInDatabase
      ) {
        exceededTemplates =
          data.subscription.templatesInDatabase -
          data.subscription.templatesLimit;
      }

      if (
        data.subscription.enabled &&
        (exceededSecrets > 0 || exceededBytes > 0 || exceededTemplates > 0)
      ) {
        setAlertExceeded({
          color: "danger-hard",
          showAlert: true,
          title: "UNSERVICEABLE ACCOUNT",
          title2: "You have exceeded at least one subscription limit.",
          tooltip:
            "The \"Unserviceable account\" state means that you won't be able to create new secrets and secrets from existing links won't be reachable. All your data remains the same. To change the account status back to normal you need to free storage by deleting old stored secrets or unused templates.",
        });
      } else if (data && data.subscription && !data.subscription.enabled) {
        console.log(data && data.subscription);
        setAlertExceeded({
          color: "danger-hard",
          showAlert: true,
          title: "UNSERVICEABLE ACCOUNT",
          title2: "Your subscription is not active anymore.",
        });
      }
    } else if (data && !data.subscription) {
      setAlertExceeded({
        color: "info",
        showAlert: true,
        title: "SUBSCRIBE TO A PLAN AND START SHARING INFORMATION SECURELY",
      });
    }
  }, [data]);

  return (
    <>
      <CustomModalWelcome
        isOpen={welcomeModal}
        toggle={toggleWelcomeModal}
        toggleRedirect={toggleWelcomeRedirectModal}
      />
      <div className="content">
        {notify > 0 && (
          <div className="flex">
            <UncontrolledAlert
              color="primary"
              fade={false}
              className="custom-notify"
              onClick={() => setNotify((prev) => prev - 1)}
            >
              <span>
                <b>Last updated - </b>5 min ago
              </span>
            </UncontrolledAlert>
            <UncontrolledAlert
              color="info"
              fade={false}
              className="custom-notify"
              onClick={() => setNotify((prev) => prev - 1)}
            >
              <span>
                <b>Some notify</b> New updates
              </span>
            </UncontrolledAlert>
            <UncontrolledAlert
              color="warning"
              fade={false}
              className="custom-notify"
              onClick={() => setNotify((prev) => prev - 1)}
            >
              <span>
                <b>Chapa notify - </b>
                Coming soon!
              </span>
            </UncontrolledAlert>
          </div>
        )}
        <div className="shadow-top"></div>
        <div
          className={`content-scroll ${notify ? "" : "content-scroll-notify"}`}
        >
          <br />
          {alertExceeded.showAlert && (
            <CustomDashboardAlert
              color={alertExceeded.color}
              title={alertExceeded.title}
              title2={alertExceeded.title2}
              tooltip={alertExceeded.tooltip}
            />
          )}
          {alert}
          <Row className="dashboard">
            {false && (
              <WidgetSubscription
                goToSubscribe={goToSubscribe}
                subscription={
                  (data &&
                    data.subscription &&
                    data.subscription.plan &&
                    data.subscription.plan.name) ||
                  ""
                }
                trial={
                  (data && data.subscription && data.subscription.trial) ||
                  false
                }
                enabled={
                  (data && data.subscription && data.subscription.enabled) ||
                  false
                }
                secretTotalUsedPercent={
                  (data &&
                    data.subscription &&
                    data.subscription.secretTotalUsedPercent) ||
                  ""
                }
                dataTotalUsedPercent={
                  (data &&
                    data.subscription &&
                    data.subscription.dataTotalUsedPercent) ||
                  ""
                }
                endSubscription={
                  (data && data.subscription && data.subscription.cancelled) ||
                  ""
                }
                renewSubscription={
                  (data && data.subscription && data.subscription.expiry) || ""
                }
                secrestLeft={
                  (data &&
                    data.subscription &&
                    data.subscription.secretsLeft) ||
                  "--"
                }
                bytesLeft={
                  (data && data.subscription && data.subscription.bytesLeft) ||
                  "--"
                }
                stars={
                  data &&
                  data.subscription &&
                  data.subscription.plan &&
                  data.subscription.plan.stars
                }
              />
            )}
            <Col lg="12" md="12">
              <Row className="dashboard storage">
                <Col lg="12" md="12">
                  <Card className="card-stats custom">
                    <CardBody>
                      <CardTitle className="center-sp" tag="h4">
                        <i className="fas fa-database flex-sp"></i>{" "}
                        &nbsp;Storage
                      </CardTitle>
                      <Row className="dashboard">
                        <WidgetConsumes
                          title="Secrets"
                          icon="tim-icons icon-key-25 flex-sp text-primary"
                          subTitle={
                            (data &&
                              data.subscription &&
                              data.subscription.secretsStorageUsed) ||
                            "--"
                          }
                          value={
                            (data &&
                              data.subscription &&
                              data.subscription.secretsStorageUsedPercent) ||
                            ""
                          }
                          state={secretUsageStatus}
                        />
                        <WidgetConsumes
                          title="Bytes"
                          icon="far fa-save flex-sp text-primary"
                          subTitle={
                            (data &&
                              data.subscription &&
                              data.subscription.bytesStorageUsed) ||
                            "--"
                          }
                          value={
                            (data &&
                              data.subscription &&
                              data.subscription.bytesStorageUsedPercent) ||
                            ""
                          }
                          state={storageUsageStatus}
                        />
                        <WidgetConsumes
                          title="Templates"
                          icon="tim-icons icon-paper flex-sp text-primary"
                          subTitle={
                            (data &&
                              data.subscription &&
                              data.subscription.templatesUsed) ||
                            "--"
                          }
                          value={
                            (data &&
                              data.subscription &&
                              data.subscription.templatesUsedPercent) ||
                            ""
                          }
                          state={templatesUsageStatus}
                        />
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <>
            <Row>
              <Col lg="12" md="12">
                <Card>
                  <CardHeader className="header-consumptions clearfix">
                    <div className="left">
                      <CardTitle tag="h4" className="title-dashboard">
                        <FontAwesomeIcon
                          className="right3"
                          icon={faChartBar}
                          color="#fff"
                        />
                        Activity history
                      </CardTitle>
                    </div>
                    <div className="right">
                      <ButtonToolbar>
                        <ButtonGroup>
                          <Button
                            className={dailyBtnClass}
                            onClick={() => setShowDailyData(true)}
                          >
                            Daily
                          </Button>{" "}
                          <Button
                            className={monthlyBtnClass}
                            onClick={() => setShowDailyData(false)}
                          >
                            Monthly
                          </Button>
                        </ButtonGroup>
                      </ButtonToolbar>
                    </div>
                  </CardHeader>
                  <CardBody className="body-consumptions">
                    <Row>
                      <Col lg="6" md="6" sm="12">
                        <Card className="card-chart custom-card-chart">
                          <CardHeader>
                            <CardTitle tag="h4">
                              <i className="tim-icons icon-key-25 text-primary" />{" "}
                              Secrets
                            </CardTitle>
                          </CardHeader>
                          <CardBody>
                            <div className="chart-area">
                              <Line
                                data={
                                  showDailyData
                                    ? dailySecretsChartData.data
                                    : monthlySecretsChartData.data
                                }
                                options={
                                  showDailyData
                                    ? dailySecretsChartData.options
                                    : monthlySecretsChartData.options
                                }
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="6" md="6" sm="12">
                        <Card className="card-chart custom-card-chart">
                          <CardHeader>
                            <CardTitle tag="h4">
                              <i className="far fa-save text-primary"></i> Bytes
                            </CardTitle>
                          </CardHeader>
                          <CardBody>
                            <div className="chart-area">
                              <Line
                                data={
                                  showDailyData
                                    ? dailyBytesChartData.data
                                    : monthlyBytesChartData.data
                                }
                                options={
                                  showDailyData
                                    ? dailyBytesChartData.options
                                    : monthlyBytesChartData.options
                                }
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <CustomWidgetInfo
                title="Secrets"
                value={(data && data.counters.totalSecretsCreated) || "--"}
                subTitle="Total secrets created"
                state="success"
              />
              <CustomWidgetInfo
                title="Secrets"
                value={
                  data &&
                  data.counters &&
                  (data.counters.totalSecretsSharePassed ||
                    !data.counters.totalSecretsSharePassed)
                    ? data.counters.totalSecretsSharePassed
                    : "--"
                }
                subTitle="Total secrets SharePassed"
                state="info"
              />
              <CustomWidgetInfo
                title="Data"
                value={(data && data.counters.totalDataCreated) || "--"}
                subTitle="Total data created"
                state="danger"
              />
              <CustomWidgetInfo
                title="Data"
                value={(data && data.counters.totalDataSharePassed) || "--"}
                subTitle="Total data SharePassed"
                state="warning"
              />
            </Row>
          </>
        </div>
        <div className="shadow-bottom"></div>
      </div>
    </>
  );
};

/**
 * Environment override file if exists
 */

const tryRequire = () => {
  try {
    const env = FrontendConfig.env;
    return require("../envs/" + env + "/views/Dashboard");
  } catch (err) {
    return null;
  }
};

const Dashboard = tryRequire() ? tryRequire().default : DashboardDefault;

export default Dashboard;
