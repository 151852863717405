import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, Label } from "reactstrap";
import useAPIError from "../../commons/hooks/useAPIError";
import { colors } from "../../constants/newSecret";
import { Context } from "../../context/auth/Context";
import { types } from "../../context/types";
import { fetchNewLabel } from "../../services/settings";
import { fetchAdminNewLabel } from "../../services/adminServices";
import { CustomErrorField } from "./CustomErrorField";
import { CustomSelectFormik } from "./CustomSelectFormik";

export const NewLabelForm = ({ toggle, admin }: any) => {
  // Notify
  const { addNotify } = useAPIError();
  const history = useHistory();

  // Get name from context
  const { dispatch } = useContext(Context);

  return (
    <Formik
      initialValues={{ name: "", color: "" }}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = "Required";
        } else {
          if (!/^[a-zA-Z0-9-_ ()]{1,30}$/.test(values.name))
            errors.name = "Please, enter a valid name";
        }

        if (!values.color) {
          errors.color = "Required";
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const body = {
          codeName: values.name,
          color: values.color,
        };

        const { status, message, redirect } = admin? await fetchAdminNewLabel(body) : await fetchNewLabel(body);

        //setSubmitting(false);
        resetForm();

        if (redirect) {
          dispatch({
            type: types.logout,
          });
          history.push("/auth/login");
        } else if (status === "Success") {
          addNotify("The label was successfully created", "success");
        } else {
          addNotify(message, "danger");
        }

        // Close modal
        toggle();
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="form-formik">
          <div className="new-label">
            <Label>Name</Label>
            <FormGroup>
              <Field type="text" name="name" />
              <ErrorMessage name="name" component={CustomErrorField} />
            </FormGroup>
          </div>
          <div className="new-label">
            <Label>Color</Label>
            <FormGroup>
              <CustomSelectFormik
                placeholder="Choose color"
                options={colors}
                value={values.color}
                onChange={(value: any) => setFieldValue("color", value.value)}
              />
              <ErrorMessage name="color" component={CustomErrorField} />
            </FormGroup>
          </div>
          <hr />
          <button
            type="submit"
            disabled={isSubmitting}
            className="btn btn-primary"
          >
            Create
          </button>
        </Form>
      )}
    </Formik>
  );
};
