import { ErrorMessage, Field } from "formik";
import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { CustomErrorField } from "./CustomErrorField";

export const InputMFA = () => {
  return (
    <Row>
      <Col lg="12" md="12" sm="12" xs="12">
        <div className="top10">
          <Label>Enter your password to view MFA settings</Label>
          <FormGroup>
            <Field type="password" name="mfapassword" placeholder="********" />
          </FormGroup>
          <ErrorMessage name="mfapassword" component={CustomErrorField} />
        </div>
      </Col>
    </Row>
  );
};
