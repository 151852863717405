import { URL_USER_REFRESH_TOKEN } from "../constants/urls";

export const isLoggedIn = async () => {
  let flag = false;
  let newToken = "";

  const refreshToken = localStorage.getItem("refreshToken");
  const expiresIn = localStorage.getItem("expiresIn");
  const userId = localStorage.getItem("userId");
  //console.log(expiresIn , expiresIn - 100000 , Date.now());
  if (expiresIn && expiresIn - 100000 > Date.now()) {
    flag = true;
  } else if (refreshToken && userId) {
    try {
      const res = await fetch(URL_USER_REFRESH_TOKEN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken: refreshToken, username: userId }),
      });

      const responseJSON = await res.json();

      if (responseJSON && responseJSON.status === "Success") {
        localStorage.setItem(
          "token",
          responseJSON.details.AuthenticationResult.AccessToken
        );
        localStorage.setItem(
          "expiresIn",
          responseJSON.details.AuthenticationResult.ExpiresIn * 1000 +
            Date.now()
        );

        newToken = responseJSON.details.AuthenticationResult.AccessToken;
        flag = true;
      } else {
        flag = false;
      }
    } catch (error) {
      console.log("Error when trying to create a secret", error);
      return error;
    }
  }

  return { isLogged: flag, newToken };
};

const chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";

// Use a lookup table to find the index.
let lookup = new Uint8Array(256);
for (let i = 0; i < chars.length; i++) {
  lookup[chars.charCodeAt(i)] = i;
}

export const b64encode = function (arraybuffer) {
  var bytes = new Uint8Array(arraybuffer),
    i,
    len = bytes.length,
    base64 = "";

  for (i = 0; i < len; i += 3) {
    base64 += chars[bytes[i] >> 2];
    base64 += chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
    base64 += chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
    base64 += chars[bytes[i + 2] & 63];
  }

  if (len % 3 === 2) {
    base64 = base64.substring(0, base64.length - 1);
  } else if (len % 3 === 1) {
    base64 = base64.substring(0, base64.length - 2);
  }

  return base64;
};

export const b64decode = function (base64) {
  var bufferLength = base64.length * 0.75,
    len = base64.length,
    i,
    p = 0,
    encoded1,
    encoded2,
    encoded3,
    encoded4;

  var arraybuffer = new ArrayBuffer(bufferLength),
    bytes = new Uint8Array(arraybuffer);

  for (i = 0; i < len; i += 4) {
    encoded1 = lookup[base64.charCodeAt(i)];
    encoded2 = lookup[base64.charCodeAt(i + 1)];
    encoded3 = lookup[base64.charCodeAt(i + 2)];
    encoded4 = lookup[base64.charCodeAt(i + 3)];

    bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
    bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
    bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
  }

  return arraybuffer;
};

export const dec2hex = (dec) => {
  return ("0" + dec.toString(16)).substr(-2);
};

export const generateRandomString = () => {
  var array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join("");
};

export const sha256 = (plain) => {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
};

export const challenge_from_verifier = async function (v) {
  const hashed = await sha256(v);
  const base64encoded = b64encode(hashed);
  return base64encoded;
};
