import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const CustomItemPrice = ({ title, value }: any) => {
  return (
    <div className="custom-sub-item">
      <FontAwesomeIcon
        className="check-item"
        icon={true ? faCheckCircle : faTimesCircle}
        color={true ? "#00f2c3" : "#6f6f73"}
      />
      <div className="title-bold">
        {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
      </div>
      <div className="item-sub-title"> {title}</div>
    </div>
  );
};
