import React from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";

interface InfoProps {
  title: string;
  subTitle: string;
  value: string;
  state: string;
  lg?:string;
  md?:string;
  icon?: string;
}

export const CustomWidgetInfo = ({
  title,
  subTitle,
  value,
  state,
  lg,
  md,
  icon
}: InfoProps) => {

  let mdT = md ? md : 6;
  let lgT = lg ? lg : 3;
  let iconT = icon ? icon : "tim-icons icon-key-25";
  return (
    <Col lg={lgT} md={mdT}>
      <Card className="card-stats">
        <CardBody>
          <Row>
            <Col xs="4">
              <div className={`info-icon text-center icon-${state}`}>
                <i className={iconT} />
              </div>
            </Col>
            <Col xs="8">
              <div className="numbers">
                <p className="card-category">{title}</p>
                <CardTitle tag="h3">{value}</CardTitle>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">{subTitle}</div>
        </CardFooter>
      </Card>
    </Col>
  );
};
