import React, { useContext, useRef, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import dark from "../../assets/img/dark_small.png";
import light from "../../assets/img/light_small.png";
import useAPIError from "../../commons/hooks/useAPIError";
import { Context } from "../../context/auth/Context";
import { DataContext } from "../../context/data/DataContext";
import { dataTypes, types } from "../../context/types";
import FrontendConfig from "../../assets/js/config";
import { fetchUpdateGeneralSettings } from "../../services/services";

/**
 * Environment override file if exists
 */


const tryRequire = () => {
  try {
    const env = FrontendConfig.env;
    return require("../../envs/" + env + "/views/components/DarkLightMode");
  } catch (err) {
    return null;
  }
}

export const DarkLightMode = tryRequire() ? tryRequire().DarkLightMode  : ({ tabName }: any) => {
  const {
    dispatch,
    user: { lightTheme },
  } = useContext(Context);

  // Context for profile
  const { profile, dispatch2 } = useContext(DataContext);

  let ref = useRef(null);

  // Notify
  const { addNotify } = useAPIError();

  const [lightMode, setLightMode] = useState(lightTheme);
  const [isLoading, setIsLoading] = useState(false);
  const handleActiveMode = async (isDark: any = null) => {
    
    if(!isLoading && (isDark == null || isDark === lightTheme)){
      setIsLoading(true);
      setLightMode(!lightMode);

      const { status } = await fetchUpdateGeneralSettings({
        lightTheme: !lightMode,
      });

      if (status === "Success") {
        dispatch({
          type: types.lightTheme,
          payload: {
            lightTheme: !lightMode,
          },
        });

        dispatch2({
          type: dataTypes.profile,
          payload: {
            ...profile,
            profileTabName: tabName,
          },
        });

        addNotify("Theme mode changed successfully", "success");
      } else {
        addNotify(
          "An error was ocurred when trying to change the theme",
          "danger"
        );
      }

      setIsLoading(false);
    }
  };

  return (
    <div className="theme row">
      <div className=" col-sm-6 col-md-6 col-lg-6 text-center">
        <img src={dark} onClick={() => {handleActiveMode(true)}} alt="dark-mode" className={!lightTheme? "theme-mode-preview theme-mode-selected" : "theme-mode-preview"} />
        <FormGroup check className="text-center form-check-radio">
          <Label check>
            <Input
              defaultValue="option1"
              id="exampleRadios1"
              name="exampleRadios"
              type="radio"
              onChange={() => handleActiveMode()}
              disabled={isLoading}
              checked={!lightTheme}
            />
            <span className="form-check-sign" />
            Dark
          </Label>
        </FormGroup>
      </div>
      <div className=" col-sm-6 col-md-6 col-lg-6 text-center">
        <img src={light} onClick={() => {handleActiveMode(false)}} alt="light-mode" className={lightTheme? "theme-mode-preview theme-mode-selected" : "theme-mode-preview"} />
        <FormGroup check className="text-center form-check-radio">
          <Label check>
            <Input
              defaultValue="option2"
              id="exampleRadios2"
              name="exampleRadios"
              type="radio"
              onChange={() => handleActiveMode()}
              disabled={isLoading}
              checked={lightTheme}
            />
            <span className="form-check-sign" />
            Light
          </Label>
        </FormGroup>
      </div>
    </div>
  );
};
