import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import FrontendConfig from "../../../../assets/js/config";
import useAPIError from "../../../../commons/hooks/useAPIError";
import { Context } from "../../../../context/auth/Context";
import { DataContext } from "../../../../context/data/DataContext";
import { dataTypes, types } from "../../../../context/types";
import {
  fetchLogin,
  fetchLoginMFA,
  fetchLoginWebauthn,
} from "../../../../services/auth";
import {
  b64decode,
  b64encode,
  challenge_from_verifier,
  generateRandomString,
} from "../../../../services/common";
import {
  fetchResendVerifyAccount,
  fetchUserProfile,
} from "../../../../services/services";
import InputPIN from "../../../../views/components/InputPIN";
import SSOButton from "../../../../views/components/SSOButton";
import VideoWrapper from "../../../../views/components/VideoWrapper";

const Login = () => {
  const history = useHistory();
  
  /**
   * If the login page has been opened from the mobile app we need to add a localstorage flag.
   */
  if(history.location.search.includes("isMobile")){
    localStorage.setItem("isMobile", "true");
  }

  // Notify
  const { addNotify } = useAPIError();
  // Login
  const { dispatch } = useContext(Context);
  const { dispatch2 } = useContext(DataContext);

  const [isWebApp, setIsWebApp] = useState(true);
  /*useEffect(() => {
    setIsWebApp(
      window.location.hostname.length <= 25 
    );
  }, []);*/

  const [state, setState] = useState({});
  useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const handleNewAccount = (e) => {
    e.preventDefault();
    history.push("/auth/register");
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    setRetry(false);
    setLoggin({ isLogged: login.isLogged, message: null });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    history.push("/auth/forgot-password");
  };

  const [dataForm, setDataForm] = useState({
    email: "",
    password: "",
  });

  const validateInput = (e) => {
    const { id, value } = e.target;

    switch (id) {
      case "email":
        setDataForm((prevForm) => ({
          ...prevForm,
          email: value,
        }));
        break;
      case "password":
        setDataForm((prevForm) => ({
          ...prevForm,
          password: value,
        }));
        break;
      default:
        break;
    }
  };

  const [retry, setRetry] = useState(false);
  const [requestMFA, setRequestMFA] = useState({
    request: false,
    message: null,
    friendlyDevice: null,
    session: null,
  });
  const [login, setLoggin] = useState({ isLogged: false, message: null });
  const [loading, setLoading] = useState(false);
  const [loadingWebauthn, setLoadingWebauthn] = useState(false);

  const [error, setError] = useState({ email: false, password: false });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (login && (login.message === "User is not confirmed." || retry)) {
      setRetry(true);
      const res = await fetchResendVerifyAccount({ email: dataForm.email });

      if (res.status === "Success") {
        setLoggin((prevLogin) => ({
          ...prevLogin,
          message: res.message,
          status: true,
        }));
      } else if (res.errorType === "LimitExceededException") {
        setLoggin((prevLogin) => ({
          ...prevLogin,
          message: res.errorMessage,
        }));
      } else if (res.status === "Forbidden") {
        setLoggin({
          isLogged: false,
          message: res.message,
        });
      }

      setLoading(false);
    } else {
      const lastPath = localStorage.getItem("lastPath") || "/admin/dashboard";
      const { email, password } = dataForm;

      const body = {
        email,
        password,
      };

      const {
        isLogged,
        message,
        token,
        expiresIn,
        idToken,
        refreshToken,
        session,
        ChallengeName,
        ChallengeParameters,
        friendlyDevice,
        sessionToken,
      } = await fetchLogin(body);

      if (!isLogged) {
        setLoggin({ isLogged, message });
        setDataForm({
          email: email,
          password: "",
        });
      } else {
        // Fetch details
        if (session && ChallengeName === "CUSTOM_CHALLENGE") {
          let challengeAllowedCreds = JSON.parse(
            ChallengeParameters.allowedCredentials
          );

          let allowedCreds = [];
          let isLegacy = false;

          for (let i = 0, size = challengeAllowedCreds.length; i < size; i++) {
            if (challengeAllowedCreds[i]["legacy"]) {
              isLegacy = true;
            }

            allowedCreds.push({
              id: b64decode(challengeAllowedCreds[i]["id"]),
              type: "public-key",
              transports: challengeAllowedCreds[i]["transports"],
            });
          }
          //----------get creds from security key or platform authenticator
          const signinOptions = {
            challenge: b64decode(ChallengeParameters.challenge),
            timeout: 1800000,
            rpId: isLegacy ? window.location.hostname : FrontendConfig.rp_id,
            userVerification: "discouraged",
            allowCredentials: allowedCreds,
          };

          try {
            //get sign in credentials from authenticator
            //console.log(this.navigator);
            const cred = await navigator.credentials.get({
              publicKey: signinOptions,
            });

            //prepare credentials challenge response
            const credential = {};
            if (cred.response) {
              const clientDataJSON = b64encode(cred.response.clientDataJSON);
              const authenticatorData = b64encode(
                cred.response.authenticatorData
              );
              const signature = b64encode(cred.response.signature);
              const userHandle = b64encode(cred.response.userHandle);

              credential.response = {
                clientDataJSON,
                authenticatorData,
                signature,
                userHandle,
              };

              const bodyWebauthn = {
                credId: cred.id,
                session: session,
                authenticatorData: authenticatorData,
                clientDataJSON: clientDataJSON,
                signature: signature,
                username: ChallengeParameters.USERNAME,
              };

              setLoadingWebauthn(true);

              const { token, expiresIn, idToken, refreshToken, sessionToken } =
                await fetchLoginWebauthn(bodyWebauthn);

              // Fetch profile
              const { redirect, details } = await fetchUserProfile(token);

              setLoadingWebauthn(false);

              setStorageAndRedirect(
                details,
                expiresIn,
                idToken,
                lastPath,
                redirect,
                refreshToken,
                token,
                sessionToken
              );
            } else {
              throw new Error(
                "There was an error trying to authenticate you using this key."
              );
            }
          } catch (e) {
            setLoading(false);
            setLoggin({ isLogged: false, message: e.message });
          }
        } else {
          if (session) {
            setRequestMFA({
              request: true,
              message: null,
              friendlyDevice: friendlyDevice,
              session: session,
            });
          } else {
            // Fetch profile
            const { redirect, details } = await fetchUserProfile(token);

            setStorageAndRedirect(
              details,
              expiresIn,
              idToken,
              lastPath,
              redirect,
              refreshToken,
              token,
              sessionToken
            );
          }
        }
      }

      setLoading(false);
    }
  };

  const handleSubmitMFA = async (pin, cleanUp) => {
    setLoading(true);

    const lastPath = localStorage.getItem("lastPath") || "/admin/dashboard";
    const { email } = dataForm;

    const body = {
      session: requestMFA.session,
      email: email,
      mfaCode: pin,
    };

    const {
      isLogged,
      message,
      token,
      expiresIn,
      idToken,
      refreshToken,
      sessionToken,
    } = await fetchLoginMFA(body);

    if (!isLogged) {
      if (message !== "Invalid code received for user") {
        setDataForm({
          email: "",
          password: "",
        });

        setRequestMFA({
          request: false,
          message: null,
          friendlyDevice: null,
          session: null,
        });
      }

      setLoggin({ isLogged, message });
      cleanUp(false);
      setLoading(false);
    } else {
      // Fetch profile
      const { redirect, details } = await fetchUserProfile(token);

      setLoading(false);

      setStorageAndRedirect(
        details,
        expiresIn,
        idToken,
        lastPath,
        redirect,
        refreshToken,
        token,
        sessionToken
      );

      return;
    }
    setLoading(false);
  };

  const setStorageAndRedirect = (
    details,
    expiresIn,
    idToken,
    lastPath,
    redirect,
    refreshToken,
    token,
    sessionToken
  ) => {
    if (!redirect) {
      const {
        consumption,
        counters,
        email,
        lastname,
        lightTheme,
        sidebarToggle,
        advancedMode,
        name,
        revealMessage,
        sso,
        subscription,
        userId,
        isAdmin,
        welcomeScreen,
        publicKeysCred,
        webhook,
        defaultSecretTemplate,
      } = details;

      if (localStorage.getItem("isMobile")) {
        window.location = `sharepassapp://app/dashboard/${sessionToken}`;
      } else {
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("expiresIn", expiresIn * 1000 + Date.now());
        localStorage.setItem("userId", userId);
        localStorage.setItem("idToken", idToken);

        dispatch2({
          type: dataTypes.profile,
          payload: {
            consumption,
            counters,
            revealMessage,
            sso,
            subscription,
            welcomeScreen,
            publicKeysCred,
            webhook,
            defaultSecretTemplate,
          },
        });

        dispatch({
          type: types.login,
          payload: {
            name,
            lastName: lastname,
            email,
            userDetails: { name, lastname, email },
            lightTheme,
            isAdmin,
            sidebarToggle,
            advancedMode,
          },
        });
      }

      //history.push(lastPath);
    }
  };

  const handleSSO = async (idp) => {
    const idpURL = encodeURIComponent(idp);
    const redirect = encodeURIComponent(FrontendConfig.sso.redirect);
    const cid = encodeURIComponent(FrontendConfig.sso.cid);
    const type = encodeURIComponent(FrontendConfig.sso.type);
    const scope = encodeURIComponent(FrontendConfig.sso.scope);
    const state = encodeURIComponent(generateRandomString());
    const verifier = generateRandomString();
    const code_challenge = encodeURIComponent(
      await challenge_from_verifier(verifier)
    );

    localStorage.setItem("idp", idp);
    localStorage.setItem("verifier", verifier);

    const auxVerifier = localStorage.getItem("verifier");

    window.location.assign(
      `${FrontendConfig.auth_url}/oauth2/authorize?identity_provider=${idpURL}&redirect_uri=${redirect}&response_type=${type}&client_id=${cid}&scope=${scope}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=S256`
    );
  };

  if (isWebApp) {
    return (
      <>
        {loadingWebauthn && (
          <>
            <ReactBSAlert
              style={{ display: "block", marginTop: "-100px" }}
              showCancel={false}
              showConfirm={false}
            >
              <p className="display-3">Please wait...</p>
            </ReactBSAlert>
          </>
        )}
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6" sm="8" xs="10">
              <Form className="form" onSubmit={handleSubmit}>
                <Card className="card-login card-white">
                  <CardHeader
                    className={`${
                      isWebApp ? "card-header-sp" : "card-header-sp-extension"
                    }`}
                  >
                    <img
                      className="logo-login"
                      alt="..."
                      src={require("../../assets/img/ymtech.png").default}
                    />
                  </CardHeader>
                  

                  {requestMFA &&
                    requestMFA.request === true &&
                    login &&
                    login.message !== "User is not confirmed." &&
                    !retry && (
                      <InputPIN
                        requestMFA={requestMFA}
                        handleSubmitMFA={handleSubmitMFA}
                      />
                    )}
                  {login && (
                    <p className={`error-p ${login.status ? "p-info" : ""}`}>
                      {login.message}
                    </p>
                  )}
                  <CardFooter>
                    {login &&
                      login.message !== "User is not confirmed." &&
                      !retry && (
                        <>
                          <hr />
                          <SSOButton
                            handleSSO={() => {handleSSO("Google")}}
                            icon={faGoogle}
                            loading={loading}
                            color="danger"
                            text="Sign In with "
                          />
                        </>
                      )}

                    {isWebApp && (
                      <div>
                        <hr />
                        <Row className="mb-1">
                          <Col sm="12">
                            <div className="text-center">
                              <FontAwesomeIcon
                                icon={faUserSecret}
                                className="text-center fa-2x"
                              />
                            </div>
                            <div className="text-center">
                              <small className="text-center">
                                To strengthen the security it is recommended to
                                use the browser in private/incognito mode.
                              </small>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                    
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="content-extension">
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6" sm="8" xs="10">
              <Card className="card-login card-white">
                <CardHeader className="card-header-sp-extension">
                  <img
                    className="logo-login"
                    alt="..."
                    src={require("../../assets/img/ymtech.png").default}
                  />
                </CardHeader>
                <CardBody>
                  <h3 className="text-center">SharePass for Chrome</h3>

                  <div className="custom-sub-item">
                    <div className="title-bold">
                      <i className="fas fa-check-circle text-primary"></i> Share
                      sensitive data securely{" "}
                    </div>
                  </div>
                  <div className="custom-sub-item">
                    <div className="title-bold">
                      <i className="fas fa-check-circle text-primary"></i>{" "}
                      Available in all websites{" "}
                    </div>
                  </div>
                  <div className="custom-sub-item">
                    <div className="title-bold">
                      <i className="fas fa-check-circle text-primary"></i> Easy
                      to use{" "}
                    </div>
                  </div>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="pull-left">
                    <h6>
                      <a
                        href={`${FrontendConfig.current_url}/auth/register`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Create Account
                      </a>
                    </h6>
                  </div>
                  <div className="pull-right">
                    <h6>
                      <a
                        href={`${FrontendConfig.current_url}/auth/login`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Sign in
                      </a>
                    </h6>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Container>
        </div>
      </>
    );
  }
};

export default Login;
