import { faChartBar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Line } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledAlert
} from "reactstrap";
import { CustomDashboardAlert } from "../../components/Customs/CustomDashboardAlert";
import { CustomWidgetInfo } from "../../components/Customs/CustomWidgetInfo";
import { WidgetConsumes } from "../../components/Customs/WidgetConsumes";
import { WidgetSubscription } from "../../components/Customs/WidgetSubscription";
import { chartOptions } from "../../constants/dashboard";
import { Context } from "../../context/auth/Context";
import { DataContext } from "../../context/data/DataContext";
import { dataTypes, types } from "../../context/types";
import {
  fetchPutWelcomescreen,
  fetchSetSubscription,
  fetchUserProfile,
  fetchGlobalCounters,
} from "../../services/services";
import {
  parseConsumptionsDaily,
  parseConsumptionsMonthly,
  parseData
} from "../../utils/dashboard";
import { CustomModalWelcome } from "../components/CustomModalWelcome";

const AdminDashboard = (props) => {  

  const history = useHistory();
  // Context
  const { dispatch } = useContext(Context);

  const [data, setData] = useState();

  
  useEffect(() => {
    
    const getGlobalCounters = async () => {
      
      const { details, redirect } = await fetchGlobalCounters();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (details) {
        setData(details);
      }
      
    };

    if (!data) {
      getGlobalCounters();
    }
  }, [data]);

  return (
    <>
      <div className="content">
        
        <div className="shadow-top"></div>
        <div
          className={""/*`content-scroll content-scroll-notify`*/}
        >
          <br />
            <>
              <Row>
                <CustomWidgetInfo
                  icon="tim-icons icon-single-02"
                  title="Users"
                  value={(data && data.totalUsers) || "--"}
                  subTitle="Total users sharepassing"
                  state="success"
                  lg="4"
                  md="4"
                />
                <CustomWidgetInfo
                  title="Secrets"
                  value={
                    data &&
                    data.totalSecretsSharePassed
                      ? data.totalSecretsSharePassed
                      : "--"
                  }
                  subTitle="Total secrets SharePassed"
                  state="info"
                  lg="4"
                  md="4"
                />
                <CustomWidgetInfo
                  icon="tim-icons icon-send"
                  title="Data"
                  value={(data && data.totalDataSharePassed) || "--"}
                  subTitle="Total data SharePassed"
                  state="danger"
                  lg="4"
                  md="4"
                />
              </Row>
            </>
        </div>
        <div className="shadow-bottom"></div>
      </div>
    </>
  );
};

export default AdminDashboard;
