import {
  URL_ADMIN_TEMPLATES,
  URL_ADMIN_TEMPLATES_LIST,
  URL_ADMIN_TEMPLATES_RECEIVE,
  URL_ADMIN_TEMPLATES_RECEIVE_LIST,
  URL_ADMIN_LABEL_LIST,
  URL_ADMIN_LABEL,
  URL_ADMIN_SECURITY_KEY_CREATE,
  URL_ADMIN_SECURITY_KEY_PARSE,
  URL_ADMIN_SECURITY_KEY_DELETE,
  URL_ADMIN_SECURITY_KEY_LIST,
} from "../constants/urls";
import { isLoggedIn } from "./common";


export const fetchAdminNewTemplate = async (body, purpose) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    if (body.details.otl) {
      body.details.lockAfterUse = false;
    }
    console.log("PURPOSE", purpose);
    const token = localStorage.getItem("token");
    return await fetch(purpose === 1 ? URL_ADMIN_TEMPLATES_RECEIVE : URL_ADMIN_TEMPLATES, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to update a secret", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchAdminTemplates = async (purpose) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(purpose === 1 ? URL_ADMIN_TEMPLATES_RECEIVE_LIST : URL_ADMIN_TEMPLATES_LIST, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to get templates", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchAdminUpdateTemplate = async (body, purpose) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    if (
      body.updateAttributes &&
      body.updateAttributes.details &&
      body.updateAttributes.details.otl
    ) {
      body.updateAttributes.details.lockAfterUse = false;
    }

    const token = localStorage.getItem("token");
    return await fetch(purpose === 1 ? URL_ADMIN_TEMPLATES_RECEIVE : URL_ADMIN_TEMPLATES, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to delete a template", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchAdminDeleteTemplate = async (body, purpose) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(purpose === 1 ? URL_ADMIN_TEMPLATES_RECEIVE : URL_ADMIN_TEMPLATES, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to delete a template", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchAdminNewLabel = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_ADMIN_LABEL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("Error when trying to create a new label", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchAdminLabels = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_ADMIN_LABEL_LIST, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("Error when trying to create a new label", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchAdminDeleteLabel = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_ADMIN_LABEL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to delete a label", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchAdminNewSecretCredentialRemoteRequest = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_ADMIN_SECURITY_KEY_CREATE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(
          "Error when trying to create a new secret public credential",
          error
        );
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchAdminNewSecretCredentialParseAdd = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_ADMIN_SECURITY_KEY_PARSE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(
          "Error when trying to create a new secret public credential",
          error
        );
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchAdminNewSecretCredentialRequest = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_ADMIN_SECURITY_KEY_CREATE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(
          "Error when trying to create a new public key request object",
          error
        );
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchAdminSecretCredentials = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_ADMIN_SECURITY_KEY_LIST, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("Error when trying to get all secret credentials", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchAdminDeleteSecretCredential = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_ADMIN_SECURITY_KEY_DELETE, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to delete a secret credential", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};