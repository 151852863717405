import React from "react";
import { Spinner } from "reactstrap";

export const CustomSpinner = () => {
  return (
    <div className="center-sp">
      <Spinner animation="border" variant="primary" />
    </div>
  );
};
