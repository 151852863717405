/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useState } from "react";
// react plugin used to create a form with multiple steps
import ReactWizard from "react-bootstrap-wizard";
import { useHistory } from "react-router-dom";
// reactstrap components
import { Col } from "reactstrap";
import { CustomEndData } from "../../commons/models/models";
import { Context } from "../../context/auth/Context";
import { types } from "../../context/types";
import { fetchNewSecret, fetchFileUploadURL, fetchUploadFileToS3  } from "../../services/services";
import { createBodyRequest } from "../../utils/secret.js";
// wizard steps
import Step1 from "./WizardSteps/Step1";
import Step2 from "./WizardSteps/Step2";
import Step3 from "./WizardSteps/Step3";
import FrontendConfig from "../../assets/js/config";
import ReactBSAlert from "react-bootstrap-sweetalert";

const LiteNewSecretFormDefault = ({ defaultSecretTemplate }: any) => {
  const history = useHistory();
  // Get name from context
  const { dispatch } = useContext(Context);

  const [finishButtonClasses, setFinishButtonClasses] =
    useState("btn-wd btn-primary");
  const [finishButtonText, setFinishButtonText] = useState("Finish");
  const [previousButtonClasses, setPreviousButtonClasses] = useState("btn-wd");

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading file...");

  const readFile = async function (fileData: any) {
    let result = await new Promise((resolve) => {

        const reader = new FileReader()

        reader.onabort = (err) => {
          setIsLoading(false);
          console.log('file reading was aborted', err);
        }

        reader.onerror = (err) => {
          setIsLoading(false);
          console.log('file reading has failed', err);
        }

        reader.onload = () => {
          // Do whatever you want with the file contents
          console.log("Data set");
          resolve(reader.result);
        }

        reader.readAsDataURL(fileData);
    });

    return result;
  }

  let steps = [
    {
      stepName: "Type",
      stepIcon: "tim-icons icon-bullet-list-67",
      component: Step1,
    },
    {
      stepName: "Secret",
      stepIcon: "tim-icons icon-key-25",
      component: Step2,
    },
    {
      stepName: "Expiry",
      /*stepIcon: "tim-icons icon-time-alarm",*/
      stepIcon: "fa fa-clock",
      component: Step3,
      stepProps: {
        defaultSecretTemplate: defaultSecretTemplate,
      },
    },
  ];

  const finishButtonClick = async (allStates: any) => {
    /*
     * Generate secret
     */
    let secretContent;

    switch (allStates.secretType) {
      case "password":
        secretContent = allStates.password;
        break;
      case "message":
        secretContent = allStates.message;
        break;
      case "credentials":
        secretContent = {
          email: allStates.credentialEmail,
          password: allStates.credentialPassword,
          hostname: allStates.credentialHostname,
          f2a: allStates.credential2Fa,
        };
        break;
      case "QR":
        secretContent = allStates.qr;
        break;
      case "custom":
        secretContent = allStates.json;
        break;
      case "file":
          setIsLoading(true);
          secretContent = await readFile(allStates.file);

          //File is empty
          if(secretContent == 'data:'){
            history.push("/admin/secret/error", {
              data: "Your file is empty. Please, try again with a different one.",
            });

            return;
          }
          
          setLoadingText("Encrypting file content...");
          break;  
      default:
        break;
    }

    if (!secretContent) return;

    let endData: CustomEndData = {
      private: "",
      public: "",
      type: allStates.secretType,
      data: secretContent,
    };

    const { body, randomWord }: any = await createBodyRequest(
      endData,
      allStates.config
    );

    setFinishButtonText("PLEASE WAIT...");
    setPreviousButtonClasses("btn-wd disabled");
    setFinishButtonClasses("btn-wd btn-primary disabled");

    try {

      /**
      * If type file lets create pre-signed URL and upload the file
      */
      if(body.type == 'file'){
            
        let bodyFileUploadURL = {
          contentLength: body.secret.length,
          filename: allStates.file.name
        };

        setLoadingText("Uploading file...");

        const { status, details, message, redirect } = await fetchFileUploadURL(bodyFileUploadURL);

        if(status != "Success"){
          history.push("/admin/secret/error", {
            data: message,
          });

          return;
        }

        details["fields"]["x-amz-meta-userid"]    = localStorage.getItem("userId");
        details["fields"]["x-amz-meta-filename"]  = allStates.file.name;

        const response = await fetchUploadFileToS3(details, body.secret);

        //File was uploaded correctly, update secret value with file key
        if(response.status == 204){
          body.secret = details.fields.key;
        } else {
          history.push("/admin/secret/error", {
            data: response.message,
          });

          return;
        }
        setIsLoading(false);
      }

      const { status, details, message, redirect } = await fetchNewSecret(body);

      setFinishButtonText("Finish");
      setPreviousButtonClasses("btn-wd");
      setFinishButtonClasses("btn-wd btn-primary");

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        details.randomWord = randomWord;
        details.body = body;
        history.push("/admin/secret/success?ts=" + Date.now(), {
          data: details,
        });
      } else {
        history.push("/admin/secret/error", {
          data: message,
        });
      }
    } catch (e) {
      setFinishButtonText("Finish");
      setPreviousButtonClasses("btn-wd");
      setFinishButtonClasses("btn-wd btn-primary");
    }
  };

  return (
    <>
    {isLoading && (
      
      <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          showCancel={false}
          showConfirm={false} title={""} onConfirm={function (response?: any) {
            throw new Error("Function not implemented.");
          } }        >
        <p className="display-3">{loadingText}</p>
      </ReactBSAlert>
      
    )}
      <div className="content">
        <Col className="mr-auto ml-auto" md="10">
          <ReactWizard
            steps={steps}
            navSteps
            validate
            title="New secret wizard"
            description="Share your secrets in 3 easy steps."
            finishButtonClick={finishButtonClick}
            headerTextCenter
            finishButtonText={finishButtonText}
            finishButtonClasses={finishButtonClasses}
            nextButtonClasses="btn-wd btn-primary"
            previousButtonClasses={previousButtonClasses}
            progressbar
            color="primary"
          />
        </Col>
      </div>
    </>
  );
};

/**
 * Environment override file if exists
 */


const tryRequire = () => {
  try {
    const env = FrontendConfig.env;
    return require("../../envs/" + env + "/views/components/LiteNewSecretForm");
  } catch (err) {
    return null;
  }
}

 const LiteNewSecretForm = tryRequire() ? tryRequire().default : LiteNewSecretFormDefault;

export default LiteNewSecretForm;
