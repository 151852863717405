import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import NewSecurityKeyLocal from "./NewSecurityKeyLocal";
import NewSecurityKeyMode from "./NewSecurityKeyMode";
import NewSecurityKeyRemote from "./NewSecurityKeyRemote";

const NewSecurityKey = () => {
  const [mode, setMode] = useState(""); //false for lite mode, true for advanced

  const renderModeComponent = () => {
    switch (mode) {
      case "local":
        return <NewSecurityKeyLocal setMode={setMode} />;
      case "remote":
        return <NewSecurityKeyRemote setMode={setMode} />;
      default:
        return <NewSecurityKeyMode setMode={setMode} />;
    }
  };

  return (
    <>
      <div className="content new-secret">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <div>
                  <Row>
                    <Col md="12">{renderModeComponent()}</Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NewSecurityKey;
