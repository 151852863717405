export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return !(re.test(String(email).toLowerCase()) && email.length <= 100);
};

export const validatePassword = (password) => {
  const re =
    /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/;
  return !re.test(String(password));
};

export const validateNameLastname = (string) => {
  return !(string.length <= 50);
};

export const validateVerificationCode = (code) => {
  //const re = /^[0-9]*$/;
  const re = /^[0-9]{6}$/;
  return !re.test(String(code));
};

export const isJsonString = (item) => {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }

  return false;
};

export const checkIpValue = (value) => {
  const subips = value.split(".");
  if (subips.length > 4) {
    return false;
  }
  const invalidSubips = subips.filter((ip) => {
    ip = parseInt(ip);
    return ip < 0 || ip > 255;
  });
  if (invalidSubips.length !== 0) {
    return false;
  }
  let emptyIpCount = 0;
  subips.forEach((ip) => {
    if (ip === "") {
      emptyIpCount++;
    }
  });
  if (emptyIpCount > 1) {
    return false;
  }
  return true;
};
