import React, { useRef, useState } from "react";
import ReactCodeInput from "react-code-input";
interface MFAProps {
  request: boolean;
  message?: string;
  friendlyDevice?: string;
  session?: string;
}
const InputPIN = ({
  requestMFA,
  handleSubmitMFA,
}: {
  requestMFA: MFAProps;
  handleSubmitMFA: any;
}) => {
  const [disabledPin, setDisabledPin] = useState(false);
  const codeRef = useRef(undefined);

  function functionCleanUp(bool: boolean) {
    setDisabledPin(bool);

    if (
      codeRef &&
      codeRef.current &&
      codeRef.current.textInput &&
      codeRef.current.textInput[0]
    ) {
      codeRef.current.textInput[0].focus();
    }

    if (codeRef.current && codeRef.current.state) {
      codeRef.current.state.input[0] = "";
      codeRef.current.state.input[1] = "";
      codeRef.current.state.input[2] = "";
      codeRef.current.state.input[3] = "";
      codeRef.current.state.input[4] = "";
      codeRef.current.state.input[5] = "";
    }
    let reactPINInput = document.getElementsByClassName("react-code-input");

    if (reactPINInput.length) {
      let inputsPIN = reactPINInput[0].getElementsByTagName("input");

      for (let i = 0; i < inputsPIN.length; i++) {
        if (i === 0) {
          inputsPIN[i].focus();
        }

        inputsPIN[i].value = "";
      }
    }
  }

  function onPinChange(pin: string) {
    if (pin.length === 6) {
      setDisabledPin(true);
      handleSubmitMFA(pin, functionCleanUp);
    }
  }

  let mfaAdvise = <></>;
  if (requestMFA.friendlyDevice !== "") {
    mfaAdvise = <strong>{requestMFA.friendlyDevice}</strong>;
  }

  return (
    <>
      <label className="text-center">Enter MFA code</label>
      <div className="mt-3"></div>
      <div className="text-center">
        <div className="form-group d-i-block">
          <ReactCodeInput
            ref={codeRef}
            disabled={disabledPin}
            onChange={onPinChange}
            type="text"
            fields={6}
          />
        </div>
      </div>
      <label className="text-center">Device name: {mfaAdvise}</label>
    </>
  );
};

export default InputPIN;
