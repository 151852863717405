
export const SharePassLoading = () => {
  return (
    <div className="covered">
      <div className="covered-inner">
        <div className="covered-inner-rings">
          <div className="covered-inner-rings">
            
          </div>
        </div>
      </div>
    </div>
  );
};
