import { Alert, UncontrolledTooltip } from "reactstrap";

interface Params {
  color: string;
  title: string;
  title2?: string;
  tooltip?: string;
}

export const CustomDashboardAlert = ({ color, title, title2, tooltip }: Params) => {
  return (
    <Alert className="text-center" color={color}>
      <p>
        <i id="exceeded-alert-icon"className="fas fa-exclamation-circle"></i>&nbsp;

        {tooltip && (<UncontrolledTooltip delay={0} target="exceeded-alert-icon" placement="right">
        {tooltip}
      </UncontrolledTooltip>)}
        <strong>{title}</strong>
      </p>
      <p>{title2}</p>
    </Alert>
  );
};
