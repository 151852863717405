import { faCog, faCopy, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import useAPIError from "../../commons/hooks/useAPIError";
import { URL_APP } from "../../constants/urls";
import { Context } from "../../context/auth/Context";
import { DataContext } from "../../context/data/DataContext";
import { types } from "../../context/types";
import {
  fetchCheckRequestCode, fetchLabels,
  fetchNewSecretCredentialRemoteRequest,
  fetchCheckExistence,
  fetchCancelEnrollment
} from "../../services/settings";
import { getColorName, parseClassLabels } from "../../utils/settings";
import { CustomErrorField } from "../components/CustomErrorField";
import { CustomModalLabel } from "../components/CustomModalLabel";
import ReactBSAlert from "react-bootstrap-sweetalert";
const NewSecurityKeyRemote = ({ setMode }: any) => {
  const history = useHistory();

  // Notify
  const { addNotify } = useAPIError();
  const { user, dispatch } = useContext(Context);

  // Context for profile
  const { profile, dispatch2 } = useContext(DataContext);

  /* Show label modal */
  const [modalClassic, setModalClassic] = useState(false);

  const [multipleSelect, setmultipleSelect] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);

  const [requestCode, setRequestCode] =
    useState({});

  const [existsRequest, setExistsRequest] = useState({});
    
  /*
   * Show alert before delete a credential
   */
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const warningWithConfirmMessage = (code: string, name: string) => {
    setShowAlert(true);
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          setAuxId(code);
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, proceed"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        Would you like to proceed to cancel the enrollment of the security key <strong>"{name}"</strong> with code <strong>"{code}"</strong>?<br />
        <small>Keep in mind that the code will become invalid, and you will need to generate a new one.</small>
      </ReactBSAlert>
    );
  };

  /*
   * Hide alert when delete a secret
   */
  const hideAlert = () => {
    setShowAlert(false);
    setAlert({});
  };

  /*
   * Delete a secret
   */
  const [auxId, setAuxId] = useState("");
  useEffect(() => {
    // Delete from the database
    const cancelEnrollment = async () => {
      const body = { code: auxId };

      const { status, redirect } = await fetchCancelEnrollment(body);

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        setAuxId("");
        setShowAlert(false);
        setRequestCode({});
        setAlert({});
      } else {
        addNotify("There was an error trying to cancel this enrollment", "danger");
      }
    };

    if (auxId !== "") {
      cancelEnrollment();
    }
  }, [auxId]);

  useEffect(() => {
    const checkExistence = async () => {
      const { status, details, redirect } = await fetchCheckExistence();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        setRequestCode(details);
        setExistsRequest({exists: true});
      } else {
        setExistsRequest({exists: false});
      }

    };

    checkExistence();
  }, []);
  /*
   * We use this condition to put only one label, if we want to add more labels
   * in the future, we will need to delete this condition.
   */
  useEffect(() => {
    if (multipleSelect.length > 1) {
      setmultipleSelect((prevValues) => [prevValues[1]]);
    }
  }, [multipleSelect]);

  const addNewLabel = () => {
    setModalClassic(!modalClassic);
  };

  /*
   * Listen close modal
   * After close modal (maybe succes or cancelled by the user) we need to update the array of labels
   * In the future, we will remove, and by replaced by hooks of redux or context.
   */
  useEffect(() => {
    const getLabels = async () => {
      const { status, details, redirect } = await fetchLabels();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        const auxLabels = parseClassLabels(details);
        setLabelOptions([
          {
            label: "Select an option",
            value: "Select an option",
            isDisabled: true,
          },
          ...auxLabels,
        ]);
      }
    };

    if (!modalClassic) getLabels();
  }, [modalClassic]);

  useEffect(() => {
    const interval = setInterval(() => {
      if(requestCode.code)
        resolveCheckCode(requestCode.code);
    }, 3000);
  
    return () => clearInterval(interval);
  }, [requestCode]);

  const resolveCheckCode = async (requestCode: any) => {
    
    try{

      if(requestCode){
        const { status, redirect } = await fetchCheckRequestCode({code: requestCode});

        if (redirect) {
          dispatch({
            type: types.logout,
          });
          history.push("/auth/login");
        } else if (status === "Success") {
          history.push("/admin/security-key/list");
          addNotify("Security key added successfully!", "success");
        }
      }
      
    }catch(e){
      console.log(e);
    }
    
  }

  return (
    <>
      {showAlert && alert}
      <h3>Remote security keys</h3>
      {existsRequest.hasOwnProperty("exists") ?
      (<Row>
        
        {requestCode && requestCode.code? (
          <Col lg="8" md="8" sm="12" xs="12">
            <p>
              Start a video call or a phone call with the security key's owner and ask it to go to the following URL:
            </p>
          <div className="mt-4 mb-4 text-primary">
            
              {URL_APP}/auth/enroll-security-key

              <FontAwesomeIcon
                icon={faCopy}
                className="left5 cursor-p"
                onClick={() => {
                  navigator.clipboard.writeText(`${URL_APP}/auth/enroll-security-key`);
                  addNotify("Link copied!", "success");
                }}
              />
          </div>
          <p>
            The security key's owner will need to use the following code to complete the enrollment process for the security key <b>"{requestCode.name}"</b>:
          </p>
          
          <h3>{requestCode.code}</h3>

          <Row>
            <Col md='12' className="mb-4">
              <div className="mt-2">
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                ></FontAwesomeIcon> Waiting for remote enrollment ...
              </div>
            </Col>  
          </Row>
          <Button
            className="btn-fill btn-sm"
            color="primary"
            type="button"
            onClick={() => {
              setMode("");
            }}
          >
            Back
          </Button>
          <Button
            className="btn-fill btn-sm"
            color="danger"
            type="button"
            onClick={() => {
              warningWithConfirmMessage(requestCode.code, requestCode.name);
            }}
          >
          Cancel
        </Button>
          </Col>
        ) : (
          
          <Col lg="8" md="8" sm="12" xs="12">
            <div>
        <p>
          Use the following form to add a security key (e.g. Yubikey), if the key is not physically with you.
        </p>
        
      </div>
            <Formik
              initialValues={{
                name: "",
              }}
              validate={(values) => {
                let errors: { name?: string } = {};
                const error = "This field is required";

                if (!values.name) {
                  errors.name = error;
                }

                if (values.name && values.name.length > 30) {
                  errors.name = "Use 30 chars max";
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                let endData: any = {
                  name: values.name,
                };

                if (
                  multipleSelect.length > 0 &&
                  multipleSelect[0].color &&
                  multipleSelect[0].codeName
                ) {
                  endData["label"] = {
                    codeName: multipleSelect[0].codeName,
                    color: getColorName(multipleSelect[0].color),
                  };
                }

                try {
                  const { status, details, message, redirect } =
                    await fetchNewSecretCredentialRemoteRequest(endData);

                  if (redirect) {
                    dispatch({
                      type: types.logout,
                    });
                    history.push("/auth/login");
                  } else if (status === "Success") {
                    setRequestCode({code:details.id,name: values.name});
                    setmultipleSelect([]);
                    resetForm();
                    addNotify("Security key request added!", "success");
                    
                  } else {
                    let customMessage =
                      "There was an error creating the credentials.";

                    if (message) {
                      customMessage = message;
                    }

                    addNotify(customMessage, "danger");
                  }

                  setSubmitting(false);
                } catch (error: any) {
                  console.log(error);
                  addNotify(error.message, "danger");
                }
              }}
            >
              {({ isSubmitting, resetForm }) => (
                <Form className="form-formik">
                  <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                      <Row>
                        <Col lg="6" md="6" sm="12" xs="12">
                          <Label>Key name <small>(visible for the recipient too)</small></Label>
                          <FormGroup>
                            <Field
                              type="text"
                              name="name"
                              disabled={isSubmitting}
                            />
                          </FormGroup>
                          <ErrorMessage
                            name="name"
                            component={CustomErrorField}
                          />
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12">
                          <FormGroup>
                            <div className="box-label">
                              {labelOptions.length <= 1 ? (
                                <Label>
                                  You don't have a label yet.{" "}
                                  <b
                                    className={"cursor-p"}
                                    onClick={addNewLabel}
                                  >
                                    Create one
                                  </b>
                                </Label>
                              ) : (
                                <div>
                                  <Label className="">Label</Label>
                                  <FontAwesomeIcon
                                    icon={faCog}
                                    className="left5 cursor-p"
                                    onClick={addNewLabel}
                                  />
                                </div>
                              )}
                            </div>
                            <CustomModalLabel
                              isOpen={modalClassic}
                              toggle={addNewLabel}
                            />
                            <Select
                              className={`react-select ${
                                multipleSelect[0]
                                  ? multipleSelect[0].color
                                  : "info"
                              }`}
                              classNamePrefix="react-select"
                              placeholder="Choose label"
                              name="multipleSelect"
                              closeMenuOnSelect
                              isMulti
                              value={multipleSelect}
                              onChange={(value) => setmultipleSelect(value)}
                              options={labelOptions}
                              isDisabled={
                                (labelOptions.length <= 1 && !multipleSelect) ||
                                isSubmitting
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        className="btn-fill btn-sm"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {!isSubmitting ? "New request" : "PLEASE WAIT..."}
                      </Button>

                      <Button
                        className="btn-fill btn-sm"
                        color="danger"
                        type="button"
                        onClick={() => {
                          setMode("");
                        }}
                      >
                        Cancel
                      </Button>
                      {!isSubmitting ? (
                        <></>
                      ) : (
                        <div className="mt-1">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="spinner"
                          ></FontAwesomeIcon>{" "}
                          This operation may take few seconds...
                        </div>
                      )}
                    </Col>
                    
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        )}
      </Row>) : 
      <Row>
        <Col md='12' className="text-center">
          <div className="mt-2">
            <FontAwesomeIcon
              icon={faSpinner}
              className="spinner"
            ></FontAwesomeIcon>
          </div>
        </Col>  
      </Row>}
    </>
  );
};

export default NewSecurityKeyRemote;
