import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavItem, NavLink } from "reactstrap";

interface InfoProps {
  pageTabs: string;
  changeActiveTab: any;
  tabName: string;
  icon: any;
  title: string;
  disable?: boolean;
  classes?: string;
}

export const NavItemSetting = ({
  pageTabs,
  changeActiveTab,
  tabName,
  icon,
  title,
  disable,
  classes,
}: InfoProps) => {
  return (
    <NavItem id={`${tabName}-tab-profile`} className={classes}>
      <NavLink
        data-toggle="tab"
        href="#"
        className={pageTabs === tabName ? "active setting-tab" : "setting-tab"}
        onClick={(e) => changeActiveTab(e, "pageTabs", tabName)}
        disabled={disable}
      >
        <div>
          <FontAwesomeIcon
            icon={icon}
            color={`${pageTabs === tabName ? "#fff" : ""}`}
            width="40px"
            className="font-awesome tim-icons"
          />
          <br />
          {title}
        </div>
      </NavLink>
    </NavItem>
  );
};
