import React from "react";
import { Label } from "reactstrap";

const CHECKBOX_STATES = {
  Checked: "Yes",
  Indeterminate: "No",
  Empty: "Any",
};

export const CustomCheckbox = ({ handleCheck, field, initialValue }: any) => {
  const [checked, setChecked] = React.useState(
    initialValue || CHECKBOX_STATES.Empty
  );

  const handleChange = () => {
    let updatedChecked;

    if (checked === CHECKBOX_STATES.Checked) {
      updatedChecked = CHECKBOX_STATES.Indeterminate;
    } else if (checked === CHECKBOX_STATES.Empty) {
      updatedChecked = CHECKBOX_STATES.Checked;
    } else if (checked === CHECKBOX_STATES.Indeterminate) {
      updatedChecked = CHECKBOX_STATES.Empty;
    }

    setChecked(updatedChecked);

    handleCheck(field, updatedChecked);
  };

  const Checkbox = ({ value, onChange }: any) => {
    const checkboxRef = React.useRef<any>();
    let text = "";

    React.useEffect(() => {
      if (value === CHECKBOX_STATES.Checked) {
        checkboxRef.current.checked = true;
        checkboxRef.current.indeterminate = false;
      } else if (value === CHECKBOX_STATES.Empty) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = false;
      } else if (value === CHECKBOX_STATES.Indeterminate) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = true;
      }
    }, [value]);

    return (
      <>
        <input
          ref={checkboxRef}
          type="checkbox"
          onChange={onChange}
          className={field === "ipList" ? "custom-checkbox" : ""}
        />
        <div className="custom-label">
          <Label>{value}</Label>
        </div>
      </>
    );
  };

  return <Checkbox value={checked} onChange={handleChange} />;
};
