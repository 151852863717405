import {
  faEye,
  faEyeSlash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field } from "formik";
import React, { useRef, useState } from "react";
import {
  Col,
  CustomInput,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { CustomErrorField } from "./CustomErrorField";

export const InputWebhookSettings = ({
  authVars,
  isSwitched,
  setIsSwitched,
}: {
  authVars: object;
  isSwitched: boolean;
  setIsSwitched: any;
}) => {
  const [enableText, setEnableText] = useState(
    isSwitched ? "Enabled" : "Disabled"
  );
  const [seePassword, setSeePassword] = useState(false);

  let ref = useRef(null);

  const handleMFASwitch = async () => {
    const currentSwitch = !isSwitched;
    setIsSwitched(currentSwitch);
    setEnableText(currentSwitch ? "Enabled" : "Disabled");
  };

  return (
    <Row className="mb-5">
      <Col lg="12" md="12" sm="12" xs="12">
        <div className="top10 bottom10">
          <FormGroup>
            <Label>{enableText}</Label>
            <CustomInput
              type="switch"
              id="active"
              innerRef={ref}
              onChange={handleMFASwitch}
              checked={isSwitched}
            />
          </FormGroup>
          <br />
          <div>
            <Label>Webhook URL</Label>
            <FormGroup>
              <div className="flex">
                <Field type="text" disabled={!isSwitched} name="url" />
              </div>
            </FormGroup>
            <ErrorMessage name="url" component={CustomErrorField} />
          </div>
          <div>
            <Label>Secret</Label>
            <FontAwesomeIcon
              color="#0d6efd"
              icon={faInfoCircle}
              className="left5"
              id="webhook-icon"
            />
            <UncontrolledTooltip
              delay={0}
              target="webhook-icon"
              placement="right"
            >
              The secret allows to implement additional security. SharePass will
              use the specified secret to calculate a sha256 checksum of the
              webhook payload and send it in the 'signature' header;{" "}
              <b>signature = sha256(payload+secret)</b>
            </UncontrolledTooltip>
            <FormGroup>
              <div className="flex">
                <FontAwesomeIcon
                  icon={seePassword ? faEye : faEyeSlash}
                  color="#0d6efd"
                  className="usr-profile cursor-p"
                  onClick={() => setSeePassword(!seePassword)}
                />
                <Field
                  type={seePassword ? "text" : "password"}
                  disabled={!isSwitched}
                  name="password"
                />
              </div>
            </FormGroup>
          </div>
        </div>
      </Col>
    </Row>
  );
};
