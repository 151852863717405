import { ErrorMessage, Field } from "formik";
import React from "react";
import { FormGroup, Label } from "reactstrap";
import { CustomErrorField } from "./CustomErrorField";
import { LockIconTooltip } from "./LockIconTooltip";

export const InputJsonWizard = () => {
  return (
    <div>
      <Label>
        JSON
        <LockIconTooltip />
      </Label>
      <FormGroup>
        <Field className="eee-field" as="textarea" name="json" />
      </FormGroup>
      <ErrorMessage name="json" component={CustomErrorField} />
    </div>
  );
};
