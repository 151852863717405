export const types = {
  login: "[auth] login",
  logout: "[auth] logout",
  refresh: "[auth] refresh",
  updateName: "[auth] update",
  userDetails: "[auth] userDetails",
  lightTheme: "[profile] lightTheme",
  purposeMode: "[profile] purposeMode",
  profile: "[profile] profile",
};

export const dataTypes = {
  profile: "[data] profile",
};
