import { faGlobe, faHome, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Col, Row } from "reactstrap";
const NewSecurityKeyMode = ({ setMode }: any) => {
  const [remoteHover, setRemoteHover] = useState(false);
  const [localHover, setLocalHover] = useState(false);

  return (
    <>
      <div className="text-center">
        <h4>This section allows you to enroll security keys to protect secret links with a physical device.</h4>
        <h4 className="info-text">
          Do you have the security key physically with you?
        </h4>
      </div>
      <Row>
        <Col className="text-center" sm="12">
          <div className="d-inline-block m-3">
            <button
              className={`${
                localHover
                  ? "btn-wizard btn-simple btn btn-primary"
                  : "btn-wizard btn-simple btn"
              }`}
              onClick={(e) => setMode("local")}
              onMouseEnter={(e) => setLocalHover(true)}
              onMouseLeave={(e) => setLocalHover(false)}
            >
              <FontAwesomeIcon
                icon={faHome}
                className="font-awesome mb-3"
                size="3x"
              />{" "}
              <br /> <span className="btn-wizard-title">Local</span>
            </button>
            <p>Yes, I have it with me</p>
          </div>

          <div className="d-inline-block m-3">
            <button
              className={`${
                remoteHover
                  ? "btn-wizard btn-simple btn btn-primary"
                  : "btn-wizard btn-simple btn"
              }`}
              onClick={(e) => setMode("remote")}
              onMouseEnter={(e) => setRemoteHover(true)}
              onMouseLeave={(e) => setRemoteHover(false)}
            >
              <FontAwesomeIcon
                icon={faGlobe}
                className="font-awesome mb-3"
                size="3x"
              />{" "}
              <br /> <span className="btn-wizard-title">Remote</span>
            </button>
            <p>No, it is with someone else</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewSecurityKeyMode;
