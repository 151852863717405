import { _arrayBufferToBase64, base64ToArrayBuffer } from "./secret";
import { Kyber1024Handshake } from "crystals-kyber-ts";
import { number } from "prop-types";

const wrapPublicKey = toArrayBuffer("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
    
const rsaAlg = {
    name: "RSA-OAEP",
    hash: "SHA-512",
    publicExponent: new Uint8Array([1, 0, 1]),
    modulusLength: 4096
};

export function toArrayBuffer(text: string) {
    var len = text.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = text.charCodeAt(i);
    }
    return bytes.buffer;
}

export function fromArrayBuffer(buffer: ArrayBuffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return binary;
}

export const generateRandomString = function (length:number) :string{

    let response = '';
    let random = crypto.getRandomValues(new Uint8Array(length));
    
    for( let i = 0; i < length; i++ )
    {
        response += String.fromCharCode( (random[i] % 94) + 33 );
    }

   return response;
};

export const generateRSAKeyPair = async function(masterKey: string){

    const masterKeyAB = toArrayBuffer(masterKey);
    const aesKey = await window.crypto.subtle.importKey(
        "raw",
        masterKeyAB,
        "AES-GCM",
        true,
        ["encrypt", "decrypt", "wrapKey", "unwrapKey"]
    );

    const aesPublicKey = await window.crypto.subtle.importKey(
        "raw",
        wrapPublicKey,
        "AES-GCM",
        true,
        ["encrypt", "decrypt", "wrapKey", "unwrapKey"]
    );
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const aesAlg = {
        name: "AES-GCM",
        length: 256,
        iv: iv,
    };

    const rsaKeys = await crypto.subtle.generateKey(rsaAlg, true, ["encrypt", "decrypt"]);

    /**
     * Wrap Private key
     */
    const sealedPrivateKey = await crypto.subtle.wrapKey("jwk", rsaKeys.privateKey, aesKey, aesAlg);

    /**
     * Wrap Public key
     */
    const wrappedPublicKey = await crypto.subtle.wrapKey("jwk", rsaKeys.publicKey, aesPublicKey, aesAlg);

    return {
        "publicKey": _arrayBufferToBase64(wrappedPublicKey),
        "sealedPrivateKey": _arrayBufferToBase64(sealedPrivateKey),
        "iv": _arrayBufferToBase64(iv),
    };
}

export const rsaEncrypt = async function(publicKeyData: any, data: string){
    
    let enc = new TextEncoder();
    let encoded = enc.encode(data);

    const unwrappedPublicKey = await unWrapRSAPublicKey(base64ToArrayBuffer(publicKeyData.publicKey),base64ToArrayBuffer(publicKeyData.iv));

    const encryptedData = await window.crypto.subtle.encrypt(
        {
          name: "RSA-OAEP",
        },
        unwrappedPublicKey,
        encoded
    ); 

    return _arrayBufferToBase64(encryptedData);
}

export const unWrapRSAPrivateKey = async function(masterKey: string, wrappedKey: ArrayBuffer, iv: ArrayBuffer){
    const masterKeyAB = toArrayBuffer(masterKey);

    const aesKey = await window.crypto.subtle.importKey(
        "raw",
        masterKeyAB,
        "AES-GCM",
        true,
        ["encrypt", "decrypt", "wrapKey", "unwrapKey"]
    );
      
    const aesAlg = {
        name: "AES-GCM",
        length: 256,
        iv: iv,
    };

    const unwrappedKey = await crypto.subtle.unwrapKey("jwk", wrappedKey, aesKey, aesAlg, rsaAlg, true, ["decrypt"]);

    return unwrappedKey;
}

export const unWrapRSAPublicKey = async function(wrappedKey: ArrayBuffer, iv: ArrayBuffer){

    const aesKey = await window.crypto.subtle.importKey(
        "raw",
        wrapPublicKey,
        "AES-GCM",
        true,
        ["encrypt", "decrypt", "wrapKey", "unwrapKey"]
    );
      
    const aesAlg = {
        name: "AES-GCM",
        length: 256,
        iv: iv,
    };

    const unwrappedKey = await crypto.subtle.unwrapKey("jwk", wrappedKey, aesKey, aesAlg, rsaAlg, true, ["encrypt"]);

    return unwrappedKey;
}

export const decryptDataRSA = async function(masterKey: string, encryptedData: string, decKey: any){

    let privateKey = await unWrapRSAPrivateKey(masterKey, base64ToArrayBuffer(decKey.sealedPrivateKey), base64ToArrayBuffer(decKey.iv));
    let encryptedSymmetricKey = base64ToArrayBuffer(decKey.key);
    
    let decryptedSymmetricKey = await window.crypto.subtle.decrypt(
        {
          name: "RSA-OAEP",
        },
        privateKey,
        encryptedSymmetricKey
    ); 

    let decodedDecryptedSymmetricKey = base64ToArrayBuffer(new TextDecoder().decode(decryptedSymmetricKey));
    			
	const SERVER_ENCRYPTION_IV_LENGTH = 12; // For GCM a nonce length of 12 bytes is recommended!

    let decodedEncryptedData = new Uint8Array(base64ToArrayBuffer(encryptedData));
    
	let nonce = decodedEncryptedData.subarray(0, SERVER_ENCRYPTION_IV_LENGTH);
	let ciphertextTag = decodedEncryptedData.subarray(SERVER_ENCRYPTION_IV_LENGTH);
	
	let aesKey = await window.crypto.subtle.importKey('raw', decodedDecryptedSymmetricKey, 'AES-GCM', true, ['encrypt', 'decrypt']);
	var decrypted = await crypto.subtle.decrypt({name: 'AES-GCM', iv: nonce}, aesKey, ciphertextTag);

	return new TextDecoder().decode(decrypted);

}

/**
 * Function to decrypt symmetric key and return as string, as it was in the secret URL for the view.
 * @param masterKey 
 * @param decKey 
 * @returns String symmetric secret key 
 */
export const decryptKeyRSA = async function(masterKey: string, decKey: any){

    let privateKey = await unWrapRSAPrivateKey(masterKey, base64ToArrayBuffer(decKey.sealedPrivateKey), base64ToArrayBuffer(decKey.iv));
    let encryptedSymmetricKey = base64ToArrayBuffer(decKey.key);
    
    let decryptedSymmetricKey = await window.crypto.subtle.decrypt(
        {
          name: "RSA-OAEP",
        },
        privateKey,
        encryptedSymmetricKey
    ); 

    return new TextDecoder().decode(decryptedSymmetricKey);
}

export const generateCRYSTALSKyberKeyPair = async function(masterKey: string){

    const masterKeyAB = toArrayBuffer(masterKey);
    const aesKey = await window.crypto.subtle.importKey(
        "raw",
        masterKeyAB,
        "AES-GCM",
        true,
        ["encrypt", "decrypt", "wrapKey", "unwrapKey"]
    );

    /*const aesPublicKey = await window.crypto.subtle.importKey(
        "raw",
        wrapPublicKey,
        "AES-GCM",
        true,
        ["encrypt", "decrypt", "wrapKey", "unwrapKey"]
    );*/

    const iv = crypto.getRandomValues(new Uint8Array(12));
    const aesAlg = {
        name: "AES-GCM",
        length: 256,
        iv: iv,
    };

    const publicKey = crypto.getRandomValues(new Uint8Array(1024));
    const privateKey = crypto.getRandomValues(new Uint8Array(1024));
    
    const sealedPrivateKey = await window.crypto.subtle.encrypt(
        aesAlg,
        aesKey,
        privateKey
    );

    return {
        "publicKey": _arrayBufferToBase64(publicKey),
        "sealedPrivateKey": _arrayBufferToBase64(sealedPrivateKey),
        "iv": _arrayBufferToBase64(iv),
    };

}

export const unWrapCRYSTALSKyberPrivateKey = async function(masterKey: string, wrappedKey: ArrayBuffer, iv: string){
    const masterKeyAB = toArrayBuffer(masterKey);
    const ivAB        = toArrayBuffer(iv);

    const aesKey = await window.crypto.subtle.importKey(
        "raw",
        masterKeyAB,
        "AES-GCM",
        true,
        ["encrypt", "decrypt", "wrapKey", "unwrapKey"]
    );
      
    const aesAlg = {
        name: "AES-GCM",
        length: 256,
        iv: ivAB,
    };

	var unwrappedKey = await crypto.subtle.decrypt(aesAlg, aesKey, wrappedKey);
	
    return unwrappedKey;
}



export const crystalsKyberEncrypt = async function(publicKeyData: any, data: string){
    
    const crystalsKyberShake = new Kyber1024Handshake();
    //crystalsKyberShake.kyberService.
    
    const publicKey: number[] = crystalsKyberShake.publicKey;//Array.from(new Uint8Array(base64ToArrayBuffer(publicKeyData.publicKey)));
    console.log("publickey", Array.from(new Uint8Array(publicKey)));
    console.log("private", crystalsKyberShake.privateKey);
    console.log("publicKey", publicKey.length,publicKey[0]);
    /*const dataToCipher: number[] = Array.from(new Uint8Array(toArrayBuffer(data)));
    crystalsKyberShake.publicKey = publicKey;
    crystalsKyberShake.sharedSecret = dataToCipher;*/
   // const aliceCipherText: number[] = aliceHandshake.generateCipherTextAndSharedSecret(bobPublicKey);

    /**
    * Send the cipher text generated from Bob's public key to Bob so that he
    * can generate the same remote shared secret
    */
    //const bobSharedSecret: number[] = bobHandshake.generateRemoteSharedSecret(aliceCipherText);
}

const chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";

// Use a lookup table to find the index.
let lookup = new Uint8Array(256);
for (let i = 0; i < chars.length; i++) {
  lookup[chars.charCodeAt(i)] = i;
}

/*export async function decrypt(){
    let response = await window.crypto.subtle.decrypt(
        { name: "RSA-OAEP" },
        unwrappedKey,
        encryptedData
      );
}*/
    
   
