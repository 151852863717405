import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface InfoProps {
  pageTabs: string;
  changeActiveTab: any;
  tabName: string;
  icon: any;
  title: string;
  disable?: boolean;
}

export const WizardButton = ({
  pageTabs,
  changeActiveTab,
  tabName,
  icon,
  title,
  disable,
}: InfoProps) => {
  return (
    <button
      className={`${
        pageTabs === tabName
          ? "btn-wizard btn-simple btn btn-primary"
          : "btn-wizard btn-simple btn"
      }`}
      onClick={(e) => changeActiveTab(e, "pageTabs", tabName)}
      disabled={disable}
    >
      <FontAwesomeIcon icon={icon} className="font-awesome mb-3" size="3x" />{" "}
      <br /> <span className="btn-wizard-title">{title}</span>
    </button>
  );
};
