import { ErrorMessage, Field } from "formik";
import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { CustomErrorField } from "./CustomErrorField";
import { LockIconTooltip } from "./LockIconTooltip";

export const InputCredentialWizard = () => {
  return (
    <Row>
      <Col md={6}>
        <div>
          <Label>
            Username
            <LockIconTooltip />
          </Label>
          <FormGroup>
            <Field className="eee-field" type="text" name="credentialEmail" />
          </FormGroup>
          <div className="spot-error-field">
            <ErrorMessage name="credentialEmail" component={CustomErrorField} />
          </div>
        </div>
        <div>
          <Label>
            Hostname
            <LockIconTooltip />
          </Label>
          <FormGroup>
            <Field
              className="eee-field"
              type="text"
              name="credentialHostname"
              placeholder="Optional"
            />
          </FormGroup>
          <div className="spot-error-field">
            <ErrorMessage
              name="credentialHostname"
              component={CustomErrorField}
            />
          </div>
        </div>
      </Col>
      <Col md={6}>
        <div>
          <Label>
            Password
            <LockIconTooltip />
          </Label>
          <FormGroup>
            <Field
              className="eee-field"
              type="password"
              name="credentialPassword"
            />
          </FormGroup>
          <div className="spot-error-field">
            <ErrorMessage
              name="credentialPassword"
              component={CustomErrorField}
            />
          </div>
        </div>
        <div>
          <Label>
            2FA Secret
            <LockIconTooltip />
          </Label>
          <FormGroup>
            <Field
              className="eee-field"
              type="text"
              name="credential2Fa"
              placeholder="Optional"
            />
          </FormGroup>
          <div className="spot-error-field">
            <ErrorMessage name="credential2Fa" component={CustomErrorField} />
          </div>
        </div>
      </Col>
    </Row>
  );
};
