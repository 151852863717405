import { useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { Button } from "reactstrap";

function CredentialsTemplateDefault({ data }) {
  const dataParsed = JSON.parse(data);
  const user = dataParsed.user;
  const secret = dataParsed.password;
  const url = dataParsed.hostname;
  const mfa = dataParsed.secret;

  const [btnUserDisabled, setBtnUserDisabled] = useState(false);
  const [btnSecretDisabled, setBtnSecretDisabled] = useState(false);
  const [btnURLDisabled, setBtnURLDisabled] = useState(false);
  const [btnMFADisabled, setBtnMFADisabled] = useState(false);

  const [btnUserCopyContent, setBtnUserCopyContent] = useState(
    <i className="fas fa-copy"></i>
  );
  const [btnSecretCopyContent, setBtnSecretCopyContent] = useState(
    <i className="fas fa-copy"></i>
  );
  const [btnURLCopyContent, setBtnURLCopyContent] = useState(
    <i className="fas fa-copy"></i>
  );
  const [btnMFACopyContent, setBtnMFACopyContent] = useState(
    <i className="fas fa-copy"></i>
  );

  const [inputType, setInputType] = useState("password");
  const [btnRevealContent, setBtnRevealContent] = useState(
    <i className="fas fa-eye"></i>
  );
  const [contentRevealed, setContentRevealed] = useState(false);

  function revealData() {
    setContentRevealed(!contentRevealed);
    if (contentRevealed == false) {
      setBtnRevealContent(<i className="fas fa-eye-slash"></i>);
      setInputType("text");
    } else {
      setBtnRevealContent(<i className="fas fa-eye"></i>);
      setInputType("password");
    }
  }

  const [btnRevealFullContent, setBtnRevealFullContent] = useState(
    <>
      <i className="fas fa-eye"></i> Reveal
    </>
  );
  const [fullContentRevealed, setFullContentRevealed] = useState(false);

  function revealFullData() {
    setFullContentRevealed(!fullContentRevealed);
    if (fullContentRevealed == false) {
      setBtnRevealFullContent(
        <>
          <i className="fas fa-eye-slash"></i> Hide
        </>
      );
    } else {
      setBtnRevealFullContent(
        <>
          <i className="fas fa-eye"></i> Reveal
        </>
      );
    }
  }

  function copyToClipboard(text, setBtnDisabled, setBtnCopyContent) {
    navigator.clipboard.writeText(text);
    setBtnDisabled(true);

    /* Alert the copied text */
    setBtnCopyContent("Copied!");

    setTimeout(function () {
      setBtnDisabled(false);
      setBtnCopyContent(<i className="fas fa-copy"></i>);
    }, 3000);
  }

  function copyUserToClipboard() {
    copyToClipboard(user, setBtnUserDisabled, setBtnUserCopyContent);
  }

  function copySecretToClipboard() {
    copyToClipboard(secret, setBtnSecretDisabled, setBtnSecretCopyContent);
  }

  function copyURLToClipboard() {
    copyToClipboard(url, setBtnURLDisabled, setBtnURLCopyContent);
  }

  function copyMFAToClipboard() {
    copyToClipboard(mfa, setBtnMFADisabled, setBtnMFACopyContent);
  }

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnCopyContent, setBtnCopyContent] = useState(
    <>
      <i className="fas fa-copy"></i> Copy
    </>
  );

  function copyAllToClipboard() {
    let textToCopy = {
      username: user,
      password: secret,
    };

    if (mfa) {
      textToCopy.mfa = mfa;
    }

    if (url) {
      textToCopy.hostname = url;
    }

    navigator.clipboard.writeText(JSON.stringify(textToCopy));
    setBtnDisabled(true);

    /* Alert the copied text */
    setBtnCopyContent("Copied!");

    setTimeout(function () {
      setBtnDisabled(false);
      setBtnCopyContent(
        <>
          <i className="fas fa-copy"></i> Copy
        </>
      );
    }, 3000);
  }


  return (
    <>
      <div className="mb-3">
        <i className="fas fa-check-circle"></i> You can grab the credentials
        delivered.
      </div>

      <div className="mb-3">
        <Button
          className={`btn-round`}
          color="primary"
          type="button"
          onClick={revealFullData}
        >
          {btnRevealFullContent}
        </Button>
      
        <Button
          className={`btn-round ${
            btnDisabled ? "disabled" : ""
          }`}
          color="secondary"
          type="button"
          disabled={btnDisabled}
          onClick={copyAllToClipboard}
        >
          {btnCopyContent}
        </Button>
        
      </div>

      {fullContentRevealed &&
        <div className="input-group">
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="btn btn-outline-primary fixed-prepend wider-prepend">
                <i className="fas fa-user"></i>
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              readOnly
              id="cUser"
              value={user}
            />
            <div className="input-group-append">
              <div
                className="btn btn-outline-primary copy-data"
                disabled={btnUserDisabled}
                onClick={copyUserToClipboard}
              >
                {btnUserCopyContent}
              </div>
            </div>
          </div>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div
                className="btn btn-outline-primary fixed-prepend wider-prepend"
                onClick={revealData}
              >
                {btnRevealContent}
              </div>
            </div>
            <input
              type={inputType}
              className="form-control"
              readOnly
              id="secretData"
              value={secret}
            />
            <div className="input-group-append">
              <div
                className="btn btn-outline-primary copy-data"
                disabled={btnSecretDisabled}
                onClick={copySecretToClipboard}
              >
                {btnSecretCopyContent}
              </div>
            </div>
          </div>
          {url && 
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="btn btn-outline-primary fixed-prepend wider-prepend">
                  <i className="fas fa-link"></i>
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                readOnly
                id="cURL"
                value={url}
              />
              <div className="input-group-append">
                <div
                  className="btn btn-outline-primary copy-data"
                  disabled={btnURLDisabled}
                  onClick={copyURLToClipboard}
                >
                  {btnURLCopyContent}
                </div>
              </div>
            </div>
          }
          {mfa &&
            <>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="btn btn-outline-primary fixed-prepend wider-prepend">
                    <small>2FA</small>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  id="c2fa"
                  value={mfa}
                />
                <div className="input-group-append">
                  <div
                    className="btn btn-outline-primary copy-data"
                    disabled={btnMFADisabled}
                    onClick={copyMFAToClipboard}
                  >
                    {btnMFACopyContent}
                  </div>
                </div>
              </div>
              <div className="w100">
                <hr />
                <div className="text-center">
                  <p>Scan your 2FA code directly from the QR code below:</p>
                </div>

                <div className="text-center" style={{ width: "100%" }}>
                  <QRCode
                    value={`otpauth://totp/${user}?secret=${mfa}`}
                    size="250"
                    /*logoImage="/assets/img/SP.png"
                    logoWidth="40"*/
                  />
                </div>
              </div>
            </>
          }
        </div>
      }
    </>
  );
}




const CredentialsTemplate = CredentialsTemplateDefault;

export default CredentialsTemplate;
