import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
export const EnvelopeIncompleteURL = () => {

  

  return (
    <Container>
      <Row>
        <Col className="ml-auto mr-auto" md="7">
          <Card className="card-register card-envelope card-white no-mb">
            <CardHeader className="card-header-sp">
              <img
                alt="..."
                src={require("../../assets/img/sp.png").default}
              />
            </CardHeader>
            <CardBody className="pBotttom0">
                  
              <div className="form-group text-center" >
                <div className="alert alert-info">
                    <div><i className="fas fa-exclamation-triangle fa-3x"></i></div>
                    To reveal your secret use the complete URL, which contains the required secret ID and password.
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
