import { faEdit } from "@fortawesome/free-regular-svg-icons";
import {
  faExternalLinkSquareAlt,
  faInfoCircle,
  faKey,
  faPaintBrush,
  faShieldAlt, faSignOut, faSpinner,
  faTrash,
  faUserCircle,
  faCode,
  faCopy,
  faQrcode,
  faEnvelope,
  faPaperPlane,
  faInbox
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Collapse,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from "reactstrap";
import useAPIError from "../../commons/hooks/useAPIError";
import { CustomEndData } from "../../commons/models/models";
import { Context } from "../../context/auth/Context";
import { DataContext } from "../../context/data/DataContext";
import { dataTypes, types } from "../../context/types";
import {
  fetchWebauthnCreate,
  fetchWebauthnParseAdd
} from "../../services/auth";
import { b64decode, b64encode } from "../../services/common";
import {
  fetchChangePassword,
  fetchDeleteSecurityKey,
  fetchDeleteWebhook, fetchGlobalSignOut, fetchMFAStatus,
  fetchMFAVerify,
  fetchPutWebhook,
  fetchUpdateName,
  fetchUserProfile
} from "../../services/services";
import FrontendConfig from "../../assets/js/config";
import InstanceConfig from "../../assets/js/instanceConfig";
import { DarkLightMode } from "../components/DarkLightMode";
import { DefaultInputs } from "../components/DefaultInputs";
import { EnvelopeSettings } from "../components/EnvelopeSettings";
import { InputChangePassword } from "../components/InputChangePassword";
import { InputMFA } from "../components/InputMFA";
import { InputUpdateProfile } from "../components/InputUpdateProfile";
import { InputWebhookSettings } from "../components/InputWebhookSettings";
import { MFAForm } from "../components/MFAForm";
import { MFAFormVerify } from "../components/MFAFormVerify";
import { NavItemSetting } from "../components/NavItemSetting";
import { SecurityKeyInput } from "../components/SecurityKeyInput";

interface ErrorForm {
  verifyCode?: string;
  friendlyDevice?: string;
}

const ProfileDefault = () => {
  const history = useHistory();

  // Notify
  const { addNotify } = useAPIError();

  const {
    user: { userDetails: userData },
    dispatch,
  } = useContext(Context);

  // Context for profile
  const {
    profile, //: { sso, publicKeysCred },
    dispatch2,
  } = useContext(DataContext);

  console.log(localStorage.getItem("lastPath"));

  const currentURL = new URLSearchParams(history.location.search);

  if (currentURL.get("tab")) {
    profile.profileTabName = currentURL.get("tab");
  }

  const [pageTabs, setpageTabs] = useState(profile.profileTabName || "user");

  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  const expiresIn = localStorage.getItem("expiresIn");
  const userId = localStorage.getItem("userId");

  function isValidHttpUrl(string: string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "https:";
  }

  useEffect(() => {
    const updateUserDetails = async () => {
      if (typeof profile.sso === "undefined") {
        const { status, details, redirect } = await fetchUserProfile();
        //const { status, details, redirect } = await fetchUserDetails();
        if (redirect) {
          dispatch({
            type: types.logout,
          });
          history.push("/auth/login");
        } else if (status === "Success" && details) {
          /*setUserDetails(details);
          setIsSwitched(
            details.webhook && details.webhook.active
              ? details.webhook.active
              : false
          );
          setHasSSO(details.sso === true);
          setHasWebhook(details.webhook && details.webhook.url ? true : false);
          setIsCredKeys(details.publicKeysCred);*/

          dispatch2({
            type: dataTypes.profile,
            payload: { ...details, profileTabName: pageTabs },
          });
        }
      }
    };

    updateUserDetails();
  }, []);

  const [isSwitched, setIsSwitched] = useState(
    profile.webhook && profile.webhook.active ? profile.webhook.active : false
  );

  const [credKeys, setIsCredKeys] = useState(profile.publicKeysCred);

  const [hasSSO, setHasSSO] = useState(profile.sso === true);

  const [userDetails, setUserDetails] = useState(profile);
  const [hasWebhook, setHasWebhook] = useState(
    profile.webhook && profile.webhook.url ? true : false
  );
  const [switchMFA, setSwitchMFA] = useState(false);
  const [friendlyDeviceName, setFriendlyDeviceName] = useState("");

  const [showStep, setShowStep] = useState("password");
  const [has2FA, sethas2FA] = useState(false);
  const [TOPTURL, setTOPTURL] = useState("");
  const [TOPTSecret, setTOPTSecret] = useState("");

  const [action, setAction] = useState("update");

  const [isSigningOutGlobally, setIsSigningOutGlobally] = useState(false);

  const changeActiveTab = (e: any, tabState: string, tabName: string) => {
    e.preventDefault();
    switch (tabState) {
      case "pageTabs":
        setpageTabs(tabName);
        break;
      default:
        break;
    }
  };

  /*
   * Show alert before delete a credential
   */
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const warningWithConfirmMessage = (id: string, name: string) => {
    setShowAlert(true);
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          setAuxId(id);
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="success"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        Are you sure you want to delete the key <strong>"{name}"</strong>?<br />
      </ReactBSAlert>
    );
  };

  /*
   * Hide alert when delete a secret
   */
  const hideAlert = () => {
    setShowAlert(false);
    setAlert({});
  };

  /*
   * Delete a secret
   */
  const [auxId, setAuxId] = useState("");
  useEffect(() => {
    // Delete from the database
    const deleteSecurityKey = async () => {
      const body = { credId: auxId };

      const { status, redirect } = await fetchDeleteSecurityKey(body);

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        let tempCreds = Object.assign({}, credKeys);

        delete tempCreds[auxId];

        let tempDetails: any = {};
        tempDetails["publicKeysCred"] = tempCreds;

        setIsCredKeys(tempCreds);

        setAuxId("");

        /*setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Deleted!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            Your security key has been deleted.
          </ReactBSAlert>
        );*/
        addNotify("Your security key was deleted successfully", "success");

        dispatch2({
          type: dataTypes.profile,
          payload: { ...userDetails, ...tempDetails, profileTabName: pageTabs },
        });
      }
    };

    if (auxId !== "") {
      deleteSecurityKey();
    }
  }, [auxId]);

  const handleGlobalSignOut = async () => {
    try{
      setIsSigningOutGlobally(true);

      const { status, redirect } = await fetchGlobalSignOut();

      setIsSigningOutGlobally(false);

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        localStorage.clear();
        
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else {
        addNotify(
          "There was an error while closing all you sessions, please try again.",
          "danger"
        );
      }
      
    }catch(e){
      setIsSigningOutGlobally(false);
      addNotify(
        "There was an error while closing all you sessions, please try again.",
        "danger"
      );
    }
  };

  const copyRefreshToken = () => {
    navigator.clipboard.writeText(localStorage.getItem("refreshToken"));
    addNotify(
      "Refresh token copied",
      "success"
    );
  }

  const [openedDefaultSecrets, setOpenedDefaultSecrets] = useState(true);
  const [openedDefaultEnvelopes, setOpenedDefaultEnvelopes] = useState(false);

  return (
    <>
      {showAlert && alert}
      <div className="content">
        <Card className="card-stats">
          <CardBody>
            <Nav
              className="nav-pills-primary nav-pills-icons justify-content-center mb-2"
              pills
            >
              <NavItemSetting
                icon={faUserCircle}
                pageTabs={pageTabs}
                changeActiveTab={changeActiveTab}
                tabName="user"
                title="Account"
              />

              {InstanceConfig().emailSignUp && <>
                <NavItemSetting
                  icon={faKey}
                  pageTabs={pageTabs}
                  changeActiveTab={changeActiveTab}
                  tabName="password"
                  title="Password"
                  disable={hasSSO}
                />
                <NavItemSetting
                  icon={faShieldAlt}
                  pageTabs={pageTabs}
                  changeActiveTab={changeActiveTab}
                  tabName="mfa"
                  title="MFA"
                  disable={hasSSO}
                />
              </>}
              <NavItemSetting
                icon={faCode}
                pageTabs={pageTabs}
                changeActiveTab={changeActiveTab}
                tabName="webhooks"
                title="Dev"
              />
              <NavItemSetting
                icon={faPaintBrush}
                pageTabs={pageTabs}
                changeActiveTab={changeActiveTab}
                tabName="settings"
                title="Theme"
              />
              {InstanceConfig().envelope && <NavItemSetting
                icon={faEnvelope}
                pageTabs={pageTabs}
                changeActiveTab={changeActiveTab}
                tabName="envelope"
                title="Envelope"
              />}
              <NavItemSetting
                icon={faEdit}
                pageTabs={pageTabs}
                changeActiveTab={changeActiveTab}
                tabName="default"
                title="Default"
              />
            </Nav>
            {InstanceConfig().emailSignUp && hasSSO && (
              <>
                <UncontrolledTooltip
                  delay={0}
                  target="mfa-tab-profile"
                  placement="top"
                >
                  Not applicable to SSO accounts
                </UncontrolledTooltip>
                <UncontrolledTooltip
                  delay={0}
                  target="password-tab-profile"
                  placement="top"
                >
                  Not applicable to SSO accounts
                </UncontrolledTooltip>
              </>
            )}
            <TabContent
              className="tab-space tab-subcategories"
              activeTab={pageTabs}
            >
              <TabPane tabId="user">
                <Row>
                  <Col lg="12" md="12">
                    <Card className="custom-card">
                      <CardBody>
                        <CardTitle tag="h3">Profile info</CardTitle>
                        <Row>
                          <Col lg="4" md="4" sm="6" xs="12">
                            <Label>Email</Label>
                            <FormGroup>
                              <Input
                                type="text"
                                value={userData.email}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12">
                    <Card className="custom-card">
                      <CardBody>
                        <CardTitle tag="h3">Full name</CardTitle>
                        <div>
                          <p>Update your name</p>
                        </div>
                        <Row>
                          <Col lg="4" md="4" sm="6" xs="12">
                            <Formik
                              initialValues={{
                                firstname: userData.name ? userData.name : "",
                                lastname: userData.lastname
                                  ? userData.lastname
                                  : "",
                              }}
                              validate={(values) => {
                                let errors: {
                                  firstname?: string;
                                  lastname?: string;
                                } = {};
                                const error = "This field is required";

                                if (!values.firstname) {
                                  errors.firstname = error;
                                }

                                if (!values.lastname) {
                                  errors.lastname = error;
                                }

                                return errors;
                              }}
                              onSubmit={async (values, { setSubmitting }) => {
                                let endData: {
                                  name: string;
                                  lastname: string;
                                } = {
                                  name: values.firstname,
                                  lastname: values.lastname,
                                };

                                const { status, redirect } =
                                  await fetchUpdateName(endData);

                                setSubmitting(false);

                                if (redirect) {
                                  dispatch({
                                    type: types.logout,
                                  });
                                  history.push("/auth/login");
                                } else if (status === "Success") {
                                  addNotify(
                                    "Details updated successfully",
                                    "success"
                                  );

                                  dispatch({
                                    type: types.userDetails,
                                    payload: {
                                      name: values.firstname,
                                      lastName: values.lastname,
                                      email: userData.email,
                                      userDetails: {
                                        name: values.firstname,
                                        lastname: values.lastname,
                                        email: userData.email,
                                      },
                                    },
                                  });

                                  dispatch2({
                                    type: dataTypes.profile,
                                    payload: {
                                      ...userDetails,
                                      profileTabName: pageTabs,
                                    },
                                  });
                                } else {
                                  addNotify(
                                    "There was an error while updating the details",
                                    "danger"
                                  );
                                }
                              }}
                            >
                              {({ isSubmitting }) => (
                                <Form className="form-formik">
                                  <InputUpdateProfile />
                                  <Row>
                                    <Col>
                                      <Button
                                        className="btn-fill btn-sm"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                      >
                                        {!isSubmitting
                                          ? "Ok"
                                          : "PLEASE WAIT..."}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>
                              )}
                            </Formik>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" md="12">
                    <Card className="custom-card">
                      <CardBody>
                        <CardTitle tag="h3">Global sign out</CardTitle>
                        
                        <Row>
                          <Col lg="6" md="6" sm="12" xs="12">
                          <div>
                          <p>Sign this account out of all SharePass sessions across all devices. It can take up to 15 minutes for the process to complete.
This account will be able to immediately sign back in.</p>
                          </div>
                          <Button
                                        className="btn-fill btn-sm"
                                        color="primary"
                                        type="submit"
                                        disabled={isSigningOutGlobally}
                                        onClick={handleGlobalSignOut}
                                      >
                                        <FontAwesomeIcon
                                          className="cursor-p"
                                          icon={faSignOut}
                                        />{" "}Global sign out
                                      </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              {InstanceConfig().emailSignUp &&
              <>
                <TabPane tabId="password">
                  <Row>
                    <Col lg="12" md="12">
                      <Card className="custom-card">
                        <CardBody>
                          <CardTitle tag="h3">Password</CardTitle>
                          <div>
                            <p>
                              Choose a strong password and don't reuse it for
                              other accounts. We recommend you to change your
                              password every 6 months
                            </p>
                          </div>
                          <Row>
                            <Col lg="4" md="4" sm="6" xs="12">
                              <Formik
                                initialValues={{
                                  password: "",
                                  newPassword: "",
                                  confirmNewPassword: "",
                                }}
                                validate={(values) => {
                                  let errors = {};
                                  const error = "This field is required";

                                  if (!values.password) {
                                    errors.password = error;
                                  }

                                  if (!values.newPassword) {
                                    errors.newPassword = error;
                                  }

                                  if (!values.confirmNewPassword) {
                                    errors.confirmNewPassword = error;
                                  }

                                  if (values.confirmNewPassword.length < 8) {
                                    errors.confirmNewPassword =
                                      "Passwords need at least 8 characters.";
                                  }

                                  if (
                                    values.confirmNewPassword !==
                                    values.newPassword
                                  ) {
                                    errors.confirmNewPassword =
                                      "Password confirmation doesn't match.";
                                  }

                                  return errors;
                                }}
                                onSubmit={async (
                                  values,
                                  { setSubmitting, resetForm }
                                ) => {
                                  let endData: CustomEndData = {
                                    previousPassword: values.password,
                                    proposedPassword: values.newPassword,
                                  };

                                  const { status, redirect, message } =
                                    await fetchChangePassword(endData);

                                  if (redirect) {
                                    dispatch({
                                      type: types.logout,
                                    });
                                    history.push("/auth/login");
                                  } else if (status === "Success") {
                                    resetForm();
                                    addNotify(
                                      "Password updated successfully",
                                      "success"
                                    );
                                  } else {
                                    let showMessage =
                                      "There was an error while updating the details";

                                    if (message) {
                                      showMessage = message;
                                    }

                                    addNotify(showMessage, "danger");
                                  }
                                  setSubmitting(false);
                                }}
                              >
                                {({ isSubmitting }) => (
                                  <Form className="form-formik">
                                    <InputChangePassword />

                                    <Row>
                                      <Col>
                                        <Button
                                          className="btn-fill btn-sm"
                                          color="primary"
                                          type="submit"
                                          disabled={isSubmitting}
                                        >
                                          {!isSubmitting
                                            ? "Ok"
                                            : "PLEASE WAIT..."}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Form>
                                )}
                              </Formik>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="mfa">
                  <Row>
                    <Col lg="12" md="12">
                      <Card className="custom-card">
                        <CardBody>
                          <CardTitle tag="h3">Authenticator app</CardTitle>
                          <div>
                            <p>
                              Protect your account with an extra Time-based
                              one-time Password (TOTP).
                            </p>
                          </div>
                          <Row>
                            {showStep && showStep === "password" && (
                              <Col lg="4" md="4" sm="6" xs="12">
                                <Formik
                                  initialValues={{
                                    mfapassword: "",
                                  }}
                                  validate={(values) => {
                                    let errors = {};
                                    const error = "This field is required";

                                    if (!values.mfapassword) {
                                      errors.mfapassword = error;
                                    }

                                    return errors;
                                  }}
                                  onSubmit={async (
                                    values,
                                    { setSubmitting, resetForm }
                                  ) => {
                                    let endData: CustomEndData = {
                                      password: values.mfapassword,
                                    };

                                    const { status, details, message, redirect } =
                                      await fetchMFAStatus(endData);

                                    if (redirect) {
                                      dispatch({
                                        type: types.logout,
                                      });
                                      history.push("/auth/login");
                                    } else if (status === "Success") {
                                      resetForm();
                                      setSwitchMFA(details.enabled);
                                      sethas2FA(details.enabled);
                                      setShowStep("toggle");

                                      if (details.friendlyDeviceName) {
                                        setFriendlyDeviceName(
                                          details.friendlyDeviceName
                                        );
                                      }

                                      addNotify("Password is correct", "success");
                                    } else {
                                      let showMessage =
                                        "There was an error while retreiving MFA status.";

                                      if (message) {
                                        showMessage = message;
                                      }

                                      addNotify(showMessage, "danger");
                                    }

                                    setSubmitting(false);
                                  }}
                                >
                                  {({ isSubmitting }) => (
                                    <Form className="form-formik">
                                      <InputMFA />
                                      <Row>
                                        <Col>
                                          <Button
                                            className="btn-fill btn-sm"
                                            color="primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                          >
                                            {!isSubmitting
                                              ? "Ok"
                                              : "PLEASE WAIT..."}
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Form>
                                  )}
                                </Formik>
                              </Col>
                            )}
                            {showStep &&
                              ["toggle", "verify", "checking"].includes(
                                showStep
                              ) && (
                                <Col lg="4" md="4" sm="6" xs="12">
                                  <MFAForm
                                    authVars={{
                                      token,
                                      refreshToken,
                                      expiresIn,
                                      userId: userId,
                                      email: userData.email,
                                    }}
                                    isSwitched={switchMFA}
                                    friendlyDeviceName={friendlyDeviceName}
                                    setShowStep={setShowStep}
                                    setTOPTURL={setTOPTURL}
                                    setTOPTSecret={setTOPTSecret}
                                    sethas2FA={sethas2FA}
                                    has2FA={has2FA}
                                  />
                                </Col>
                              )}
                            {showStep && "checking" === showStep && (
                              <Col lg="4" md="4" sm="6" xs="12">
                                <div className="text-center">
                                  <i className="fa fa-spinner fa-spin fa-3x"></i>
                                </div>
                              </Col>
                            )}
                            {!has2FA && showStep && showStep === "verify" && (
                              <Col lg="4" md="4" sm="6" xs="12">
                                <Formik
                                  initialValues={{
                                    verifyCode: "",
                                    friendlyDevice: "",
                                  }}
                                  validate={(values) => {
                                    let errors: ErrorForm = {};
                                    const error = "This field is required";

                                    if (!values.verifyCode) {
                                      errors.verifyCode = error;
                                    }

                                    if (values.verifyCode.length !== 6) {
                                      errors.verifyCode =
                                        "The code needs to be 6 digits";
                                    }

                                    if (
                                      values.friendlyDevice.trim().length > 25
                                    ) {
                                      errors.friendlyDevice =
                                        "This name is too long, max 25 characters";
                                    }

                                    return errors;
                                  }}
                                  onSubmit={async (
                                    values: {
                                      verifyCode?: string;
                                      friendlyDevice?: string;
                                    },
                                    { setSubmitting, resetForm }
                                  ) => {
                                    let verifyData: {
                                      userCode: string;
                                      friendlyDeviceName?: string;
                                    } = {
                                      userCode: values.verifyCode
                                        ? values.verifyCode
                                        : "",
                                    };

                                    if (
                                      values.friendlyDevice &&
                                      values.friendlyDevice.trim() !== ""
                                    ) {
                                      verifyData.friendlyDeviceName =
                                        values.friendlyDevice.trim();
                                    }

                                    const { status, details, message, redirect } =
                                      await fetchMFAVerify(verifyData);

                                    if (redirect) {
                                      dispatch({
                                        type: types.logout,
                                      });
                                      history.push("/auth/login");
                                    } else if (status === "Success") {
                                      if (details && details.friendlyDeviceName) {
                                        setFriendlyDeviceName(
                                          details.friendlyDeviceName
                                        );
                                      }

                                      sethas2FA(true);

                                      addNotify(
                                        "You have verified your MFA device!",

                                        "success"
                                      );

                                      setShowStep("toggle");

                                      resetForm();
                                    } else {
                                      let showMessage =
                                        "Incorrect verification code, try again.";

                                      if (message) {
                                        showMessage = message;
                                      }

                                      addNotify(showMessage, "danger");
                                    }

                                    setSubmitting(false);
                                  }}
                                >
                                  {({ isSubmitting, values, setFieldValue }) => (
                                    <Form className="form-formik">
                                      <MFAFormVerify
                                        TOPTURL={TOPTURL}
                                        secret={TOPTSecret}
                                        notify={addNotify}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                      />
                                      <Row>
                                        <Col>
                                          <Button
                                            className="btn-fill btn-sm"
                                            color="primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                          >
                                            {!isSubmitting
                                              ? "Ok"
                                              : "PLEASE WAIT..."}
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Form>
                                  )}
                                </Formik>
                              </Col>
                            )}
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  {/*className="d-none" -->*/}
                  <Row>
                    <Col lg="12" md="12">
                      <Card className="custom-card">
                        <CardBody>
                          <CardTitle tag="h3">Security keys</CardTitle>
                          <div>
                            <p>
                              Protect your account with a security key (e.g.
                              Yubikey).
                              <br />
                              <small>
                                (the security keys override the authenticator app)
                              </small>
                            </p>
                          </div>
                          <Row>
                            <Col lg="4" md="4" sm="6" xs="12">
                              <Formik
                                initialValues={{
                                  keyName: "",
                                }}
                                validate={(values) => {
                                  let errors: { keyName?: string } = {};
                                  const error = "This field is required";

                                  if (!values.keyName) {
                                    errors.keyName = error;
                                  }

                                  if (
                                    values.keyName &&
                                    values.keyName.length > 30
                                  ) {
                                    errors.keyName = "Use 30 chars max";
                                  }

                                  return errors;
                                }}
                                onSubmit={async (
                                  values,
                                  { setSubmitting, resetForm }
                                ) => {
                                  let endData: any = {
                                    keyName: values.keyName,
                                  };

                                  try {
                                    const { status, details, message, redirect } =
                                      await fetchWebauthnCreate();

                                    if (redirect) {
                                      dispatch({
                                        type: types.logout,
                                      });
                                      history.push("/auth/login");
                                    } else if (status === "Success") {
                                      let credOptions = details;
                                      let challenge = credOptions.challenge;
                                      credOptions.user.id = b64decode(
                                        credOptions.user.id
                                      );
                                      credOptions.challenge = b64decode(
                                        credOptions.challenge
                                      );

                                      if (credOptions.excludeCredentials) {
                                        for (
                                          let i = 0,
                                            size =
                                              credOptions.excludeCredentials
                                                .length;
                                          i < size;
                                          i++
                                        ) {
                                          credOptions.excludeCredentials[i].id =
                                            b64decode(
                                              credOptions.excludeCredentials[i].id
                                            );
                                        }
                                      }
                                      console.log("credOptions", credOptions);
                                      //----------create credentials using available authenticator
                                      const cred = await navigator.credentials
                                        .create({
                                          publicKey: credOptions,
                                        })
                                        .catch(function (error) {
                                          console.log(error);
                                          throw error;
                                        });
                                      //console.log("cred", cred.response.getTransports());
                                      // parse credentials response to extract id and public-key, this is the information needed to register the user in Cognito
                                      const credential: {
                                        id?: any;
                                        rawId?: any;
                                        type?: any;
                                        challenge?: any;
                                        response?: any;
                                        transports?: any;
                                      } = {};
                                      credential.id =
                                        cred && cred.id ? cred.id : null;
                                      credential.rawId = b64encode(
                                        cred && cred.rawId ? cred.rawId : null
                                      );
                                      credential.type =
                                        cred && cred.type ? cred.type : null;
                                      credential.challenge = challenge;

                                      if (cred && cred.response) {
                                        const clientDataJSON = b64encode(
                                          cred.response.clientDataJSON
                                        );
                                        const attestationObject = b64encode(
                                          cred.response.attestationObject
                                        );
                                        credential.response = {
                                          clientDataJSON,
                                          attestationObject,
                                        };

                                        credential.transports = JSON.stringify(
                                          cred.response.getTransports()
                                        );
                                      }
                                      endData = { ...endData, ...credential };

                                      const parseResult =
                                        await fetchWebauthnParseAdd(endData);

                                      const { status, redirect } = parseResult;

                                      if (redirect) {
                                        dispatch({
                                          type: types.logout,
                                        });
                                        history.push("/auth/login");
                                      } else if (status === "Success") {
                                        resetForm();
                                        addNotify(
                                          "Security key added!",
                                          "success"
                                        );

                                        let tempCreds = Object.assign(
                                          {},
                                          credKeys
                                        );

                                        tempCreds[parseResult.details.credId] = {
                                          name: parseResult.details.name,
                                        };

                                        let tempDetails: any = {};
                                        tempDetails["publicKeysCred"] = tempCreds;

                                        dispatch2({
                                          type: dataTypes.profile,
                                          payload: {
                                            ...userDetails,
                                            ...tempDetails,
                                            profileTabName: pageTabs,
                                          },
                                        });

                                        setIsCredKeys(tempCreds);
                                      } else {
                                        let message =
                                          "There was an error adding your security key.";

                                        if (res.message) {
                                          message = res.message;
                                        }

                                        addNotify(message, "danger");
                                      }
                                    } else {
                                      let message =
                                        "There was an error creating the credentials.";

                                      if (res.message) {
                                        message = res.message;
                                      }

                                      addNotify(message, "danger");
                                    }

                                    setSubmitting(false);
                                  } catch (error: any) {
                                    console.log(error);
                                    addNotify(error.message, "danger");
                                  }
                                }}
                              >
                                {({ isSubmitting }) => (
                                  <Form className="form-formik">
                                    <SecurityKeyInput />
                                    <Row>
                                      <Col>
                                        <Button
                                          className="btn-fill btn-sm"
                                          color="primary"
                                          type="submit"
                                          disabled={isSubmitting}
                                        >
                                          {!isSubmitting
                                            ? "Add key"
                                            : "PLEASE WAIT..."}
                                        </Button>
                                        {!isSubmitting ? (
                                          <></>
                                        ) : (
                                          <div className="mt-1">
                                            <FontAwesomeIcon
                                              icon={faSpinner}
                                              className="spinner"
                                            ></FontAwesomeIcon>{" "}
                                            This operation may take few seconds...
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </Form>
                                )}
                              </Formik>
                            </Col>
                            <Col lg="4" md="4" sm="6" xs="12">
                              <Label>Your keys</Label>

                              {credKeys && Object.keys(credKeys).length === 1 && (
                                <>
                                  <FontAwesomeIcon
                                    color="#f63b3b"
                                    icon={faInfoCircle}
                                    className="left5"
                                    id="your-keys-icon"
                                  />
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="your-keys-icon"
                                    placement="right"
                                  >
                                    It is recommended to have a secondary security
                                    key just in case the primary gets lost, stolen
                                    or unavailable.
                                  </UncontrolledTooltip>
                                </>
                              )}
                              <div className="box-keys">
                                {credKeys && (
                                  <>
                                    {Object.keys(credKeys).map((credId) => (
                                      <div
                                        className="flex-start key-row"
                                        key={credId}
                                      >
                                        <p className="credential-name-mfa">
                                          {credKeys[credId]["name"]}
                                        </p>
                                        <div className="credential-name-mfa-delete">
                                          <FontAwesomeIcon
                                            className="cursor-p"
                                            icon={faTrash}
                                            color="#ff4757"
                                            onClick={() => {
                                              warningWithConfirmMessage(
                                                credId,
                                                credKeys[credId]["name"]
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </>
                                )}

                                {(!credKeys ||
                                  (credKeys &&
                                    Object.keys(credKeys).length == 0)) && (
                                  <div className="text-center mt-1 alert alert-info">
                                    <span>
                                      <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        className="left5"
                                      />{" "}
                                      You don't have any security key added
                                    </span>
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </>}
              <TabPane tabId="webhooks">
                {userDetails &&
                  userDetails.subscription &&
                  userDetails.subscription.plan &&
                  userDetails.subscription.plan &&
                  userDetails.subscription.plan.limits &&
                  userDetails.subscription.plan.limits.webhooks && (<>
                    <Row>
                      <Col lg="12" md="12">
                        <Card className="custom-card">
                          <CardBody>
                            <CardTitle tag="h3">Webhooks</CardTitle>
                            <div>
                              <p>
                                Receive notifications when a secret is
                                successfully retrieved.
                              </p>
                            </div>
                            <Row>
                              <Col lg="4" md="4" sm="6" xs="12">
                                <Formik
                                  initialValues={{
                                    url:
                                      userDetails.webhook &&
                                      userDetails.webhook.url
                                        ? userDetails.webhook.url
                                        : "",
                                    password:
                                      userDetails.webhook &&
                                      userDetails.webhook.secretToken
                                        ? userDetails.webhook.secretToken
                                        : "",
                                  }}
                                  validate={(values) => {
                                    let errors = {};
                                    const error = "This field is required";

                                    if (!values.url) {
                                      errors.url = error;
                                    }

                                    if (!isValidHttpUrl(values.url)) {
                                      errors.url = "Invalid URL";
                                    }

                                    return errors;
                                  }}
                                  onSubmit={async (
                                    values,
                                    { setSubmitting, resetForm }
                                  ) => {
                                    let body: {
                                      active: boolean;
                                      url: string;
                                      secretToken?: string;
                                    } = {
                                      active: isSwitched,
                                      url: values.url,
                                    };

                                    if (values.password) {
                                      body.secretToken = values.password;
                                    }

                                    let res;

                                    if (action === "update") {
                                      const response = await fetchPutWebhook(
                                        body
                                      );

                                      if (response.redirect) {
                                        dispatch({
                                          type: types.logout,
                                        });
                                        history.push("/auth/login");
                                      } else {
                                        res = response;

                                        let tempWebhookDetails = {
                                          webhook: body,
                                        };

                                        dispatch2({
                                          type: dataTypes.profile,
                                          payload: {
                                            ...userDetails,
                                            ...tempWebhookDetails,
                                            profileTabName: pageTabs,
                                          },
                                        });
                                      }
                                    } else if (action === "delete") {
                                      const response =
                                        await fetchDeleteWebhook();

                                      if (response.redirect) {
                                        dispatch({
                                          type: types.logout,
                                        });
                                        history.push("/auth/login");
                                      } else {
                                        res = response;

                                        let tempWebhookDetails = profile;
                                        delete tempWebhookDetails.webhook;

                                        dispatch2({
                                          type: dataTypes.profile,
                                          payload: {
                                            ...userDetails,
                                            ...tempWebhookDetails,
                                            profileTabName: pageTabs,
                                          },
                                        });
                                      }
                                    }

                                    if (res.status === "Success") {
                                      if (action === "delete") {
                                        setIsSwitched(false);
                                        setHasWebhook(false);
                                        resetForm();
                                      } else {
                                        setHasWebhook(true);
                                      }
                                      addNotify(
                                        "Webhook settings updated successfully",
                                        "success"
                                      );
                                    } else {
                                      let message =
                                        "There was an error while updating the details";

                                      if (res.message) {
                                        message = res.message;
                                      }

                                      addNotify(message, "danger");
                                    }

                                    setAction("update");

                                    setSubmitting(false);
                                  }}
                                >
                                  {({ isSubmitting, submitForm }) => (
                                    <Form className="form-formik">
                                      <InputWebhookSettings
                                        authVars={{
                                          token,
                                          refreshToken,
                                          expiresIn,
                                          userId: userId,
                                        }}
                                        isSwitched={isSwitched}
                                        setIsSwitched={setIsSwitched}
                                      />
                                      <Row>
                                        <Col>
                                          <Button
                                            className="btn-fill btn-sm"
                                            color="primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                          >
                                            {!isSubmitting
                                              ? "Ok"
                                              : "PLEASE WAIT..."}
                                          </Button>

                                          {hasWebhook && (
                                            <button
                                              onClick={() => {
                                                setAction("delete");
                                                submitForm();
                                              }}
                                              disabled={isSubmitting}
                                              type="button"
                                              className="btn-fill btn-sm btn btn-danger btn-danger-fix ml-2"
                                            >
                                              Delete
                                            </button>
                                          )}
                                        </Col>
                                      </Row>
                                    </Form>
                                  )}
                                </Formik>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" md="12">
                        <Card className="custom-card">
                          <CardBody>
                            <CardTitle tag="h3">Refresh token</CardTitle>
                            <div>
                              <p>You can use the refresh token to generate new access tokens. Access tokens are needed to authenticate API calls to the backend of SharePass.</p>
                              <p>A sign-out invalidates the current refresh token, whereas a global sign-out invalidates all refresh tokens. See the <a href="https://developers.sharepass.com/" target="_blank">API documentation</a> for further details.</p>
                            </div>
                            <Row>
                              <Col lg="4" md="4" sm="6" xs="12">
                              <Button
                                      className="btn-fill btn-sm"
                                      color="primary"
                                      type="button"
                                      onClick={copyRefreshToken}
                                    >
                                      <FontAwesomeIcon
                                        className="cursor-p"
                                        icon={faCopy}
                                      />{"  "}&nbsp; Copy refresh token
                                    </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    </>
                  )}
                {!userDetails && (
                  <Row>
                    <Col lg="12" md="12">
                      <Card className="custom-card">
                        <CardBody>
                          <CardTitle tag="h3">Webhooks</CardTitle>

                          <Row></Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {userDetails && !userDetails.subscription && (
                  <Row>
                    <Col lg="12" md="12">
                      <Card className="custom-card">
                        <CardBody>
                          <CardTitle tag="h3">Webhooks</CardTitle>

                          <Row>
                            <Col lg="12" md="12" className="text-center">
                              <p>
                                Only available for plans that allow webhooks.
                              </p>
                            </Col>

                            <Col lg="12" md="12" className="text-center">
                              <button
                                onClick={() => {
                                  history.push("/admin/settings/plans");
                                }}
                                className="btn btn-sm btn-primary"
                              >
                                Check our plans
                              </button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {userDetails &&
                  userDetails.subscription &&
                  userDetails.subscription.plan &&
                  userDetails.subscription.plan &&
                  userDetails.subscription.plan.limits &&
                  !userDetails.subscription.plan.limits.webhooks && (
                    <Row>
                      <Col lg="12" md="12">
                        <Card className="custom-card">
                          <CardBody>
                            <CardTitle tag="h3">Webhooks</CardTitle>
                            <Row>
                              <Col lg="12" md="12" className="text-center">
                                <p>
                                  Your current subscription doesn't allow
                                  webhooks, consider upgrading your plan to get
                                  more features.
                                </p>
                              </Col>

                              <Col lg="12" md="12" className="text-center">
                                <button
                                  onClick={() => {
                                    history.push("/admin/settings/plans");
                                  }}
                                  className="btn btn-sm btn-primary"
                                >
                                  Check our plans
                                </button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
              </TabPane>
              <TabPane tabId="settings">
                <Row>
                  <Col lg="12" md="12">
                    <Card className="custom-card profile">
                      <CardBody>
                        <CardTitle tag="h3">Theme settings</CardTitle>
                        <Row>
                          <Col lg="12" md="12">
                            <p>
                              Switch your preferred mode between light or dark
                              mode
                            </p>
                            <DarkLightMode tabName={pageTabs} />
                            <br />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              {InstanceConfig().envelope && <TabPane tabId="envelope">
                <EnvelopeSettings
                  tabName={pageTabs}
                  dispatchProfile={dispatch2}
                  profile={profile}
                />
              </TabPane>}
              <TabPane tabId="default">
                {false && <>
                <div
                  aria-multiselectable={true}
                  className="card-collapse mb-3 form-formik"
                  id="accordionSecretDefault"
                  role="tablist"
                >
                  <Card className="card-plain">
                    <CardHeader role="tab">
                      <a
                        aria-expanded={openedDefaultSecrets}
                        href="#pabo"
                        data-parent="#accordionSecretDefault"
                        data-toggle="collapse"
                        onClick={(e) => {
                          e.preventDefault();
                          //if(!openedDefaultSecrets){
                            setOpenedDefaultSecrets(!openedDefaultSecrets);
                            setOpenedDefaultEnvelopes(false);
                          //}
                          
                        }}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />{"  "}
                        For sending secrets
                        <i className="tim-icons icon-minimal-down" />
                      </a>
                    </CardHeader>
                    <Collapse
                      role="tabpanel"
                      isOpen={openedDefaultSecrets}
                      className="collapse-settings"
                    >
                      <CardBody>
                        <Card className="card-plain card-subcategories">
                          <CardBody>
                            <DefaultInputs
                              tabName={pageTabs}
                              dispatchProfile={dispatch2}
                              profile={profile}
                            />
                          </CardBody>
                        </Card>
                      </CardBody>
                    </Collapse>  
                  </Card>
                </div>
                <div
                  aria-multiselectable={true}
                  className="card-collapse mb-3 form-formik"
                  id="accordionEnvelopeDefault"
                  role="tablist"
                >
                  <Card className="card-plain">
                    <CardHeader role="tab">
                      <a
                        aria-expanded={openedDefaultEnvelopes}
                        href="#pabo"
                        data-parent="#accordionEnvelopeDefault"
                        data-toggle="collapse"
                        onClick={(e) => {
                          e.preventDefault();
                          //if(!openedDefaultEnvelopes){
                            setOpenedDefaultEnvelopes(!openedDefaultEnvelopes);
                            setOpenedDefaultSecrets(false);
                          //}
                          
                        }}
                      >
                        <FontAwesomeIcon icon={faInbox} />{"  "}
                        For receiving secrets
                        <i className="tim-icons icon-minimal-down" />
                      </a>
                    </CardHeader>
                    <Collapse
                      role="tabpanel"
                      isOpen={openedDefaultEnvelopes}
                      className="collapse-settings"
                    >
                      <CardBody>
                        <Card className="card-plain card-subcategories">
                          <CardBody>
                            <DefaultInputs
                              tabName={pageTabs}
                              dispatchProfile={dispatch2}
                              profile={profile}
                            />
                          </CardBody>
                        </Card>
                      </CardBody>
                    </Collapse>  
                  </Card>
                </div>
                </>}
                <DefaultInputs
                  tabName={pageTabs}
                  dispatchProfile={dispatch2}
                  profile={profile}
                />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

/**
 * Environment override file if exists
 */

const tryRequire = () => {
  try {
    const env = FrontendConfig.env;
    return require("../../envs/" + env + "/views/profile/Profile");
  } catch (err) {
    return null;
  }
}

 const Profile = tryRequire() ? tryRequire().default : ProfileDefault;

export default Profile;
