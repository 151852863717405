import {
  URL_SIGN_IN,
  URL_SIGN_IN_MFA,
  URL_SIGN_IN_SSO,
  URL_SIGN_IN_WEBAUTHN,
  URL_SIGN_UP,
  URL_WEBAUTHN_CREATE,
  URL_WEBAUTHN_ENROLL_CREATE,
  URL_WEBAUTHN_ENROLL_PARSE,
  URL_WEBAUTHN_PARSE,
  URL_SEND_ENVELOPE,
} from "../constants/urls";
import { isLoggedIn } from "./common";

export const signUp = async (body) => {
  const res = await fetch(URL_SIGN_UP, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log("Error when trying to create a new user", error);
      return error;
    });

  if (!res.statusCode && res.status !== "Success") {
    res.error = true;
    res.errorMessage = res.message;
    res.successMessage = "";
  } else if (res.status === "Success") {
    res.error = false;
    res.errorMessage = "";
    res.successMessage =
      "You have been registered successfully, please validate your email account.";
  }

  return res;
};

export const fetchLogin = async (body) => {
  
  const headers = {
    "Content-Type": "application/json",
  };

  if(localStorage.getItem("isMobile")){
    headers.mobile = true;
  }

  let res = await fetch(URL_SIGN_IN, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((res) => res)
    .catch((error) => {
      console.log("Error when trying to Sign In", error);
      return error;
    });

  if (res.status === "Success") {
    if (res.details.ChallengeName) {
      console.log(res.details.ChallengeParameters);
      res.isLogged = true;
      res.token = null;
      res.expiresIn = null;
      res.idToken = null;
      res.refreshToken = null;
      res.session = res.details.Session;
      res.ChallengeName = res.details.ChallengeName;
      res.ChallengeParameters = res.details.ChallengeParameters;
      res.friendlyDevice =
        res.details.ChallengeParameters &&
        res.details.ChallengeParameters["FRIENDLY_DEVICE_NAME"]
          ? res.details.ChallengeParameters["FRIENDLY_DEVICE_NAME"]
          : null;
    } else {
      res.isLogged = true;
      res.token = res.details.AuthenticationResult.AccessToken
        ? res.details.AuthenticationResult.AccessToken
        : null;
      res.expiresIn = res.details.AuthenticationResult.ExpiresIn;
      res.idToken = res.details.AuthenticationResult.IdToken;
      res.refreshToken = res.details.AuthenticationResult.RefreshToken;
      res.session = null;
      res.friendlyDevice = null;
      res.ChallengeName = null;
      res.ChallengeParameters = null;
    }
  } else {
    res.isLogged = false;
    res.token = null;
    res.expiresIn = null;
    res.idToken = null;
    res.refreshToken = null;
    res.friendlyDevice = null;
    res.ChallengeName = null;
    res.ChallengeParameters = null;
  }

  if(res.details && res.details.sessionToken){
    res.sessionToken = res.details.sessionToken;
  }

  return res;
};

export const fetchLoginMFA = async (body) => {

  const headers = {
    "Content-Type": "application/json",
  };

  if(localStorage.getItem("isMobile")){
    headers.mobile = true;
  }

  let res = await fetch(URL_SIGN_IN_MFA, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((res) => res)
    .catch((error) => {
      console.log("Error when trying to Sign In", error);
      return error;
    });

  if (res.status === "Success") {
    if (res.details.ChallengeName) {
      res.isLogged = true;
      res.token = null;
      res.expiresIn = null;
      res.idToken = null;
      res.refreshToken = null;
      res.session = res.details.Session;
    } else {
      res.isLogged = true;
      res.token = res.details.AuthenticationResult.AccessToken
        ? res.details.AuthenticationResult.AccessToken
        : null;
      res.expiresIn = res.details.AuthenticationResult.ExpiresIn;
      res.idToken = res.details.AuthenticationResult.IdToken;
      res.refreshToken = res.details.AuthenticationResult.RefreshToken;
      res.session = null;
    }
  } else {
    res.isLogged = false;
    res.token = null;
    res.expiresIn = null;
    res.idToken = null;
    res.refreshToken = null;
  }

  if(res.details && res.details.sessionToken){
    res.sessionToken = res.details.sessionToken;
  }

  return res;
};

export const fetchLoginSSO = async (body) => {

  const headers = {
    "Content-Type": "application/json",
  };

  if(localStorage.getItem("isMobile")){
    headers.mobile = true;
  }

  let res = await fetch(URL_SIGN_IN_SSO, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((res) => res)
    .catch((error) => {
      console.log("Error when trying to Sign In", error);
      return error;
    });

  if (res.status === "Success") {
    res.isLogged = true;
    res.token = res.details.access_token ? res.details.access_token : null;
    res.expiresIn = res.details.expires_in ? res.details.expires_in : null;
    res.idToken = res.details.id_token ? res.details.id_token : null;
    res.refreshToken = res.details.refresh_token
      ? res.details.refresh_token
      : null;
    res.session = null;
  } else {
    res.isLogged = false;
    res.token = null;
    res.expiresIn = null;
    res.idToken = null;
    res.refreshToken = null;
  }

  if(res.details && res.details.sessionToken){
    res.sessionToken = res.details.sessionToken;
  }

  return res;
};

export const fetchLoginWebauthn = async (body) => {

  const headers = {
    "Content-Type": "application/json",
  };

  if(localStorage.getItem("isMobile")){
    headers.mobile = true;
  }

  let res = await fetch(URL_SIGN_IN_WEBAUTHN, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((res) => res)
    .catch((error) => {
      console.log("Error when trying to Sign In", error);
      return error;
    });
  console.log("fetchLoginWebauthn", res);
  if (
    res.status === "Success" &&
    res.details &&
    res.details.AuthenticationResult &&
    res.details.AuthenticationResult.AccessToken
  ) {
    res.isLogged = true;
    res.token = res.details.AuthenticationResult.AccessToken
      ? res.details.AuthenticationResult.AccessToken
      : null;
    res.expiresIn = res.details.AuthenticationResult.ExpiresIn;
    res.idToken = res.details.AuthenticationResult.IdToken;
    res.refreshToken = res.details.AuthenticationResult.RefreshToken;
  } else {
    res.isLogged = false;
    res.token = null;
    res.expiresIn = null;
    res.idToken = null;
    res.refreshToken = null;
  }

  if(res.details && res.details.sessionToken){
    res.sessionToken = res.details.sessionToken;
  }

  return res;
};

export const fetchWebauthnCreate = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_WEBAUTHN_CREATE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("Error when trying to create credentials request", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchWebauthnParseAdd = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_WEBAUTHN_PARSE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(
          "Error when trying to parse and add security key credentials",
          error
        );
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchCreateEnrollWebauthn = async (body) => {
  let res = await fetch(URL_WEBAUTHN_ENROLL_CREATE, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((res) => res)
    .catch((error) => {
      console.log(
        "Error when trying to get enroll security key request",
        error
      );
      return error;
    });

  return res;
};

export const fetchParseEnrollWebauthn = async (body) => {
  let res = await fetch(URL_WEBAUTHN_ENROLL_PARSE, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((res) => res)
    .catch((error) => {
      console.log("Error when trying to parse new enroll security key", error);
      return error;
    });

  return res;
};

export const fetchOpenEnvelope = async (id, pin='', challengeresponse = '') => {
  
  let headers = {
    "Content-Type": "application/json",
  };

  if (pin) {
    headers.pin = pin;
  }

  if(challengeresponse){
    headers.challengeresponse = challengeresponse;
  }

  let res = await fetch(`${URL_SEND_ENVELOPE}?secretId=${id}`, {
    method: "GET",
    headers: headers,
  })
    .then((response) => response.json())
    .then((res) => res)
    .catch((error) => {
      console.log("Error when trying to open the envelope", error);
      return error;
    });

  return res;
};

export const fetchSendEnvelope = async (body) => {
  let res = await fetch(URL_SEND_ENVELOPE, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((res) => res)
    .catch((error) => {
      console.log("Error when trying to send the envelope", error);
      return error;
    });

  return res;
};